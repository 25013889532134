import React, {useContext, useEffect, useState} from 'react'
import {useClickOutside} from "../../../../hooks/ClickOutside";
import {ISelect, IUser} from "../../../../models";
import {useSelector} from "react-redux";
import {useScrollTopValue} from "../../../../hooks/ScrollTopValue";
import {Translate} from "../../../../components/translate/Translate";
import {TableHeaderProjects} from "../../../../components/table/TableHeaderProjects";
import {FixedTopEdit} from "../../../timesheet/Timesheet";
import {getBearer} from "../../../../functions/getBearer";
import axios, {AxiosResponse} from "axios";
import {getApiLink} from "../../../../functions/getApiLink";

interface ICostsTableHeaderProps {
    setFilterByProjectName: any
    setSortByDate: any
    setSortByCost: any
    setSortByCategory: any
    setFilterByProjectDescription: any
}

export const CostsTableHeader: React.FC<ICostsTableHeaderProps> = ({setFilterByProjectName, setSortByCategory, setSortByDate, setSortByCost, setFilterByProjectDescription}) => {

    const [isActiveDate, setIsActiveDate] = useState(false)
    const activeDate = useClickOutside(setIsActiveDate)

    const [isActiveCategory, setIsActiveCategory] = useState(false)
    const activeCategory = useClickOutside(setIsActiveCategory)

    const [isActiveProjectName, setIsActiveProjectName] = useState(false)
    const [isActiveProjectDescription, setIsActiveProjectDescription] = useState(false)

    const setIsFixedEditBlock: any = useContext(FixedTopEdit)

    const [chosenSortDate, setChosenSortDate] = useState("ASC")
    const [chosenSortCost, setChosenSortCost] = useState("")

    const {scrollY} = useScrollTopValue()


    const [categoriesList, setCategoriesList] = useState<ISelect[]>([
        {value: "default", label: "All categories"}
    ])
    const [selectedCategory, setSelectedCategory] = useState(categoriesList[0])

    useEffect(() => {
        setCategoriesList([{value: "default", label: "All categories"}])
        setSelectedCategory(categoriesList[0])

        getBearer("get")
        axios.get(getApiLink("/api/timesheet/categories/expense/"))
            .then(({data}: AxiosResponse<{id: string; name: string}[]>) => {
                const selectArray: any = data?.map(item => {
                    return {
                        value: item.id,
                        label: item.name
                    }
                })

                setCategoriesList(prev => [...prev, ...selectArray])
            })
            .catch((error) => {
                
            });

    }, [])

    return (
        <div className="section-table__head">
            <div className="section-table__head-row">
                <div className="section-table__head-th visible-on-mob">
                    <span className="section-table__main--param">
                        <span>No</span>
                        <div ref={activeDate.rootEl} className="section-table__main--sort drop-down-absolute is-center">
                        <button
                            className={isActiveDate ? "section-table__main--sort-target drop-down-absolute__target is-active" : "section-table__main--sort-target drop-down-absolute__target"}
                            data-drop-down-target="date-sort-2" type="button"
                            onClick={_ => setIsActiveDate(prev => !prev)}>
                            <svg width="13" height="13" viewBox="0 0 13 13">
                                <use xlinkHref="#calendar-selected"></use>
                            </svg>
                            <Translate>costs_page.table.date</Translate>
                            <svg width="10" height="15" viewBox="0 0 11 15">
                                <use xlinkHref="#sort-up-down"></use>
                            </svg>
                        </button>
                        <div
                            className={isActiveDate ? "section-table__main--sort-block drop-down-absolute__block is-active" : "section-table__main--sort-block drop-down-absolute__block"}
                            id="date-sort-2" style={{minWidth: "150px", transform: `translateY(${-scrollY}px)`}}>
                            <ul className="drop-down__list drop-down__list-date">
                                <li className={` ${chosenSortDate === "ASC" && "is-active"}`}>
                                    <a onClick={_ => {
                                        setSortByDate("ASC")
                                        setChosenSortDate("ASC")
                                        setSortByCost("")
                                        setChosenSortCost("")
                                        setIsActiveDate(false)
                                    }}>
                                        <Translate>newest_first</Translate>
                                    </a>
                                </li>
                                <li className={` ${chosenSortDate === "DESC" && "is-active"}`}>
                                    <a onClick={_ => {
                                        setSortByDate("DESC")
                                        setChosenSortDate("DESC")
                                        setSortByCost("")
                                        setChosenSortCost("")
                                        setIsActiveDate(false)
                                    }}>
                                        <Translate>oldest_first</Translate>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                                </span>
                </div>
                <div className="section-table__head-th visible-on-desktop">
                                <span className="section-table__main--param">
                                No
                                </span>
                </div>
                <div className="section-table__head-th visible-on-desktop">
                    {window.innerWidth > 992 && <div ref={activeDate.rootEl} className="section-table__main--sort drop-down-absolute is-center">
                        <button
                            className={isActiveDate ? "section-table__main--sort-target drop-down-absolute__target is-active" : "section-table__main--sort-target drop-down-absolute__target"}
                            data-drop-down-target="date-sort-2" type="button"
                            onClick={_ => setIsActiveDate(prev => !prev)}>
                            <svg width="13" height="13" viewBox="0 0 13 13">
                                <use xlinkHref="#calendar-selected"></use>
                            </svg>
                            <Translate>costs_page.table.date</Translate>
                            <svg width="10" height="15" viewBox="0 0 11 15">
                                <use xlinkHref="#sort-up-down"></use>
                            </svg>
                        </button>
                        <div
                            className={isActiveDate ? "section-table__main--sort-block drop-down-absolute__block is-active" : "section-table__main--sort-block drop-down-absolute__block"}
                            id="date-sort-2" style={{minWidth: "150px", transform: `translateY(${-scrollY}px)`}}>
                            <ul className="drop-down__list drop-down__list-date">
                                <li className={` ${chosenSortDate === "ASC" && "is-active"}`}>
                                    <a onClick={_ => {
                                        setSortByDate("ASC")
                                        setChosenSortDate("ASC")
                                        setSortByCost("")
                                        setChosenSortCost("")
                                        setIsActiveDate(false)
                                    }}>
                                        <Translate>newest_first</Translate>
                                    </a>
                                </li>
                                <li className={` ${chosenSortDate === "DESC" && "is-active"}`}>
                                    <a onClick={_ => {
                                        setSortByDate("DESC")
                                        setChosenSortDate("DESC")
                                        setSortByCost("")
                                        setChosenSortCost("")
                                        setIsActiveDate(false)
                                    }}>
                                        <Translate>oldest_first</Translate>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>}
                </div>
                <div className="section-table__head-th">
                    <TableHeaderProjects title={"P. Number"} setFilterByProjectName={setFilterByProjectName}
                                         isActiveBlock={isActiveProjectName} setIsActiveBlock={setIsActiveProjectName} icon={"#project"}/>
                </div>
                <div className="section-table__head-th">
                    <TableHeaderProjects title={"P. Description"} setFilterByProjectName={setFilterByProjectName}
                                         isActiveBlock={isActiveProjectDescription} setIsActiveBlock={setIsActiveProjectDescription} icon={"#comments"}/>
                </div>
                <div className="section-table__head-th">
                    <div ref={activeCategory.rootEl} className="section-table__main--sort drop-down-absolute is-center">
                        <button
                            className={(isActiveCategory || selectedCategory.value !== "default") ? "section-table__main--sort-target drop-down-absolute__target is-active" : "section-table__main--sort-target drop-down-absolute__target"}
                            data-drop-down-target="date-sort-2" type="button"
                            onClick={_ => setIsActiveCategory(prev => !prev)}>
                            <svg width="13" height="13" viewBox="0 0 13 13">
                                <use xlinkHref="#position"></use>
                            </svg>
                            <Translate>Category</Translate>
                            <svg width="10" height="15" viewBox="0 0 11 15">
                                <use xlinkHref="#drop-down-arrow"></use>
                            </svg>
                        </button>
                        <div
                            className={isActiveCategory ? "section-table__main--sort-block drop-down-absolute__block is-active" : "section-table__main--sort-block drop-down-absolute__block"}
                            id="date-sort-2" style={{minWidth: "150px", transform: `translateY(${-scrollY}px)`}}>
                            <ul className="drop-down__list drop-down__list-date">

                                {
                                    categoriesList.map(item =>
                                        <li key={item.value} className={` ${selectedCategory.value === item.value && "is-active"}`}>
                                            <a onClick={_ => {
                                                setSortByCategory(item.value)
                                                setSelectedCategory(item)
                                                setSortByCost("")
                                                setChosenSortCost("")
                                                setIsActiveCategory(false)
                                            }}>
                                                {item.label}
                                            </a>
                                        </li>
                                    )
                                }

                            </ul>
                        </div>
                    </div>
                </div>
                <div className="section-table__head-th">
                    <span className="section-table__main--param">
                        <svg width="13" height="13" viewBox="0 0 13 13">
                            <use xlinkHref="#pin"></use>
                        </svg>
                        <Translate>Expense</Translate>
                    </span>
                </div>
                <div className="section-table__head-th">
                    <span className="section-table__main--param">
                        <svg width="13" height="13" viewBox="0 0 20 20">
                            <use xlinkHref="#money"></use>
                        </svg>
                        <Translate>costs_page.table.cost</Translate>
                    </span>
                </div>
                <div className="section-table__head-th">
                    <span className="section-table__main--param">
                        <svg width="13" height="13" viewBox="0 0 20 20">
                            <use xlinkHref="#money"></use>
                        </svg>
                        <Translate>Currency</Translate>
                    </span>
                </div>
                <div className="section-table__head-th">
                    <span className="section-table__main--param">
                        <svg width="13" height="13" viewBox="0 0 20 20">
                            <use xlinkHref="#money"></use>
                        </svg>
                        <Translate>costs_page.table.cost</Translate> (EUR)
                    </span>
                </div>
                <div className="section-table__head-th">
                    <span className="section-table__main--param">
                        <svg width="13" height="13" viewBox="0 0 13 13">
                            <use xlinkHref="#clip"></use>
                        </svg>
                    </span>
                </div>
            </div>
        </div>
    )
}
