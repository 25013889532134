import React, { useContext, useEffect, useRef, useState } from 'react'
import { Notifications } from "../../../../components/notifications/Notifications";
import { ICurrency, IExpense, IProject, ISelect, ITimesheet, IUser } from "../../../../models";
import { useDispatch, useSelector } from "react-redux";
import { getBearer } from "../../../../functions/getBearer";
import axios, { AxiosResponse } from "axios";
import { getApiLink } from "../../../../functions/getApiLink";
import { setExpenses, setTasks } from "../../../../storage/toolkit";
import { TableExportCustom } from "../../../../components/table/TableExportCustom";
import { TableSelectYearMonth } from "../../../../components/table/TableSelectYearMonth";
import { TableCalendar } from "../../../../components/table/TableCalendar";
import { BlockToEdit, FixedTopEdit } from '../../Costs';
import { TableProjectsForUser } from "../../../../components/table/TableProjectsForUser";
import { SetExpenses } from '../../../../api/SetExpenses';
import { SetStatistic } from "../../../../api/SetStatistic";
import { Translate } from "../../../../components/translate/Translate";
import { useClickOutside } from "../../../../hooks/ClickOutside";
import { GetAccessToken } from '../../../../api/GetAccessToken';
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { CostsExcel } from "../CostsExcel";
import ExcelJS from "exceljs";
import { MonthNumber } from "../../../../constants/MonthNumber";
import FileSaver from "file-saver";
import { CustomSelect } from "../../../../components/customSelect/CustomSelect";
import { SetTasks } from "../../../../api/SetTasks";
import { toast } from "react-toastify";
import { errors } from "../../../../constants/Errors";
import { CostsDocsTemplate } from '../../../../components/docs/Costs';
import logo from './../../../../assets/html/img/logo-2.png'

interface ICostsHeaderProps {
    itemToEdit: IExpense | undefined
    itemToDuplicate: IExpense | undefined
    isFixedEditBlock: boolean
    setItemToDuplicate: any
}

export const CostsHeader: React.FC<ICostsHeaderProps> = ({
    itemToEdit,
    isFixedEditBlock,
    itemToDuplicate,
    setItemToDuplicate
}) => {

    const isEditExpense = itemToEdit && Object.keys(itemToEdit).length
    const isDuplicateExpense = itemToDuplicate && Object.keys(itemToDuplicate).length

    const [isCancelEdit, setIsCancelEdit] = useState(false)
    const [projectData, setProjectData] = useState<IProject | undefined>()
    const [dateData, setDateData] = useState<string>("")
    const [descriptionData, setDescriptionData] = useState<string>("")
    const [costData, setCostData] = useState<number>(0)
    const [isLoadingToAdd, setIsLoadingToAdd] = useState(false)
    const [receipt, setReceipt] = useState<any | string>()
    const [projectSort, setProjectSort] = useState<IProject>()

    const language = useSelector((state: any) => state.toolkit.language)
    const timesheet: ITimesheet[] = useSelector((state: any) => state.toolkit.timesheet)
    const chosenTimesheet: ITimesheet = useSelector((state: any) => state.toolkit.chosenTimesheet)
    const expenses: IExpense[] = useSelector((state: any) => state.toolkit.expenses)
    const userData: IUser = useSelector((state: any) => state.toolkit.user)
    const currency: ICurrency = useSelector((state: any) => state.toolkit.currency)
    const currentCurrency: string = useSelector((state: any) => state.toolkit.currentCurrency)

    const { date, ...allCurrencies }: any = currency

    const inputFileRef: any = useRef(null)

    const dispatch = useDispatch()

    const setIsFixedEditBlock: any = useContext(FixedTopEdit)

    const handleCreateExpense = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        setIsLoadingToAdd(true)

        const timesheetRequest: any = {
            "project_id": projectData?.id,
            "date": dateData,
            "description": descriptionData,
            "sum_in_currency": costData,
            "category_id": selectedTask?.value,
            "currency": selectedCurrency?.value,
        }

        if (isEditExpense) {

            if (receipt?.files) {
                const file = receipt?.files && receipt?.files[0]
                const formData = new FormData();
                formData.append("add_data", JSON.stringify(timesheetRequest));
                const reader = new FileReader();
                reader.readAsDataURL(file);

                reader.onload = () => {
                    formData.append("check_file", file);

                    getBearer("post")
                    axios.patch(
                        getApiLink(`/api/expense/edit/?expense_id=${itemToEdit?.id}&project_id=${projectData?.id}&description=${descriptionData}&category_id=${selectedTask?.value}&date=${dateData}&currency=${selectedCurrency?.value}&sum_in_currency=${+costData}`),
                        formData,
                    ).then(({ data }) => {
                        setIsLoadingToAdd(false)

                        SetStatistic(dispatch, chosenTimesheet.id)
                        SetExpenses(dispatch, chosenTimesheet.id)
                        handleBackFromCreate()
                        resetFields()

                        setProjectData(undefined)
                        setDescriptionData("")
                        setCostData(0)
                        setReceipt(null)
                        setSelectedTask(undefined)
                        setSelectedCurrency(undefined)
                    }).catch(err => {
                        
                    })
                };
            } else {
                getBearer("patch")
                axios.patch(
                    getApiLink(`/api/expense/edit/?expense_id=${itemToEdit?.id}&project_id=${projectData?.id}&description=${descriptionData}&category_id=${selectedTask?.value}&date=${dateData}&currency=${selectedCurrency?.value}&sum_in_currency=${+costData}`),
                ).then(({ data }) => {
                    setIsLoadingToAdd(false)

                    SetStatistic(dispatch, chosenTimesheet.id)
                    SetExpenses(dispatch, chosenTimesheet.id)
                    handleBackFromCreate()
                    resetFields()

                    setProjectData(undefined)
                    setDescriptionData("")
                    setCostData(0)
                    setReceipt(null)
                    setSelectedTask(undefined)
                    setSelectedCurrency(undefined)
                }).catch(err => {
                    
                })
            }

        } else {
            const file = receipt?.files && receipt?.files[0]

            if (!file) return fetchExpense();

            const formData = new FormData();
            formData.append("add_data", JSON.stringify(timesheetRequest));
            const reader = new FileReader();

            reader.onload = () => {
                formData.append("check_file", file);
                fetchExpense(formData)
            };

            reader.readAsDataURL(file);

        }
    }

    const fetchExpense = (formData?: any) => {
        getBearer("post")
        axios.post(
            getApiLink(`/api/expense/add/?timesheet_id=${chosenTimesheet?.id}&project_id=${projectData?.id}&description=${descriptionData}&category_id=${selectedTask?.value}&date=${dateData}&currency=${selectedCurrency?.value}&sum_in_currency=${+costData}`),
            formData ?? {},
        ).then(({ data }) => {
            setIsLoadingToAdd(false)

            setProjectData(undefined)
            setDescriptionData("")
            setCostData(0)
            setReceipt(null)
            setSelectedTask(undefined)
            setSelectedCurrency(undefined)

            if (isDuplicateExpense) handleBackFromCreate()

            SetStatistic(dispatch, chosenTimesheet.id)
            SetExpenses(dispatch, chosenTimesheet.id)
        }).catch(err => {
            setIsLoadingToAdd(false)
            

            setProjectData(undefined)
            setDescriptionData("")
            setCostData(0)
            setReceipt(null)

            if (err.response.status === 422) {
                toast.error("Fill all fields please!")
            } else if (err.response.status === 400) {
                toast.error(errors[err.response.data.message])
            }

        })
    }

    const lessThenTen = (num: string) => +num < 10 ? "0" + num : num

    useEffect(() => {
        if (!chosenTimesheet?.date) return;
        if (itemToEdit && !Object.keys(itemToEdit).length) return;
        if (isEditExpense) setIsOpenCreatBlock(true)

        setProjectData(itemToEdit?.project ?? undefined)
        setReceipt(itemToEdit?.check_file)
        setDescriptionData(itemToEdit?.description ?? "")
        setDateData(itemToEdit?.date?.replaceAll("/", ".") ?? `${lessThenTen(String(getMondayDate().getDate()))}.${chosenTimesheet?.date[3]}${chosenTimesheet?.date[4]}.${getMondayDate().getFullYear()}`)
        setCostData(itemToEdit?.sum_in_currency ?? 0)
        setSelectedTask(itemToEdit?.category ? {
            value: itemToEdit?.category.id,
            label: itemToEdit?.category.name
        } : { value: "default", label: "Choose task" })
        setTimeout(_ => setSelectedCurrency({
            value: itemToEdit?.currency ?? "",
            label: itemToEdit?.currency.toUpperCase() ?? ""
        }), 100)
    }, [itemToEdit])

    useEffect(() => {
        if (!chosenTimesheet?.date) return;
        if (itemToDuplicate && !Object.keys(itemToDuplicate).length) return;
        if (isDuplicateExpense) setIsOpenCreatBlock(true)

        setProjectData(itemToDuplicate?.project ?? undefined)
        setDescriptionData(itemToDuplicate?.description ?? "")
        setReceipt(itemToDuplicate?.check_file)
        setDateData(itemToDuplicate?.date?.replaceAll("/", ".") ?? `${lessThenTen(String(getMondayDate().getDate()))}.${chosenTimesheet?.date[3]}${chosenTimesheet?.date[4]}.${getMondayDate().getFullYear()}`)
        setCostData(itemToDuplicate?.sum ?? 0)
        setSelectedTask(itemToDuplicate?.category ? {
            value: itemToDuplicate?.category.id,
            label: itemToDuplicate?.category.name
        } : { value: "default", label: "Choose task" })
        setTimeout(_ => setSelectedCurrency({
            value: itemToDuplicate?.currency ?? "",
            label: itemToDuplicate?.currency.toUpperCase() ?? ""
        }), 100)
    }, [itemToDuplicate])

    const setItemEdit: any = useContext(BlockToEdit)

    const handleBackFromCreate = () => {
        setIsCancelEdit(true)
        setTimeout(() => {
            setIsOpenCreatBlock(false)
            setItemEdit({})
            setItemToDuplicate({})
            setIsCancelEdit(false)
            setIsFixedEditBlock(false)
            
            setProjectData(undefined)
            setDescriptionData("")
            setCostData(0)
            setReceipt(null)
            setSelectedTask(undefined)
            setSelectedCurrency(undefined)
        }, 400)
    }

    const [isOpenCreatBlock, setIsOpenCreatBlock] = useState(false)


    const handleSwitchMonth = (month: number) => {
        if (!timesheet.length) return;

        const idTasksForMonth = timesheet.filter(item => Number(`${item.date[3]}${item.date[4]}`) === month)[0]?.id

        if (!idTasksForMonth?.length) return;

        getBearer("get")
        axios.get(getApiLink(`/api/timesheet/expenses/?timesheet_id=${idTasksForMonth}`)).then(({ data }) => {

            SetStatistic(dispatch, idTasksForMonth)
            dispatch(setExpenses(data))
        }).catch(er => {
            er?.response?.status === 401 && GetAccessToken(dispatch, handleSwitchMonth)
        })
    }

    const handleOpenToCreate = () => {
        setIsOpenCreatBlock(true)

        resetFields()
    }

    function getMondayDate() {
        const today = new Date();

        return today;
    }

    const resetFields = () => {
        setProjectData(undefined)
        setDescriptionData("")
        setCostData(0)
    }

    useEffect(() => {
        if (!chosenTimesheet?.date) return;

        setDateData(`${lessThenTen(String(getMondayDate().getDate()))}.${chosenTimesheet?.date[3]}${chosenTimesheet?.date[4]}.${getMondayDate().getFullYear()}`)
    }, [chosenTimesheet])

    //  || chosenTimesheet?.status === "waiting"
    const isApprove = chosenTimesheet?.status === "approve"

    const [isOpenInputSearch, setIsOpenInputSearch] = useState(false)
    const { rootEl } = useClickOutside(setIsOpenInputSearch)


    const handleSetValueCost = (e: any) => {

        let inputValue = e.target.value;

        inputValue = inputValue.replace(/,/g, '.');

        const dotIndex = inputValue.indexOf('.');

        if (dotIndex !== -1) {
            const decimals = inputValue.substring(dotIndex + 1);
            if (decimals.length > 2) {
                setCostData(inputValue.substring(0, dotIndex + 3));
                return;
            }
        }

        setCostData(inputValue);
    }

    const { t } = useTranslation();

    const secondTitle: { [key: string]: string } = {
        "duplicate": `${t("duplicate_cost")}`,
        "edit": `${t("edit_cost")}`,
        "add": `${t("costs_page.top_part.add_expense_2")}`
    }

    const isWindowMin = isFixedEditBlock && window.innerWidth > 1200 && window.innerWidth < 1500


    const [tasksList, setTasksList] = useState<ISelect[]>([])
    const [selectedTask, setSelectedTask] = useState<ISelect>()

    useEffect(() => {

        getBearer("get")
        axios.get(getApiLink("/api/timesheet/categories/expense/"))
            .then(({ data }: AxiosResponse<{ id: string; name: string }[]>) => {
                const selectArray: any = data?.map(item => {
                    return {
                        value: item.id,
                        label: item.name
                    }
                })

                setTasksList(prev => [...prev, ...selectArray])
            })
            .catch((error) => {
                
            });

    }, [])

    const [currencyList, setCurrencyList] = useState<ISelect[]>([
        { value: "eur", label: "EUR" }
    ])
    const [selectedCurrency, setSelectedCurrency] = useState<ISelect>()

    useEffect(() => {
        setCurrencyList([
            { value: "eur", label: "EUR" }
        ])

        const currencyArray: ISelect[] = Object.keys(allCurrencies).map(item => {
            return {
                value: item,
                label: item.toUpperCase()
            }
        })

        setCurrencyList(prev => [...prev, ...currencyArray])
    }, [currency, isOpenCreatBlock])

    useEffect(() => {

        if (!projectSort) {
            SetExpenses(dispatch, chosenTimesheet.id)
        } else {
            SetExpenses(dispatch, chosenTimesheet.id, projectSort.id)
        }

    }, [projectSort])

    const getImage = async () => {
        const response = await fetch(logo);
        const imageArrayBuffer = await response.arrayBuffer();
        const imageData = new Uint8Array(imageArrayBuffer);

        return imageData
    }

    return (
        <div style={{ paddingLeft: isFixedEditBlock ? isWindowMin ? "170px" : "51px" : "0" }}
            className={`section-table__header section-table__header-cost ${isFixedEditBlock && "animate-to-show"} ${isCancelEdit && "animate-to-hide"}`}>
            <div className="section-table__header--row is-always-row">
                <div className="section-table__header--col">
                    <h1 className="section-table__title title change-title" id="main-title">
                        <span>

                            <Translate>costs_page.top_part.costs</Translate>

                            {
                                isOpenCreatBlock && " / "
                            }
                            {
                                isOpenCreatBlock && (secondTitle[isDuplicateExpense ? "duplicate" : isEditExpense ? "edit" : "add"])
                            }

                            {
                                chosenTimesheet?.user?.id && chosenTimesheet?.user?.id !== userData?.id &&
                                <span> ({chosenTimesheet?.user?.first_name} {chosenTimesheet?.user?.last_name})</span>
                            }

                        </span>
                    </h1>
                </div>

                <Notifications />

            </div>
            <div className={`section-table__header--block block-for-is-active ${isOpenCreatBlock && "is-active"}`}>
                <div className="section-table__header--block-item">
                    <div>
                        <div className="section-table__header--row row-2">
                            <div className="section-table__header--col">
                                <button disabled={isApprove} onClick={handleOpenToCreate} type="button"
                                    className="section-table__add btn add-is-active"
                                    data-add-active-change-title="main-title">
                                    <Translate>costs_page.top_part.add_expense_2</Translate>
                                    <svg width="16" height="15" viewBox="0 0 16 15">
                                        <use xlinkHref="#plus"></use>
                                    </svg>
                                </button>

                                <TableProjectsForUser setProjectData={setProjectSort} projectData={projectSort} />

                            </div>
                            <div className="section-table__header--col">

                                <div className="currency-block" style={{ textTransform: "uppercase" }}>
                                    {currentCurrency}/EUR - {currentCurrency === "eur" ? 1 : (1 / allCurrencies[currentCurrency]).toFixed(4)}
                                </div>

                                <TableSelectYearMonth onSwitch={handleSwitchMonth} />

                                <TableExportCustom
                                    excelFile={(e: any) => CostsExcel({ currency, project: projectSort, chosenTimesheet, expenses, translate: t })}
                                    docxFile={(e: any) => CostsDocsTemplate({ image: getImage(), expenses, currency, chosenTimesheet, chosenProject: projectSort })}
                                />

                            </div>
                        </div>
                    </div>
                </div>
                <div className="section-table__header--block-item">
                    <div>
                        <form onSubmit={handleCreateExpense}
                            className="section-table__header--add-costs section-table__add-costs">
                            <button onClick={handleBackFromCreate}
                                className="section-table__add-expense--back back-btn remove-is-active"
                                data-remove-active-change-title="main-title" type="button"
                                aria-label="Go back">
                                <svg width="7" height="10" viewBox="0 0 7 10">
                                    <use xlinkHref="#arrow-prev"></use>
                                </svg>
                                <span className="visible-on-mob">
                                    Go back
                                </span>
                            </button>

                            <TableCalendar dateData={dateData} setDateData={setDateData} />

                            <TableProjectsForUser
                                projectData={projectData}
                                setProjectData={setProjectData}
                            />

                            <CustomSelect className={"mini spacing-between"} defaultValue={{ value: "default", label: "Category" }} headText={"Category"} list={tasksList} setSelectedItem={setSelectedTask}
                                selectValue={selectedTask} />

                            <div className="section-table__add-costs--text">
                                <label>
                                    <input type="text" spellCheck name="costs" value={descriptionData}
                                        onChange={e => setDescriptionData(e.target.value)} required
                                        lang={language}
                                        className="input"
                                        placeholder={`${t("The expense")}`}
                                    />
                                </label>
                            </div>
                            <div className="section-table__add-costs--cost">
                                <input type="number" spellCheck name="cost"
                                    value={costData === 0 ? "" : String(costData).replace(',', '.')}
                                    onChange={handleSetValueCost} required className="input"
                                    placeholder={`${t("costs_page.table.cost")}`}
                                />
                            </div>

                            <CustomSelect headText={"Currency"} className={"mini"}
                                defaultValue={{ value: 'default', label: 'Currency' }} list={currencyList}
                                setSelectedItem={setSelectedCurrency} selectValue={selectedCurrency} />

                            <div className="table-header__receipt">
                                <label>
                                    <svg width="13" height="13" viewBox="0 0 13 13">
                                        <use xlinkHref="#clip"></use>
                                    </svg>
                                    <input type="file" ref={inputFileRef} onChange={e => setReceipt(e.target)}
                                        style={{ display: "none" }} />
                                    <span>
                                        {receipt?.files ? (receipt?.files[0]?.name ?? "Receipt") : (receipt ?? "Receipt")}
                                    </span>
                                </label>
                            </div>

                            <button className="section-table__add-expense--submit btn"
                                type="submit">
                                {!isLoadingToAdd ? (isEditExpense ? <Translate>edit_expense</Translate> :
                                    <Translate>costs_page.top_part.add_expense_2</Translate>) :
                                    <Translate>loading</Translate>}
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}
