import React, {useContext, useEffect, useState} from 'react'
import {IsPopupActiveContext} from "../PopupList";
import {getBearer} from "../../../functions/getBearer";
import axios from "axios";
import {getApiLink} from "../../../functions/getApiLink";
import {editProject} from "../../../storage/toolkit";
import {useDispatch} from 'react-redux';
import {useMask} from "@react-input/mask";
import {PopupClose} from "./PopupClose";
import {Translate} from "../../translate/Translate";
import {PopupCloseCancel} from "./PopupCloseCancel";
import {useTranslation} from "react-i18next";
import {useDepartmentsAndTasks} from "../../../hooks/DepartmentsAndTasks";

interface IPopupEditProjectProps {
    data: any
    setIsOpenProjects: any
    chosenTasks: any
    setChosenDepartment: any
}

export const PopupEditProject: React.FC<IPopupEditProjectProps> = ({data, setIsOpenProjects, chosenTasks, setChosenDepartment}) => {

    const [nameValue, setNameValue] = useState<string>('')
    const [descriptionValue, setDescriptionValue] = useState<string>('')

    const {departments, tasks} = useDepartmentsAndTasks(data.department, data.task_categories)

    useEffect(() => {
        setNameValue(data.name)
        setDescriptionValue(data.description)
    }, [data])

    const dispatch = useDispatch()

    const setIsPopupActive: any = useContext(IsPopupActiveContext)

    const handleAddProject = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()

        const newDataProject = {
            "name": nameValue,
            "description": descriptionValue,
            "department_id": departments.value?.value,
            "task_categories_ids": chosenTasks.map((item: any) => item.id)
        }

        getBearer("patch")
        axios.patch(getApiLink("/api/admin/project/edit/?project_id=" + data.id), newDataProject).then((res: any) => {
            setIsPopupActive(false)
            dispatch(editProject({data, newData: res.data}))
        }).catch(er => console.log(getApiLink("/api/admin/project/add/"), er))
    }

    useEffect(() => {
        setChosenDepartment(departments.value?.value)
    }, [departments.value])

    const {t} = useTranslation();

    return (
        <div className="add-project__body popup-body">
            <h2 className="add-project__title popup-title title">
                <Translate>edit_project</Translate>
            </h2>
            <PopupClose/>
            <div className="add-project__container popup-container" data-simplebar
                 data-simplebar-auto-hide="false">
                <form onSubmit={handleAddProject} className="add-project__form popup-form">
                    <div className="popup-form__row">
                        <label className="popup-form__label is-full">
                            <span>
                                <Translate>projects_admin.project_name</Translate>
                            </span>
                            <input type="text" name="project-name" required
                                   className="input" value={nameValue}
                                   onChange={e => setNameValue(e.target.value)}
                                   placeholder={`${t("projects_admin.project_name")}`}
                            />
                        </label>
                    </div>
                    <div className="popup-form__row">
                        <label className="popup-form__label is-full">
                            <span>
                                <Translate>projects_admin.project_description</Translate>
                            </span>
                            <input type="text" name="project-description" required
                                   className="input" onChange={e => setDescriptionValue(e.target.value)}
                                   value={descriptionValue}
                                   placeholder={`${t("projects_admin.project_description")}`}
                            />
                        </label>
                    </div>
                    <div className="popup-form__row">
                        <label className="popup-form__label is-full">
                            <span>
                                <Translate>Department name</Translate>
                            </span>
                            {departments.select}
                        </label>
                    </div>
                    <div className="popup-form__row">
                        <label className="popup-form__label is-full">
                            <span>
                                <Translate>Tasks</Translate>
                            </span>
                            <a onClick={_ => setIsOpenProjects((prev: string) => prev !== "tasks" ? "tasks" : "")}
                               className="popup-form__open-sub-popup open-popup">
                                <span id="checked-projects" data-none-text="None" data-text-1="project"
                                      data-text-2="projects" data-all-text="All projects">
                                    {chosenTasks?.length === tasks.value?.value.length ?
                                        <Translate>employees_admin.others.all_projects</Translate> : `${chosenTasks?.length} `}

                                    {
                                        chosenTasks?.length !== tasks.value?.value.length && <Translate>Tasks</Translate>
                                    }
                                </span>
                                <svg width="10" height="7" viewBox="0 0 10 7">
                                    <use xlinkHref="#drop-down-arrow"></use>
                                </svg>
                            </a>
                        </label>
                    </div>
                    <div className="popup-form__row is-min-gap">
                        <PopupCloseCancel/>
                        <button className="popup-form__submit btn" type="submit">
                            <Translate>projects_admin.save</Translate>
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
}
