import React, { useContext, useEffect } from "react";
import axios from "axios";
import { getApiLink } from "../../../functions/getApiLink";
import { PopupContext } from "../../../App";
import { Translate } from "../../../components/translate/Translate";
import { SummaryAllProjects } from "../../../components/summaryAllProjects/SummaryAllProjects";
import { useSelector } from "react-redux";
import { IUser } from "../../../models";

interface ISummaryEmployeesButtonsProps {
  timesheetId: string;
  handleExportPdf: any;
  isClickToExport: boolean;
  isCanApprove?: boolean;
  setValue: any
}

export const SummaryEmployeesButtons: React.FC<
  ISummaryEmployeesButtonsProps
> = ({ timesheetId, handleExportPdf, isClickToExport, isCanApprove, setValue }) => {
  const userData: IUser = useSelector((state: any) => state.toolkit.user);

  const setPopup: any = useContext(PopupContext);

  const handleReviewTimesheet = (status: string) => {
    setPopup({
      popup: "review-employee-timesheet-popup",
      data: {
        timesheetId,
        status,
      },
    });
  };

  return (
    <div className="summary-item__footer--col add-cols">
      <SummaryAllProjects setValue={setValue} />
      {(
        <>
          <button
            disabled={isClickToExport}
            onClick={handleExportPdf}
            className="summary-item__button btn is-grey is-transparent"
            type="button"
          >
            <Translate>summary_page.main.export_monthly_summary</Translate>
            <svg width="16" height="17" viewBox="0 0 16 17">
              <use xlinkHref="#download"></use>
            </svg>
          </button>

          <a
            onClick={(_) => handleReviewTimesheet("approve")}
            className="summary-item__button btn open-popup is-success"
            type="button"
          >
            <Translate>employees_page.table.approved</Translate>
            <svg width="16" height="17" viewBox="0 0 20 20">
              <use xlinkHref="#round-check"></use>
            </svg>
          </a>
          <a
            onClick={(_) => handleReviewTimesheet("reject")}
            className="summary-item__button btn is-error open-popup"
            type="button"
          >
            <Translate>employees_page.table.rejected</Translate>
          </a>
        </>
      )}
    </div>
  );
};
