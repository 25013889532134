import styled from "styled-components";

export const ProjectStyled = styled.section`
  min-height: calc(95vh - 4px);

  @media screen and (max-width: 992px) {
    min-height: calc(100vh - 115px);
  };

  @media screen and (max-width: 1100px) {
    .section-table__header--col:last-child {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }
    .section-table__header--col:first-child {
      display: grid;
      grid-template-columns: auto 1fr;
    }
  }
  @media screen and (max-width: 576px) {
    .section-table__header--col:last-child {
      display: grid;
      grid-template-columns: 1fr;
    }
    .section-table__header--col:first-child {
      display: grid;
      grid-template-columns: 1fr;
    }
  }
  
  .section-table__main--container {
    display: flex;
    max-width: 100%;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 32px;
    align-items: flex-start;
  }
  .section-table__main--wrapper {
    width: 100%;
  }

  .drop-down-2__block {
    position: fixed;
    display: block;

    opacity: 0;
    visibility: hidden;
    transition: all .3s ease;

    &.active {
      opacity: 1;
      visibility: visible;
    }
  }

  .section-table__main.is-row-mode {
    z-index: 2;
    margin-bottom: 20px;
  }

  .section-table__footer {
    padding-top: 0 !important; 
  }

  .section-table__row {
    border-bottom: 1px solid rgba(95, 100, 114, 0.1);
  }
  .section-table__row:nth-child(even) {
    background: rgba(150, 159, 186, 0.07);
  }

  @media screen and (max-width: 992px) {
    .section-table__search  {
      display: flex;
      align-items: center;
    }
    .section-table__search--label {
      /* position: absolute !important; */
    }

    .table-projects {
      margin-bottom: 10px;
    }
  }
  
  .drop-down__list {
    a {
      cursor: pointer;
    }
  }
  .drop-down__list .is-active {
    background: rgba(150, 159, 187, 0.102);
    a {
      color: var(--accent);
    }
  }
`
