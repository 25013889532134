import React, { useEffect, useState } from "react";
import { IDepartment, ISelect, ITask } from "../models";
import axios, { AxiosResponse } from "axios";
import { getApiLink } from "../functions/getApiLink";
import { CustomSelect } from "../components/customSelect/CustomSelect";

export const useDepartmentsAndTasks = (
  defaultDepartment?: IDepartment,
  defaultTasks?: ITask[]
) => {
  const [allDepartmentsList, setAllDepartmentsList] = useState<IDepartment[]>(
    []
  );
  const [departmentsList, setDepartmentsList] = useState<ISelect[]>([]);
  const [departmentValue, setDepartmentValue] = useState<ISelect>();

  useEffect(() => {
    axios
      .get(getApiLink("/api/admin/department/"))
      .then(({ data }: AxiosResponse<IDepartment[]>) => {
        setAllDepartmentsList(data);

        const _newData: any = data.map((item) => {
          return {
            value: item.id,
            label: item.name,
          };
        });

        setDepartmentValue(_newData[0]);
        setDepartmentsList(_newData);

        if (defaultDepartment?.id) {
          setDepartmentValue(
            _newData.filter((item: any) => item.value === defaultDepartment.id)[0]
          );
        }
      })
      .catch((error) => {});
  }, []);

  const [taskValue, setTaskValue] = useState<ISelect>();
  const [tasksList, setTasksList] = useState<ISelect[]>([]);
  useEffect(() => {
    if (!departmentValue) return;
    if (!allDepartmentsList.length) return;

    const allTaskCategories = allDepartmentsList?.filter(
      (item) => item.id === departmentValue?.value
    )[0]?.task_categories;

    const listToSelect = allTaskCategories?.map((item) => ({
      value: item.id,
      label: item.name,
    }));

    if (defaultDepartment?.id === departmentValue.value) {
      setTaskValue(
        defaultTasks?.length
          ? listToSelect.filter((item) => item.value === defaultTasks[0].id)[0]
          : listToSelect[0]
      );
    } else {
      setTaskValue(listToSelect[0]);
    }

    setTasksList(listToSelect);
  }, [departmentValue]);

  return {
    departments: {
      select: (
        <CustomSelect
          list={departmentsList}
          selectValue={departmentValue}
          defaultValue={departmentValue}
          setSelectedItem={setDepartmentValue}
        />
      ),
      value: departmentValue,
    },
    tasks: {
      select: (
        <CustomSelect
          list={tasksList}
          selectValue={taskValue}
          setSelectedItem={setTaskValue}
        />
      ),
      value: taskValue,
    },
  };
};
