import styled from "styled-components";

export const PopupListStyled = styled.div`
  .custom-select__body {
    max-height: 155px;
  }

  .popup-form__item--row {
    .custom-select__head {
      justify-content: space-between;
    }
  }

  .custom-select__head input {
    height: 100%;
    width: 70%;
    position: static;
  }

  .popup-form__open-sub-popup {
    height: 45px;
    margin-top: -1px;
  }

  label.textarea {
    background: rgba(174, 182, 206, 0.2);
    border-color: transparent;
    cursor: default;
    padding: 13px 20px;
    transition: color 0.2s ease, border-color 0.2s ease;
    p {
      color: var(--text-color);
      font-size: 100%;
      font-weight: 400;
      padding-top: 10px;

      &:first-child {
        padding-top: 0;
      }
    }
  }


  @media screen and (max-width: 992px) {
    .profile .popup-container {
      overflow: hidden;
    }
  }
  
  @media screen and (min-width: 550px) {
    .popup-body-new-project {
      height: min(522px, var(--vh, 1vh) * 100);
    }
  }

  .add-new-employee__body .profile__info--button,
  .add-new-employee__body input {
    height: 45px;
  }


  .profile__body .profile__info--row:not(:first-child) {
    margin-top: 0;
  }

`
