import Cookies from "universal-cookie";

export default function setCookie(name: any, data: any, maxTime?: number) {
  const cookies = new Cookies();


  const domain = window.location.hostname;
  const maxAge = maxTime ?? 999999999;

  cookies.set(name, data, { path: "/", expires: new Date(new Date().getTime() + maxAge * 60), domain })
//   document.cookie = `${name}=${data}; path=/; max-age=${maxAge}; secure;`;
}
