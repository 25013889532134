import { BorderStyle, Document, Packer, Paragraph, Table, TableCell, TableRow, VerticalAlign, WidthType, TextRun, AlignmentType, Header, ImageRun, ExternalHyperlink } from "docx";
import { saveAs } from "file-saver";
import { ICategory, IExpense } from "../../models";
import { log } from "console";
import { MonthNumber } from "../../constants/MonthNumber";
import { Children } from "react";

export const CostsDocsTemplate = ({ image, currency, chosenTimesheet, chosenProject, ...props }: any) => {

    const chosenProjectId = chosenProject?.id || chosenProject?.value
    const chosenProjectName = chosenProject?.name || chosenProject?.label

    let expenses = props.expenses.map((item: any) => {
        return {
            ...item,
            sum: +item.sum.toFixed(2),
            sum_in_currency: +item.sum_in_currency.toFixed(4)
        }
    });

    expenses = expenses?.sort((a: any, b: any) => {
        const c = new Date(`${a.date[3]}${a.date[4]}.${a.date[0]}${a.date[1]}.${a.date[6]}${a.date[7]}`).getTime();
        const d = new Date(`${b.date[3]}${b.date[4]}.${b.date[0]}${b.date[1]}.${b.date[6]}${b.date[7]}`).getTime();
        return c - d
    })
    

    expenses = chosenProjectId ? expenses.filter((item: IExpense) => item.project.id === chosenProjectId) : expenses

    const expensesToTable = expenses?.map((item: any, index: number) => {
        const isLastItem = expenses.length - 1 === index

        return new TableRow({
            children: [
                new TableCell({
                    children: [new Paragraph({
                        children: [
                            new TextRun({
                                text: item?.date?.replaceAll(".", "/"),
                                font: "Calibri",
                            })
                        ],
                        alignment: AlignmentType.CENTER
                    })],
                    borders: { top: { style: BorderStyle.SINGLE, size: 1 }, bottom: { style: isLastItem ? BorderStyle.SINGLE : BorderStyle.DOTTED, size: 1 }, left: { style: BorderStyle.SINGLE, size: 1 }, right: { style: BorderStyle.DOTTED, size: 1 } },
                    verticalAlign: VerticalAlign.CENTER,
                    margins: { top: 30, bottom: 30, left: 50, right: 50 },
                }),
                new TableCell({
                    children: [new Paragraph({
                        children: [
                            new TextRun({
                                text: item?.project?.name,
                                font: "Calibri",
                            })
                        ],
                        alignment: AlignmentType.CENTER
                    })],
                    borders: { top: { style: BorderStyle.SINGLE, size: 1 }, bottom: { style: isLastItem ? BorderStyle.SINGLE : BorderStyle.DOTTED, size: 1 }, left: { style: BorderStyle.DOTTED, size: 1 }, right: { style: BorderStyle.DOTTED, size: 1 } },
                    verticalAlign: VerticalAlign.CENTER,
                    margins: { top: 30, bottom: 30, left: 50, right: 50 },
                }),
                new TableCell({
                    children: [new Paragraph({
                        children: [
                            new TextRun({
                                text: item?.category?.name,
                                font: "Calibri",
                            })
                        ],
                        alignment: AlignmentType.CENTER
                    })],
                    borders: { top: { style: BorderStyle.SINGLE, size: 1 }, bottom: { style: isLastItem ? BorderStyle.SINGLE : BorderStyle.DOTTED, size: 1 }, left: { style: BorderStyle.DOTTED, size: 1 }, right: { style: BorderStyle.DOTTED, size: 1 } },
                    verticalAlign: VerticalAlign.CENTER,
                    margins: { top: 30, bottom: 30, left: 50, right: 50 },
                }),
                new TableCell({
                    children: [new Paragraph({
                        children: [
                            new TextRun({
                                text: item?.description,
                                font: "Calibri",
                            })
                        ],
                        alignment: AlignmentType.LEFT
                    })],
                    borders: { top: { style: BorderStyle.SINGLE, size: 1 }, bottom: { style: isLastItem ? BorderStyle.SINGLE : BorderStyle.DOTTED, size: 1 }, left: { style: BorderStyle.DOTTED, size: 1 }, right: { style: BorderStyle.DOTTED, size: 1 } },
                    verticalAlign: VerticalAlign.CENTER,
                    margins: { top: 30, bottom: 30, left: 50, right: 50 },
                }),
                new TableCell({
                    children: [new Paragraph({
                        children: [
                            new TextRun({
                                text: item?.sum_in_currency.toString(),
                                font: "Calibri",
                            })
                        ],
                        alignment: AlignmentType.CENTER
                    })],
                    borders: { top: { style: BorderStyle.SINGLE, size: 1 }, bottom: { style: isLastItem ? BorderStyle.SINGLE : BorderStyle.DOTTED, size: 1 }, left: { style: BorderStyle.DOTTED, size: 1 }, right: { style: BorderStyle.DOTTED, size: 1 } },
                    verticalAlign: VerticalAlign.CENTER,
                    margins: { top: 30, bottom: 30, left: 50, right: 50 },
                }),
                new TableCell({
                    children: [new Paragraph({
                        children: [
                            new TextRun({
                                text: item?.currency.toUpperCase(),
                                font: "Calibri",
                            })
                        ],
                        alignment: AlignmentType.CENTER
                    })],
                    borders: { top: { style: BorderStyle.SINGLE, size: 1 }, bottom: { style: isLastItem ? BorderStyle.SINGLE : BorderStyle.DOTTED, size: 1 }, left: { style: BorderStyle.DOTTED, size: 1 }, right: { style: BorderStyle.DOTTED, size: 1 } },
                    verticalAlign: VerticalAlign.CENTER,
                    margins: { top: 30, bottom: 30, left: 50, right: 50 },
                }),
                new TableCell({
                    children: [new Paragraph({
                        children: [
                            new TextRun({
                                text: item?.sum.toString(),
                                font: "Calibri",
                            })
                        ],
                        alignment: AlignmentType.CENTER
                    })],
                    borders: { top: { style: BorderStyle.SINGLE, size: 1 }, bottom: { style: isLastItem ? BorderStyle.SINGLE : BorderStyle.DOTTED, size: 1 }, left: { style: BorderStyle.DOTTED, size: 1 }, right: { style: BorderStyle.SINGLE, size: 1 } },
                    verticalAlign: VerticalAlign.CENTER,
                    margins: { top: 30, bottom: 30, left: 50, right: 50 },
                }),
                new TableCell({
                    children: [new Paragraph({
                        children: [
                            new ExternalHyperlink({
                              children: [
                                  new TextRun({
                                      text: item.check_file ? "File" : "",
                                      font: "Calibri",
                                  })
                                ],
                                link: item.check_file ? "https://api-ces.timesheet.space/"+item.check_file : "#",
                            })
                        ],
                        alignment: AlignmentType.CENTER
                    })],
                    borders: { top: { style: BorderStyle.SINGLE, size: 1 }, bottom: { style: isLastItem ? BorderStyle.SINGLE : BorderStyle.DOTTED, size: 1 }, left: { style: BorderStyle.DOTTED, size: 1 }, right: { style: BorderStyle.SINGLE, size: 1 } },
                    verticalAlign: VerticalAlign.CENTER,
                    margins: { top: 30, bottom: 30, left: 50, right: 50 },
                })
            ]
        })
    })

    const totalExpenses = expenses.reduce((sum: number, curr: any) => {
        return sum += curr.sum
    }, 0)

    const allProjectsWithoutDoubles = expenses.reduce((arr: ICategory[], curr: any) => {

        if (!arr.some(item => item.id === curr.category.id)) {
            arr.push(curr.category)
        }

        return arr;
    }, [])

    let countOfAllProjects = 0

    const projectsTotal = allProjectsWithoutDoubles.map((category: ICategory, index: number) => {

        const sum = expenses.filter((item: any) => item.category.id === category.id).reduce((arr: number, curr: any) => {
            return arr += curr.sum
        }, 0)

        countOfAllProjects += sum

        const isLastItem = allProjectsWithoutDoubles.length - 1 === index

        return new TableRow({
            children: [
                new TableCell({
                    children: [new Paragraph({
                        children: [
                            new TextRun({
                                text: category.name,
                                font: "Calibri",
                            })
                        ],
                        alignment: AlignmentType.RIGHT
                    })],
                    borders: { top: { style: BorderStyle.SINGLE, size: 1 }, bottom: { style: isLastItem ? BorderStyle.SINGLE : BorderStyle.DOTTED, size: 1 }, left: { style: BorderStyle.SINGLE, size: 1 }, right: { style: BorderStyle.DOTTED, size: 1 } },
                    verticalAlign: VerticalAlign.CENTER,
                    margins: { top: 30, bottom: 30, left: 50, right: 50 },
                }),
                new TableCell({
                    children: [new Paragraph({
                        children: [
                            new TextRun({
                                text: sum.toFixed(2).toString(),
                                font: "Calibri",
                            })
                        ],
                        alignment: AlignmentType.RIGHT
                    })],
                    borders: { top: { style: BorderStyle.SINGLE, size: 1 }, bottom: { style: isLastItem ? BorderStyle.SINGLE : BorderStyle.DOTTED, size: 1 }, left: { style: BorderStyle.DOTTED, size: 1 }, right: { style: BorderStyle.SINGLE, size: 1 } },
                    verticalAlign: VerticalAlign.CENTER,
                    margins: { top: 30, bottom: 30, left: 50, right: 50 },
                })
            ]
        })

    })

    const allCurr = expenses.map((item: any) => item.currency)

    const allCurrency = Object.keys(currency).filter(item => item !== 'date').filter(item => allCurr.some((i:any) => i === item)).map((item: string, index: number) => {
        const isLastItem = Object.keys(currency).filter(item => item !== 'date').filter(item => allCurr.some((i:any) => i === item)).length - 1 === index
        
        return new TableRow({
            children: [
                new TableCell({
                    children: [new Paragraph({
                        children: [
                            new TextRun({
                                text: item.toUpperCase(),
                                font: "Calibri",
                            })
                        ],
                        alignment: AlignmentType.RIGHT
                    })],
                    borders: { top: { style: BorderStyle.SINGLE, size: 1 }, bottom: { style: isLastItem ? BorderStyle.SINGLE : BorderStyle.DOTTED, size: 1 }, left: { style: BorderStyle.SINGLE, size: 1 }, right: { style: BorderStyle.DOTTED, size: 1 } },
                    verticalAlign: VerticalAlign.CENTER,
                    margins: { top: 30, bottom: 30, left: 50, right: 50 },
                }),
                new TableCell({
                    children: [new Paragraph({
                        children: [
                            new TextRun({
                                text: (1 / currency[item]).toFixed(4).toString(),
                                font: "Calibri",
                            })
                        ],
                        alignment: AlignmentType.RIGHT
                    })],
                    borders: { top: { style: BorderStyle.SINGLE, size: 1 }, bottom: { style: isLastItem ? BorderStyle.SINGLE : BorderStyle.DOTTED, size: 1 }, left: { style: BorderStyle.DOTTED, size: 1 }, right: { style: BorderStyle.SINGLE, size: 1 } },
                    verticalAlign: VerticalAlign.CENTER,
                    margins: { top: 30, bottom: 30, left: 50, right: 50 },
                })
            ]
        })
    })




    
    const tableLogo = {
        default: new Header({
            children: [
                new Paragraph({
                    children: [
                        new ImageRun({
                            data: image,
                            transformation: {
                                width: 70,
                                height: 55,
                            },
                        }),
                    ],
                    alignment: AlignmentType.RIGHT,
                }),
            ],
        }),
    }

    const isHaveChosenProject = chosenProjectId ? [
        new Paragraph({
            children: [
                new TextRun({ text: "Project number: ", bold: true, size: 28, font: 'Calibri' }),
                new TextRun({ text: chosenProjectName, italics: true, size: 28, font: 'Calibri' })
            ]
        }),
    ] : []

    const tableHeader = [
        // Заголовок
        new Paragraph({
            children: [new TextRun({ text: "Costs Report", size: 40, bold: true, font: 'Calibri' })],
            alignment: AlignmentType.LEFT,
        }),
        new Paragraph(""),
        new Paragraph(""),
        // Expert Name
        new Paragraph({
            children: [
                new TextRun({ text: "Expert Name: ", bold: true, size: 28, font: 'Calibri' }),
                new TextRun({ text: `${chosenTimesheet.user.first_name} ${chosenTimesheet.user.last_name}`, italics: true, size: 28, font: 'Calibri' })
            ]
        }),
        // Project number
        ...isHaveChosenProject,
        // Period
        new Paragraph({
            children: [
                new TextRun({ text: "Period: ", bold: true, size: 28, font: 'Calibri' }),
                new TextRun({ text: `${MonthNumber()[+chosenTimesheet.date.split('/')[1]]?.en_title} 20${chosenTimesheet.date.split('/')[2]}`, italics: true, size: 28, font: 'Calibri' })
            ]
        }),
    ]

    const mainTable = [
        new Table({
            rows: [
                new TableRow({
                    children: [
                        new TableCell({
                            children: [new Paragraph({
                                children: [
                                    new TextRun({
                                        text: "Date",
                                        bold: true,
                                        font: "Calibri",
                                    })
                                ],
                                alignment: AlignmentType.CENTER
                            })],
                            borders: { top: { style: BorderStyle.SINGLE, size: 3 }, bottom: { style: BorderStyle.SINGLE, size: 3 }, left: { style: BorderStyle.SINGLE, size: 3 }, right: { style: BorderStyle.SINGLE, size: 3 } },
                            verticalAlign: VerticalAlign.CENTER,
                            width: { size: 10, type: WidthType.PERCENTAGE },
                            shading: { fill: "FBC900" },
                            margins: { top: 30, bottom: 30, left: 50, right: 50 },
                        }),
                        new TableCell({
                            children: [new Paragraph({
                                children: [
                                    new TextRun({
                                        text: "Project Num.",  // Перенос текста
                                        bold: true,
                                        font: "Calibri",
                                    })
                                ],
                                alignment: AlignmentType.CENTER  // Горизонтальное выравнивание
                            })],
                            borders: {
                                top: { style: BorderStyle.SINGLE, size: 3 },
                                bottom: { style: BorderStyle.SINGLE, size: 3 },
                                left: { style: BorderStyle.SINGLE, size: 3 },
                                right: { style: BorderStyle.SINGLE, size: 3 }
                            },
                            verticalAlign: VerticalAlign.CENTER,  // Вертикальное выравнивание
                            width: { size: 14, type: WidthType.PERCENTAGE },
                            shading: { fill: "FBC900" },
                            margins: { top: 30, bottom: 30, left: 50, right: 50 },
                        }),
                        new TableCell({
                            children: [new Paragraph({
                                children: [
                                    new TextRun({
                                        text: "Category",  // Перенос текста
                                        bold: true,
                                        font: "Calibri",
                                    })
                                ],
                                alignment: AlignmentType.CENTER  // Горизонтальное выравнивание
                            })],
                            borders: {
                                top: { style: BorderStyle.SINGLE, size: 3 },
                                bottom: { style: BorderStyle.SINGLE, size: 3 },
                                left: { style: BorderStyle.SINGLE, size: 3 },
                                right: { style: BorderStyle.SINGLE, size: 3 }
                            },
                            verticalAlign: VerticalAlign.CENTER,  // Вертикальное выравнивание
                            width: { size: 14, type: WidthType.PERCENTAGE },
                            shading: { fill: "FBC900" },
                            margins: { top: 30, bottom: 30, left: 50, right: 50 },
                        }),
                        new TableCell({
                            children: [new Paragraph({
                                children: [
                                    new TextRun({
                                        text: "Cost Description",
                                        bold: true,
                                        font: "Calibri",
                                    })
                                ],
                                alignment: AlignmentType.CENTER
                            })],
                            borders: { top: { style: BorderStyle.SINGLE, size: 3 }, bottom: { style: BorderStyle.SINGLE, size: 3 }, left: { style: BorderStyle.SINGLE, size: 3 }, right: { style: BorderStyle.SINGLE, size: 3 } },
                            verticalAlign: VerticalAlign.CENTER,
                            width: { size: 30, type: WidthType.PERCENTAGE },
                            shading: { fill: "FBC900" },
                            margins: { top: 30, bottom: 30, left: 50, right: 50 },
                        }),
                        new TableCell({
                            children: [new Paragraph({
                                children: [
                                    new TextRun({
                                        text: "Cost",
                                        bold: true,
                                        font: "Calibri",
                                    })
                                ],
                                alignment: AlignmentType.CENTER
                            })],
                            borders: { top: { style: BorderStyle.SINGLE, size: 3 }, bottom: { style: BorderStyle.SINGLE, size: 3 }, left: { style: BorderStyle.SINGLE, size: 3 }, right: { style: BorderStyle.SINGLE, size: 3 } },
                            verticalAlign: VerticalAlign.CENTER,
                            width: { size: 7, type: WidthType.PERCENTAGE },
                            shading: { fill: "FBC900" },
                            margins: { top: 30, bottom: 30, left: 50, right: 50 },
                        }),
                        new TableCell({
                            children: [new Paragraph({
                                children: [
                                    new TextRun({
                                        text: "Currency",
                                        bold: true,
                                        font: "Calibri",
                                    })
                                ],
                                alignment: AlignmentType.CENTER
                            })],
                            borders: { top: { style: BorderStyle.SINGLE, size: 3 }, bottom: { style: BorderStyle.SINGLE, size: 3 }, left: { style: BorderStyle.SINGLE, size: 3 }, right: { style: BorderStyle.SINGLE, size: 3 } },
                            verticalAlign: VerticalAlign.CENTER,
                            width: { size: 12, type: WidthType.PERCENTAGE },
                            shading: { fill: "FBC900" },
                            margins: { top: 30, bottom: 30, left: 50, right: 50 },
                        }),
                        new TableCell({
                            children: [new Paragraph({
                                children: [
                                    new TextRun({
                                        text: "Cost, EUR",
                                        bold: true,
                                        font: "Calibri",
                                    })
                                ],
                                alignment: AlignmentType.CENTER
                            })],
                            borders: { top: { style: BorderStyle.SINGLE, size: 3 }, bottom: { style: BorderStyle.SINGLE, size: 3 }, left: { style: BorderStyle.SINGLE, size: 3 }, right: { style: BorderStyle.SINGLE, size: 3 } },
                            verticalAlign: VerticalAlign.CENTER,
                            width: { size: 15, type: WidthType.PERCENTAGE },
                            shading: { fill: "FBC900" },
                            margins: { top: 30, bottom: 30, left: 50, right: 50 },
                        }),
                        new TableCell({
                            children: [new Paragraph({
                                children: [
                                    new TextRun({
                                        text: "Receipt",
                                        bold: true,
                                        font: "Calibri",
                                    })
                                ],
                                alignment: AlignmentType.CENTER
                            })],
                            borders: { top: { style: BorderStyle.SINGLE, size: 3 }, bottom: { style: BorderStyle.SINGLE, size: 3 }, left: { style: BorderStyle.SINGLE, size: 3 }, right: { style: BorderStyle.SINGLE, size: 3 } },
                            verticalAlign: VerticalAlign.CENTER,
                            width: { size: 15, type: WidthType.PERCENTAGE },
                            shading: { fill: "FBC900" },
                            margins: { top: 30, bottom: 30, left: 50, right: 50 },
                        })
                    ]
                }),

                ...expensesToTable,

                new TableRow({
                    children: [
                        new TableCell({
                            children: [new Paragraph({
                                children: [
                                    new TextRun({
                                        text: "",
                                        font: "Calibri",
                                    })
                                ],
                                alignment: AlignmentType.CENTER
                            })],
                            borders: { top: { style: BorderStyle.SINGLE, size: 1 }, bottom: { style: BorderStyle.SINGLE, size: 1 }, left: { style: BorderStyle.SINGLE, size: 1 }, right: { style: BorderStyle.SINGLE, size: 1 } },
                            verticalAlign: VerticalAlign.CENTER,
                            margins: { top: 30, bottom: 30, left: 50, right: 50 },
                            shading: { fill: "EFEFEF" }
                        }),
                        new TableCell({
                            children: [new Paragraph({
                                children: [
                                    new TextRun({
                                        text: "",
                                        font: "Calibri",
                                    })
                                ],
                                alignment: AlignmentType.CENTER
                            })],
                            borders: { top: { style: BorderStyle.SINGLE, size: 1 }, bottom: { style: BorderStyle.SINGLE, size: 1 }, left: { style: BorderStyle.SINGLE, size: 1 }, right: { style: BorderStyle.SINGLE, size: 1 } },
                            verticalAlign: VerticalAlign.CENTER,
                            margins: { top: 30, bottom: 30, left: 50, right: 50 },
                            shading: { fill: "EFEFEF" }
                        }),
                        new TableCell({
                            children: [new Paragraph({
                                children: [
                                    new TextRun({
                                        text: "",
                                        font: "Calibri",
                                    })
                                ],
                                alignment: AlignmentType.CENTER
                            })],
                            borders: { top: { style: BorderStyle.SINGLE, size: 1 }, bottom: { style: BorderStyle.SINGLE, size: 1 }, left: { style: BorderStyle.SINGLE, size: 1 }, right: { style: BorderStyle.SINGLE, size: 1 } },
                            verticalAlign: VerticalAlign.CENTER,
                            margins: { top: 30, bottom: 30, left: 50, right: 50 },
                            shading: { fill: "EFEFEF" }
                        }),
                        new TableCell({
                            children: [new Paragraph({
                                children: [
                                    new TextRun({
                                        text: "",
                                        font: "Calibri",
                                    })
                                ],
                                alignment: AlignmentType.CENTER
                            })],
                            borders: { top: { style: BorderStyle.SINGLE, size: 1 }, bottom: { style: BorderStyle.SINGLE, size: 1 }, left: { style: BorderStyle.SINGLE, size: 1 }, right: { style: BorderStyle.SINGLE, size: 1 } },
                            verticalAlign: VerticalAlign.CENTER,
                            margins: { top: 30, bottom: 30, left: 50, right: 50 },
                            shading: { fill: "EFEFEF" }
                        }),
                        new TableCell({
                            children: [new Paragraph({
                                children: [
                                    new TextRun({
                                        text: "Total",
                                        bold: true,
                                        font: "Calibri",
                                    })
                                ],
                                alignment: AlignmentType.CENTER
                            })],
                            borders: { top: { style: BorderStyle.SINGLE, size: 1 }, bottom: { style: BorderStyle.SINGLE, size: 1 }, left: { style: BorderStyle.SINGLE, size: 1 }, right: { style: BorderStyle.SINGLE, size: 1 } },
                            verticalAlign: VerticalAlign.CENTER,
                            margins: { top: 30, bottom: 30, left: 50, right: 50 },
                            columnSpan: 2,
                            shading: { fill: "EFEFEF" }
                        }),

                        new TableCell({
                            children: [new Paragraph({
                                children: [
                                    new TextRun({
                                        text: totalExpenses.toString(),
                                        bold: true,
                                        font: "Calibri",
                                    })
                                ],
                                alignment: AlignmentType.CENTER
                            })],
                            borders: { top: { style: BorderStyle.SINGLE, size: 1 }, bottom: { style: BorderStyle.SINGLE, size: 1 }, left: { style: BorderStyle.SINGLE, size: 1 }, right: { style: BorderStyle.SINGLE, size: 1 } },
                            verticalAlign: VerticalAlign.CENTER,
                            margins: { top: 30, bottom: 30, left: 50, right: 50 },
                            shading: { fill: "EFEFEF" }
                        }),
                        new TableCell({
                            children: [new Paragraph({
                                children: [
                                    new TextRun({
                                        text: "",
                                        bold: true,
                                        font: "Calibri",
                                    })
                                ],
                                alignment: AlignmentType.CENTER
                            })],
                            borders: { top: { style: BorderStyle.SINGLE, size: 1 }, bottom: { style: BorderStyle.SINGLE, size: 1 }, left: { style: BorderStyle.SINGLE, size: 1 }, right: { style: BorderStyle.SINGLE, size: 1 } },
                            verticalAlign: VerticalAlign.CENTER,
                            margins: { top: 30, bottom: 30, left: 50, right: 50 },
                            shading: { fill: "EFEFEF" }
                        })
                    ]
                })
            ]
        }),
    ]

    const doubleTables = [
        new Table({
            rows: [
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Table({
                                    rows: [
                                        new TableRow({
                                            children: [
                                                new TableCell({
                                                    children: [new Paragraph({
                                                        children: [
                                                            new TextRun({
                                                                text: "Category",
                                                                bold: true,
                                                                font: "Calibri",
                                                            })
                                                        ],
                                                        alignment: AlignmentType.CENTER
                                                    })],
                                                    width: { size: 12, type: WidthType.PERCENTAGE },
                                                    borders: { top: { style: BorderStyle.SINGLE, size: 1 }, bottom: { style: BorderStyle.SINGLE, size: 1 }, left: { style: BorderStyle.SINGLE, size: 1 }, right: { style: BorderStyle.SINGLE, size: 1 } },
                                                    verticalAlign: VerticalAlign.CENTER,
                                                    shading: { fill: "FBC900" },
                                                    margins: { top: 30, bottom: 30, left: 50, right: 50 },
                                                }),
                                                new TableCell({
                                                    children: [new Paragraph({
                                                        children: [
                                                            new TextRun({
                                                                text: "Total",
                                                                bold: true,
                                                                font: "Calibri",
                                                            })
                                                        ],
                                                        alignment: AlignmentType.CENTER
                                                    })],
                                                    width: { size: 8, type: WidthType.PERCENTAGE },
                                                    borders: { top: { style: BorderStyle.SINGLE, size: 1 }, bottom: { style: BorderStyle.SINGLE, size: 1 }, left: { style: BorderStyle.SINGLE, size: 1 }, right: { style: BorderStyle.SINGLE, size: 1 } },
                                                    verticalAlign: VerticalAlign.CENTER,
                                                    shading: { fill: "FBC900" },
                                                    margins: { top: 30, bottom: 30, left: 50, right: 50 },
                                                })
                                            ]
                                        }),
                                        ...projectsTotal,
                                        new TableRow({
                                            children: [
                                                new TableCell({
                                                    children: [new Paragraph({
                                                        children: [
                                                            new TextRun({
                                                                text: "Total:",
                                                                bold: true,
                                                                font: "Calibri",
                                                            })
                                                        ],
                                                        alignment: AlignmentType.RIGHT
                                                    })],
                                                    shading: { fill: "EFEFEF" },
                                                    borders: { top: { style: BorderStyle.SINGLE, size: 1 }, bottom: { style: BorderStyle.SINGLE, size: 1 }, left: { style: BorderStyle.SINGLE, size: 1 }, right: { style: BorderStyle.SINGLE, size: 1 } },
                                                    verticalAlign: VerticalAlign.CENTER,
                                                    margins: { top: 30, bottom: 30, left: 50, right: 50 },
                                                }),
                                                new TableCell({
                                                    children: [new Paragraph({
                                                        children: [
                                                            new TextRun({
                                                                text: countOfAllProjects.toString(),
                                                                bold: true,
                                                                font: "Calibri",
                                                            })
                                                        ],
                                                        alignment: AlignmentType.RIGHT
                                                    })],
                                                    shading: { fill: "EFEFEF" },
                                                    borders: { top: { style: BorderStyle.SINGLE, size: 1 }, bottom: { style: BorderStyle.SINGLE, size: 1 }, left: { style: BorderStyle.SINGLE, size: 1 }, right: { style: BorderStyle.SINGLE, size: 1 } },
                                                    verticalAlign: VerticalAlign.CENTER,
                                                    margins: { top: 30, bottom: 30, left: 50, right: 50 },
                                                })
                                            ]
                                        })
                                    ]
                                })
                            ],
                            width: { size: 8, type: WidthType.PERCENTAGE },
                            borders: { top: { style: BorderStyle.NONE, color: "FFFFFF" }, bottom: { style: BorderStyle.NONE, color: "FFFFFF" }, left: { style: BorderStyle.NONE, color: "FFFFFF" }, right: { style: BorderStyle.NONE, color: "FFFFFF" } }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    children: [
                                        new TextRun({
                                            text: "..",
                                            color: "FFFFFF"
                                        })
                                    ]
                                })
                            ],
                            width: { size: 4, type: WidthType.PERCENTAGE },
                            borders: { top: { style: BorderStyle.NONE, color: "FFFFFF" }, bottom: { style: BorderStyle.NONE, color: "FFFFFF" }, left: { style: BorderStyle.NONE, color: "FFFFFF" }, right: { style: BorderStyle.NONE, color: "FFFFFF" } }
                        }),
                        new TableCell({
                            children: [
                                new Table({
                                    rows: [
                                        new TableRow({
                                            children: [
                                                new TableCell({
                                                    children: [new Paragraph({
                                                        children: [
                                                            new TextRun({
                                                                text: "Currency",
                                                                bold: true,
                                                                font: "Calibri",
                                                            })
                                                        ],
                                                        alignment: AlignmentType.CENTER
                                                    })],
                                                    width: { size: 12, type: WidthType.PERCENTAGE },
                                                    borders: { top: { style: BorderStyle.SINGLE, size: 3 }, bottom: { style: BorderStyle.SINGLE, size: 3 }, left: { style: BorderStyle.SINGLE, size: 3 }, right: { style: BorderStyle.SINGLE, size: 3 } },
                                                    verticalAlign: VerticalAlign.CENTER,
                                                    shading: { fill: "FBC900" },
                                                    margins: { top: 30, bottom: 30, left: 50, right: 50 },
                                                }),
                                                new TableCell({
                                                    children: [new Paragraph({
                                                        children: [
                                                            new TextRun({
                                                                text: "Currency exchange rate to EUR",
                                                                bold: true,
                                                                font: "Calibri",
                                                            })
                                                        ],
                                                        alignment: AlignmentType.CENTER
                                                    })],
                                                    width: { size: 38, type: WidthType.PERCENTAGE },
                                                    borders: { top: { style: BorderStyle.SINGLE, size: 3 }, bottom: { style: BorderStyle.SINGLE, size: 3 }, left: { style: BorderStyle.SINGLE, size: 3 }, right: { style: BorderStyle.SINGLE, size: 3 } },
                                                    verticalAlign: VerticalAlign.CENTER,
                                                    shading: { fill: "FBC900" },
                                                    margins: { top: 30, bottom: 30, left: 50, right: 50 },
                                                })
                                            ]
                                        }),
                                        
                                        ...allCurrency,
                                    ]
                                })
                            ],
                            width: { size: 12, type: WidthType.PERCENTAGE },
                            borders: { top: { style: BorderStyle.NONE, color: "FFFFFF" }, bottom: { style: BorderStyle.NONE, color: "FFFFFF" }, left: { style: BorderStyle.NONE, color: "FFFFFF" }, right: { style: BorderStyle.NONE, color: "FFFFFF" } }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    children: [
                                        new TextRun({
                                            text: "..",
                                            color: "FFFFFF"
                                        })
                                    ]
                                })
                            ],
                            width: { size: 1, type: WidthType.PERCENTAGE },
                            borders: { top: { style: BorderStyle.NONE, color: "FFFFFF" }, bottom: { style: BorderStyle.NONE, color: "FFFFFF" }, left: { style: BorderStyle.NONE, color: "FFFFFF" }, right: { style: BorderStyle.NONE, color: "FFFFFF" } }
                        }),
                    ],
                })
            ]
        }),
    ]

    const approvalUser = chosenTimesheet.approves.map((approval: any) => {
        return new Paragraph({
            children: [
                new TextRun({ text: "Approved by: ", bold: true, size: 28, font: 'Calibri' }),
                new TextRun({ text: `${approval.user.first_name} ${approval.user.last_name}`, italics: true, size: 28, font: 'Calibri' })
            ]
        })
    })

    const bottomApproval = chosenTimesheet?.status === "approve" ? [
        ...approvalUser,

        new Paragraph({
            children: [
                new TextRun({ text: "Date: ", bold: true, size: 28, font: 'Calibri' }),
                new TextRun({ text: chosenTimesheet?.updated_at, italics: true, size: 28, font: 'Calibri' })
            ]
        })
    ] : []






    const doc = new Document({
        sections: [
            {
                headers: tableLogo,
                children: [
                    ...tableHeader,

                    new Paragraph(""),
                    new Paragraph(""),

                    ...mainTable,

                    new Paragraph(""),
                    new Paragraph({ children: [new TextRun({ text: "Summary", bold: true, size: 36, font: 'Calibri' })] }),
                    new Paragraph(""),

                    ...doubleTables,

                    new Paragraph(""),
                    new Paragraph(""),

                    ...bottomApproval
                ]
            }
        ]
    });

    const today = `${String(new Date().getFullYear()).slice(2, 4)}${(new Date().getMonth() + 1) < 10 ? "0" + (new Date().getMonth() + 1) : new Date().getMonth() + 1}${new Date().getDate() < 10 ? "0" + new Date().getDate() : new Date().getDate()}`

    Packer.toBlob(doc).then((blob: any) => {
        // saveAs(blob, "Costs_Word.docx");
        saveAs(blob, `${today}_Costs_${chosenTimesheet?.user?.first_name}_${chosenTimesheet?.user?.last_name}_${MonthNumber()[+(chosenTimesheet?.date[3] + chosenTimesheet?.date[4])]?.en_title}`);
    });
};
