import React, {useEffect, useState} from 'react'
import {useLanguage} from "../../../hooks/Language";
import {useClickOutside} from "../../../hooks/ClickOutside";
import {IUser} from "../../../models";
import {useSelector} from "react-redux";

interface IAsideCurrencyProps {
    isMini?: boolean
}

export const AsideCurrency: React.FC<IAsideCurrencyProps> = ({isMini = false}) => {

    const userData: IUser = useSelector((state: any) => state.toolkit.user)

    const isAdmin = userData.status?.includes("admin")

    const {languages, handleSwitch, langSelected} = useLanguage()

    const [isOpenLanguages, setIsOpenLanguages] = useState(false)
    const {rootEl} = useClickOutside(setIsOpenLanguages)

    return (
        <div ref={rootEl} className={`aside__add--item ${isMini ? "is-mini" : ""}`}>
            <div className={`aside__language drop-down is-dark is-bottom ${isOpenLanguages && "is-active"}`} style={{width: isAdmin ? "auto" : "fit-content"}}>
                <button onClick={_ => setIsOpenLanguages(prev => !prev)} className="aside__language--target drop-down__target" type="button">
                    {!isMini && <i>
                        <svg width="15" height="15" viewBox="0 0 15 15">
                            <use xlinkHref="#language"></use>
                        </svg>
                    </i>}
                    <span>
                        {isAdmin && !isMini ? languages.filter(item => item.slug === langSelected)[0]?.titleBig : languages.filter(item => item.slug === langSelected)[0]?.title}
                    </span>
                    {languages.length > 1 && <svg width="10" height="7" viewBox="0 0 10 7"
                                                  className="drop-down__target--arrow">
                        <use xlinkHref="#drop-down-arrow"></use>
                    </svg>}
                </button>
                <div className="aside__language--block drop-down__block">
                    <ul className="drop-down__list">

                        {
                            languages?.filter(lang => lang.slug !== langSelected).map(lang =>
                                <li key={lang.slug} onClick={_ => {
                                    handleSwitch(lang.slug)
                                    setIsOpenLanguages(false)
                                }}>
                                    <a>
                                        {lang.titleBig}
                                    </a>
                                </li>
                            )
                        }

                    </ul>
                </div>
            </div>
        </div>
    )
}
