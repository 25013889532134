import { BorderStyle, Document, Packer, Paragraph, Table, TableCell, TableRow, VerticalAlign, WidthType, TextRun, AlignmentType, Header, ImageRun, PageSize, PageOrientation } from "docx";
import { saveAs } from "file-saver";
import { MonthNumber } from "../../constants/MonthNumber";
import { IProject } from "../../models";

export const OverviewDocsTemplate = ({ image, chosenTimesheet, projects, users, chosenProject, chosenDepartment }: any) => {

    const chosenProjectId = chosenProject?.id || chosenProject?.value
    const chosenProjectName = chosenProject?.name || chosenProject?.label

    users = chosenProjectId ?
        users.filter((item: any) => item.statistics.tasks.some((item2: any) => item2.project.id === chosenProjectId))
        : users

    let allTotalHours = 0
    let allTotalProjects: any[] = []

    const employeesList = users.map((item: any, index: number) => {
        const isLastItem = users.length - 1 === index

        let totalHours = 0

        const AllUsers = [
            new TableCell({
                children: [new Paragraph({
                    children: [
                        new TextRun({
                            text: `${item?.user?.first_name} ${item.user.last_name}`,
                            font: "Calibri",
                            size: 18,
                        })
                    ],
                    alignment: AlignmentType.LEFT
                })],
                borders: { top: { style: BorderStyle.SINGLE, size: 1 }, bottom: { style: isLastItem ? BorderStyle.SINGLE : BorderStyle.DOTTED, size: 1 }, left: { style: BorderStyle.SINGLE, size: 1 }, right: { style: BorderStyle.DOTTED, size: 1 } },
                verticalAlign: VerticalAlign.CENTER,
                margins: { top: 30, bottom: 30, left: 50, right: 50 },
            }),
        ]

        projects.map((proj: IProject) => {

            totalHours += (item.statistics.tasks.filter((item2: any) => item2.project.id === proj.id)[0]?.hours ?? 0)

            if(!allTotalProjects.some(item2 => item2?.projId === proj?.id)) {
                allTotalProjects.push({
                    projId: proj.id,
                    sum: (item.statistics.tasks.filter((item2: any) => item2.project.id === proj.id)[0]?.hours ?? 0)
                })
            } else {
                allTotalProjects = allTotalProjects.map(item2 => {
                    if(item2.projId === proj.id) {
                        return {
                            ...item2,
                            sum: item2.sum + (item.statistics.tasks.filter((item2: any) => item2.project.id === proj.id)[0]?.hours ?? 0)
                        }
                    }

                    return item2
                })
            }
            
            
            AllUsers.push(
                new TableCell({
                    children: [new Paragraph({
                        children: [
                            new TextRun({
                                text: `${item.statistics.tasks.filter((item2: any) => item2.project.id === proj.id)[0]?.hours ?? 0}`,
                                font: "Calibri",
                                size: 18,
                                bold: !!item.statistics.tasks.filter((item2: any) => item2.project.id === proj.id)[0]?.hours
                            })
                        ],
                        alignment: AlignmentType.CENTER
                    })],
                    borders: { top: { style: BorderStyle.SINGLE, size: 1 }, bottom: { style: isLastItem ? BorderStyle.SINGLE : BorderStyle.DOTTED, size: 1 }, left: { style: BorderStyle.DOTTED, size: 1 }, right: { style: BorderStyle.DOTTED, size: 1 } },
                    verticalAlign: VerticalAlign.CENTER,
                    margins: { top: 30, bottom: 30, left: 50, right: 50 },
                }),
            )
        })

        allTotalHours += totalHours

        AllUsers.push(
            ...[
                new TableCell({
                    children: [new Paragraph({
                        children: [
                            new TextRun({
                                text: `${totalHours}`,
                                font: "Calibri",
                                size: 18,
                                bold: !!totalHours
                            })
                        ],
                        alignment: AlignmentType.CENTER
                    })],
                    borders: { top: { style: BorderStyle.SINGLE, size: 1 }, bottom: { style: isLastItem ? BorderStyle.SINGLE : BorderStyle.DOTTED, size: 1 }, left: { style: BorderStyle.DOTTED, size: 1 }, right: { style: BorderStyle.DOTTED, size: 1 } },
                    verticalAlign: VerticalAlign.CENTER,
                    margins: { top: 30, bottom: 30, left: 50, right: 50 },
                }),
                new TableCell({
                    children: [new Paragraph({
                        children: [
                            new TextRun({
                                text: '',
                                font: "Calibri",
                                size: 18,
                            })
                        ],
                        alignment: AlignmentType.CENTER
                    })],
                    borders: { top: { style: BorderStyle.SINGLE, size: 1 }, bottom: { style: isLastItem ? BorderStyle.SINGLE : BorderStyle.DOTTED, size: 1 }, left: { style: BorderStyle.DOTTED, size: 1 }, right: { style: BorderStyle.DOTTED, size: 1 } },
                    verticalAlign: VerticalAlign.CENTER,
                    margins: { top: 30, bottom: 30, left: 50, right: 50 },
                }),
                new TableCell({
                    children: [new Paragraph({
                        children: [
                            new TextRun({
                                text: '',
                                font: "Calibri",
                                size: 18,
                            })
                        ],
                        alignment: AlignmentType.CENTER
                    })],
                    borders: { top: { style: BorderStyle.SINGLE, size: 1 }, bottom: { style: isLastItem ? BorderStyle.SINGLE : BorderStyle.DOTTED, size: 1 }, left: { style: BorderStyle.DOTTED, size: 1 }, right: { style: BorderStyle.DOTTED, size: 1 } },
                    verticalAlign: VerticalAlign.CENTER,
                    margins: { top: 30, bottom: 30, left: 50, right: 50 },
                }),
            ]
        )


        return new TableRow({
            children: AllUsers
        })
    })


    const tableLogo = {
        default: new Header({
            children: [
                new Paragraph({
                    children: [
                        new ImageRun({
                            data: image,
                            transformation: {
                                width: 70,
                                height: 55,
                            },
                        }),
                    ],
                    alignment: AlignmentType.RIGHT,
                }),
            ],
        }),
    }

    const footerBlock = [
        new TableCell({
            children: [new Paragraph({
                children: [
                    new TextRun({
                        text: "Total working days",
                        bold: true,
                        font: "Calibri",
                        size: 18,
                    })
                ],
                alignment: AlignmentType.CENTER
            })],
            borders: { top: { style: BorderStyle.SINGLE, size: 3 }, bottom: { style: BorderStyle.SINGLE, size: 3 }, left: { style: BorderStyle.SINGLE, size: 3 }, right: { style: BorderStyle.SINGLE, size: 3 } },
            verticalAlign: VerticalAlign.CENTER,
            shading: { fill: "EFEFEF" },
            margins: { top: 30, bottom: 30, left: 50, right: 50 },
        }),

    ]

    const isHaveChosenProject = chosenProjectId ? [
        new Paragraph({
            children: [
                new TextRun({ text: "Project number: ", bold: true, size: 28, font: 'Calibri' }),
                new TextRun({ text: chosenProjectName, italics: true, size: 28, font: 'Calibri' })
            ]
        }),
    ] : []

    const allProjectsTable = projects.map((proj: IProject) => {

        const projSum = allTotalProjects.filter(item2 => item2.projId === proj.id)[0].sum

        footerBlock.push(
            new TableCell({
                children: [new Paragraph({
                    children: [
                        new TextRun({
                            text: `${projSum}`,  // Перенос текста
                            bold: true,
                            font: "Calibri",
                            size: 18,
                        })
                    ],
                    alignment: AlignmentType.CENTER  // Горизонтальное выравнивание
                })],
                borders: {
                    top: { style: BorderStyle.SINGLE, size: 3 },
                    bottom: { style: BorderStyle.SINGLE, size: 3 },
                    left: { style: BorderStyle.SINGLE, size: 3 },
                    right: { style: BorderStyle.SINGLE, size: 3 }
                },
                verticalAlign: VerticalAlign.CENTER,  // Вертикальное выравнивание
                shading: { fill: "EFEFEF" },
                margins: { top: 30, bottom: 30, left: 50, right: 50 },
            }),
        )

        return new TableCell({
            children: [new Paragraph({
                children: [
                    new TextRun({
                        text: `${proj.name}`,  // Перенос текста
                        bold: true,
                        font: "Calibri",
                        size: 18,
                    })
                ],
                alignment: AlignmentType.CENTER  // Горизонтальное выравнивание
            })],
            borders: {
                top: { style: BorderStyle.SINGLE, size: 3 },
                bottom: { style: BorderStyle.SINGLE, size: 3 },
                left: { style: BorderStyle.SINGLE, size: 3 },
                right: { style: BorderStyle.SINGLE, size: 3 }
            },
            verticalAlign: VerticalAlign.CENTER,  // Вертикальное выравнивание
            width: { size: 9, type: WidthType.PERCENTAGE },
            shading: { fill: "FBC900" },
            margins: { top: 30, bottom: 30, left: 50, right: 50 },
        })
    })

    footerBlock.push(
        ...[
            new TableCell({
                children: [new Paragraph({
                    children: [
                        new TextRun({
                            text: `${allTotalHours}`,
                            bold: true,
                            font: "Calibri",
                            size: 18,
                        })
                    ],
                    alignment: AlignmentType.CENTER
                })],
                borders: { top: { style: BorderStyle.SINGLE, size: 3 }, bottom: { style: BorderStyle.SINGLE, size: 3 }, left: { style: BorderStyle.SINGLE, size: 3 }, right: { style: BorderStyle.SINGLE, size: 3 } },
                verticalAlign: VerticalAlign.CENTER,
                shading: { fill: "EFEFEF" },
                margins: { top: 30, bottom: 30, left: 50, right: 50 },
            }),
            new TableCell({
                children: [new Paragraph({
                    children: [
                        new TextRun({
                            text: '',
                            bold: true,
                            font: "Calibri",
                            size: 18,
                        })
                    ],
                    alignment: AlignmentType.CENTER
                })],
                borders: { top: { style: BorderStyle.SINGLE, size: 3 }, bottom: { style: BorderStyle.SINGLE, size: 3 }, left: { style: BorderStyle.SINGLE, size: 3 }, right: { style: BorderStyle.SINGLE, size: 3 } },
                verticalAlign: VerticalAlign.CENTER,
                shading: { fill: "EFEFEF" },
                margins: { top: 30, bottom: 30, left: 50, right: 50 },
            }),
            new TableCell({
                children: [new Paragraph({
                    children: [
                        new TextRun({
                            text: '',
                            bold: true,
                            font: "Calibri",
                            size: 18,
                        })
                    ],
                    alignment: AlignmentType.CENTER
                })],
                borders: { top: { style: BorderStyle.SINGLE, size: 3 }, bottom: { style: BorderStyle.SINGLE, size: 3 }, left: { style: BorderStyle.SINGLE, size: 3 }, right: { style: BorderStyle.SINGLE, size: 3 } },
                verticalAlign: VerticalAlign.CENTER,
                shading: { fill: "EFEFEF" },
                margins: { top: 30, bottom: 30, left: 50, right: 50 },
            }),
        ]
    )

    const tableHeader = [
        // Заголовок
        new Paragraph({
            children: [new TextRun({ text: "Activity Timesheet", size: 40, bold: true, font: 'Calibri' })],
            alignment: AlignmentType.LEFT,
        }),
        new Paragraph(""),
        new Paragraph(""),
        // Project number
        ...isHaveChosenProject,
        // Period
        new Paragraph({
            children: [
                new TextRun({ text: "Name of the Department/legal entity: ", bold: true, size: 28, font: 'Calibri' }),
                new TextRun({ text: `${chosenDepartment?.label}`, italics: true, size: 28, font: 'Calibri' })
            ]
        }),
        new Paragraph({
            children: [
                new TextRun({ text: "Period: ", bold: true, size: 28, font: 'Calibri' }),
                new TextRun({ text: `${MonthNumber()[+chosenTimesheet.date.split('/')[1]]?.en_title} 20${chosenTimesheet.date.split('/')[2]}`, italics: true, size: 28, font: 'Calibri' })
            ]
        }),
    ]

    const mainTable = [
        new Table({
            width: { size: 100, type: WidthType.PERCENTAGE },
            rows: [
                new TableRow({
                    children: [
                        new TableCell({
                            children: [new Paragraph({
                                children: [
                                    new TextRun({
                                        text: "Expert Name",
                                        bold: true,
                                        font: "Calibri",
                                        size: 18,
                                    })
                                ],
                                alignment: AlignmentType.CENTER
                            })],
                            borders: { top: { style: BorderStyle.SINGLE, size: 3 }, bottom: { style: BorderStyle.SINGLE, size: 3 }, left: { style: BorderStyle.SINGLE, size: 3 }, right: { style: BorderStyle.SINGLE, size: 3 } },
                            verticalAlign: VerticalAlign.CENTER,
                            width: { size: 15, type: WidthType.PERCENTAGE },
                            shading: { fill: "FBC900" },
                            margins: { top: 30, bottom: 30, left: 50, right: 50 },
                            rowSpan: 2,
                        }),
                        new TableCell({
                            children: [new Paragraph({
                                children: [
                                    new TextRun({
                                        text: "Project number, working days",  // Перенос текста
                                        bold: true,
                                        font: "Calibri",
                                        size: 18,
                                    })
                                ],
                                alignment: AlignmentType.CENTER  // Горизонтальное выравнивание
                            })],
                            borders: {
                                top: { style: BorderStyle.SINGLE, size: 3 },
                                bottom: { style: BorderStyle.SINGLE, size: 3 },
                                left: { style: BorderStyle.SINGLE, size: 3 },
                                right: { style: BorderStyle.SINGLE, size: 3 }
                            },
                            verticalAlign: VerticalAlign.CENTER,  // Вертикальное выравнивание
                            shading: { fill: "FBC900" },
                            margins: { top: 30, bottom: 30, left: 50, right: 50 },
                            columnSpan: projects.length
                        }),
                        new TableCell({
                            children: [new Paragraph({
                                children: [
                                    new TextRun({
                                        text: "Total working days",
                                        bold: true,
                                        font: "Calibri",
                                        size: 18,
                                    })
                                ],
                                alignment: AlignmentType.CENTER
                            })],
                            borders: { top: { style: BorderStyle.SINGLE, size: 3 }, bottom: { style: BorderStyle.SINGLE, size: 3 }, left: { style: BorderStyle.SINGLE, size: 3 }, right: { style: BorderStyle.SINGLE, size: 3 } },
                            verticalAlign: VerticalAlign.CENTER,
                            shading: { fill: "FBC900" },
                            width: { size: 8, type: WidthType.PERCENTAGE },
                            margins: { top: 30, bottom: 30, left: 50, right: 50 },
                            rowSpan: 2
                        }),
                        new TableCell({
                            children: [new Paragraph({
                                children: [
                                    new TextRun({
                                        text: "Daily Rate, EUR",
                                        bold: true,
                                        font: "Calibri",
                                        size: 18,
                                    })
                                ],
                                alignment: AlignmentType.CENTER
                            })],
                            borders: { top: { style: BorderStyle.SINGLE, size: 3 }, bottom: { style: BorderStyle.SINGLE, size: 3 }, left: { style: BorderStyle.SINGLE, size: 3 }, right: { style: BorderStyle.SINGLE, size: 3 } },
                            verticalAlign: VerticalAlign.CENTER,
                            shading: { fill: "FBC900" },
                            width: { size: 8, type: WidthType.PERCENTAGE },
                            margins: { top: 30, bottom: 30, left: 50, right: 50 },
                            rowSpan: 2
                        }),
                        new TableCell({
                            children: [new Paragraph({
                                children: [
                                    new TextRun({
                                        text: "Amount, EUR",
                                        bold: true,
                                        font: "Calibri",
                                        size: 18,
                                    })
                                ],
                                alignment: AlignmentType.CENTER
                            })],
                            borders: { top: { style: BorderStyle.SINGLE, size: 3 }, bottom: { style: BorderStyle.SINGLE, size: 3 }, left: { style: BorderStyle.SINGLE, size: 3 }, right: { style: BorderStyle.SINGLE, size: 3 } },
                            verticalAlign: VerticalAlign.CENTER,
                            shading: { fill: "FBC900" },
                            width: { size: 8, type: WidthType.PERCENTAGE },
                            margins: { top: 30, bottom: 30, left: 50, right: 50 },
                            rowSpan: 2
                        }),
                    ]
                }),

                new TableRow({
                    children: allProjectsTable
                }),

                ...employeesList,

                new TableRow({
                    children: footerBlock
                }),
            ]
        }),
    ]

    const doc = new Document({
        sections: [
            {
                headers: tableLogo,
                properties: {
                    page: {
                        size: {
                            orientation: !chosenProject?.id ? PageOrientation.LANDSCAPE : PageOrientation.PORTRAIT,
                        },
                    },
                },
                children: [
                    ...tableHeader,

                    new Paragraph(""),
                    new Paragraph(""),

                    ...mainTable,
                ]
            }
        ]
    });

    const today = `${String(new Date().getFullYear()).slice(2, 4)}${(new Date().getMonth() + 1) < 10 ? "0" + (new Date().getMonth() + 1) : new Date().getMonth() + 1}${new Date().getDate() < 10 ? "0" + new Date().getDate() : new Date().getDate()}`

    Packer.toBlob(doc).then((blob: any) => {
        // saveAs(blob, "Overall_Word.docx");
        saveAs(blob, `${today}_Project_Controlling_${MonthNumber()[+(chosenTimesheet?.date[3] + chosenTimesheet?.date[4])]?.en_title}`);
    });
};
