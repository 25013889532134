export const PhoneCodes = () => {
    return [
        {value: "+43", label: "+43"},
        {value: "+380", label: "+380"},
        {value: "+93", label: "+93"},
        {value: "+355", label: "+355"},
        {value: "+213", label: "+213"},
        {value: "+244", label: "+244"},
        {value: "+374", label: "+374"},
        {value: "+376", label: "+376"},
        {value: "+260", label: "+260"},
        {value: "+263", label: "+263"},
    ]
}