import React, { useContext, useEffect, useState } from "react";

import {
  ICurrency,
  ISelect,
  ISummaryEmployee,
  ITimesheet,
  IUser,
} from "../../../models";
import { getApiLink } from "../../../functions/getApiLink";
import { SetFavoriteEmployee } from "../../../api/SetFavoriteEmployee";
import { useDispatch, useSelector } from "react-redux";
import { SummaryEmployeesButtons } from "./SummaryEmployeesButtons";
import { SummaryEmployeesChangeDecision } from "./SummaryEmployeesChangeDecision";
import { SummaryEmployeesStatusRejected } from "./SummaryEmployeesStatusRejected";
import { SummaryEmployeesStatusApproved } from "./SummaryEmployeesStatusApproved";
import axios from "axios";
import { getBearer } from "../../../functions/getBearer";
import {
  setChosenTimesheet,
  setSummaryEmployeeIdOpen,
} from "../../../storage/toolkit";
import { NavLink } from "react-router-dom";
import { mergeAndSum } from "../../../functions/mergeAndSumStatistic";
import { useTranslation } from "react-i18next";
import { Translate } from "../../../components/translate/Translate";
import { currency } from "../../../constants/Currency";
import { PopupContext } from "../../../App";
import { SummaryExportTable } from "../../summary/components/SummaryExportTable";
import { SummaryExportTableTimesheet } from "../../summary/components/SummaryExportTableTimesheet";
import { SetStatistic } from "../../../api/SetStatistic";
import { SetExpenses } from "../../../api/SetExpenses";
import { SetTasks } from "../../../api/SetTasks";
import { SummaryExcel } from "../../summary/components/SummaryExcel";
import { GetAccessToken } from "../../../api/GetAccessToken";
import HTMLReactParser from "html-react-parser";
import { useClickOutside } from "../../../hooks/ClickOutside";

interface ISummaryEmployeesItemProps {
  itemData: ITimesheet;
  isFavorite?: boolean;
  setStatisticForTable: any;
  allUserStatistics: any;
  isCanCheck: boolean;
}

interface IStatisticList {
  project: {
    id: string;
    name: string;
    description: string;
  };
  expense: {
    sum: number;
    percent: number;
  };
  task: {
    hours: number;
    percent: number;
  };
}

export const SummaryEmployeesItem: React.FC<ISummaryEmployeesItemProps> = ({
  isFavorite,
  setStatisticForTable,
  allUserStatistics,
  ...props
}) => {
  const userData: IUser = useSelector((state: any) => state.toolkit.user);
  const [itemData, setItemData] = useState<any>(props?.itemData ?? {});
  const [isCanCheck, setIsCanCheck] = useState(props?.isCanCheck ?? false);

  const summaryEmployees: ISummaryEmployee = useSelector(
    (state: any) => state.toolkit.summaryEmployees
  );
  const summaryEmployeeIdOpen: string = useSelector(
    (state: any) => state.toolkit.summaryEmployeeIdOpen
  );
  const chosenTimesheet: ITimesheet = useSelector(
    (state: any) => state.toolkit.chosenTimesheet
  );
  const currencyList: ICurrency = useSelector(
    (state: any) => state.toolkit.currency
  );

  const [isActive, setIsActive] = useState(
    summaryEmployeeIdOpen === itemData?.id
  );

  const user: IUser = useSelector((state: any) => state.toolkit.user);

  const [isFavoriteLocal, setIsFavoriteLocal] = useState(isFavorite);
  const [isChangeDecision, setIsChangeDecision] = useState(false);
  const [isLoad, setIsLoad] = useState(false);

  // const summaryBlock: any = useRef(null)
  const { rootEl } = useClickOutside(setIsActive);

  const [allApprovals, setAllApprovals] = useState<any>([]);

  useEffect(() => {
    setItemData(props?.itemData);

    const userStatus =
      props?.itemData.approves.filter(
        (item: any) => item.user.id === userData.id
      )[0]?.status === "approve";

    if (userData.status === "project_lead" && userStatus) {
      setItemData({
        ...props.itemData,
        status: "approve",
      });
    }

    if (userData?.status === "manager") {
      setIsCanCheck(props?.itemData?.user?.id === userData?.id);

      if (props.itemData.status === "progress") {
        setItemData({
          ...props.itemData,
          // status: "waiting",
        });
      }
    }

    const newArray: any = [
      ...props.itemData.approves,
      ...props.itemData.user.managers,
    ]
      .map((item: any) => ({
        ...item,
        user: item?.manager ?? item?.user,
        status: item?.status ?? "waiting",
      }))
      .filter((item) => {
        if (
          !props.itemData.approves.some(
            (i: any) => i.user.id === item?.manager?.id
          )
        ) {
          return item;
        }
      });

    setAllApprovals(newArray);

    setIsCanCheck(
      allApprovals.some((TAItem: any) => {
        return TAItem?.user?.id === user.id;
      })
    );
  }, [props.itemData, userData, summaryEmployees]);

  useEffect(() => {
    if (isLoad) return;

    setIsLoad(true);

    if (summaryEmployeeIdOpen === itemData?.id && rootEl.current) {
      // Получаем высоту элемента
      const blockHeight = rootEl.current.getBoundingClientRect().y;

      // Прокручиваем пользователя к элементу
      setTimeout(() => {
        // @ts-ignore
        rootEl.current?.closest(".simplebar-content-wrapper").scrollTo({
          top: blockHeight, // Вычитаем высоту элемента, чтобы цель оказалась выше визуальной области
          behavior: "smooth", // Для плавной прокрутки
        });
      }, 200);
    }
  }, [isLoad, summaryEmployeeIdOpen, itemData]);

  useEffect(() => {
    setIsCanCheck(
      allApprovals.some((TAItem: any) => {
        return TAItem?.user?.id === user.id;
      })
    );
  }, [allApprovals]);

  const dispatch = useDispatch();

  const setPopup: any = useContext(PopupContext);

  const itemDate = itemData.updated_at;
  const dateForStatus = itemDate.replaceAll(".", " / ");

  const { t } = useTranslation();

  const [isClickToExport, setIsClickToExport] = useState(false);
  const handleExportPdf = () => {
    // setIsClickToExport(true)

    getBearer("get");
    axios
      .get(getApiLink(`/api/timesheet/tasks/?timesheet_id=${itemData.id}`))
      .then((resTasks) => {
        getBearer("get");
        axios
          .get(
            getApiLink(`/api/timesheet/expenses/?timesheet_id=${itemData.id}`)
          )
          .then((resExpenses) => {
            SummaryExcel({
              chosenTimesheet: itemData,
              translate: t,
              data: statisticList,
              tasks: resTasks.data,
              expenses: resExpenses.data,
              currency: currencyList,
            });
          })
          .catch((er) => {
            er?.response?.status === 401 &&
              GetAccessToken(dispatch, handleExportPdf);
          });
      })
      .catch((er) => {
        er?.response?.status === 401 &&
          GetAccessToken(dispatch, handleExportPdf);
      });
  };

  const [count, setCount] = useState(0);

  useEffect(() => {
    if (!isClickToExport) return;

    setCount(0);

    SetStatistic(dispatch, itemData.id).finally(() => {
      setCount((prev) => prev + 1);
    });
    SetExpenses(dispatch, itemData.id).finally(() => {
      setCount((prev) => prev + 1);
    });
    SetTasks(dispatch, itemData.id).finally(() => {
      setCount((prev) => prev + 1);
    });
  }, [isClickToExport]);

  const timesheetStatus: any = {
    progress: {
      title: <Translate>summary_page.main.in_progress</Translate>,
      icon: "#attention",
    },
    waiting: {
      title: `${t("summary_page.main.sent_for_approval")} ${dateForStatus}`,
      icon: "#time",
    },
    reject: {
      title: `${dateForStatus}`,
      icon: "#round-error",
    },
    approve: {
      title: `${dateForStatus}`,
      icon: "#round-check",
    },
  };

  useEffect(() => {
    setIsChangeDecision(false);
  }, [summaryEmployees]);

  const handleOpenItem = (e: any) => {
    if (e.target.closest(".summary-item__user--favorite")) return;

    setIsActive((prev) => !prev);
    dispatch(setSummaryEmployeeIdOpen(isActive ? "" : itemData.id));
  };

  const handleFavorite = () => {
    setIsFavoriteLocal((prev) => !prev);

    SetFavoriteEmployee(dispatch, itemData?.user ?? "");
  };

  const [chosenProject, setChosenProject] = useState<ISelect>({
    label: "All projects",
    value: "default",
  });

  const footerBlockByStatus: any = {
    progress: "",
    waiting: (
      <SummaryEmployeesButtons
        isClickToExport={isClickToExport}
        handleExportPdf={handleExportPdf}
        timesheetId={itemData.id}
        isCanApprove={isCanCheck}
        setValue={setChosenProject}
      />
    ),
    reject: (
      <SummaryEmployeesChangeDecision
        isClickToExport={isClickToExport}
        handleExportPdf={handleExportPdf}
        itemData={itemData}
      />
    ),
    approve: (
      <SummaryEmployeesChangeDecision
        isClickToExport={isClickToExport}
        handleExportPdf={handleExportPdf}
        itemData={itemData}
        isCanApprove={isCanCheck}
      />
    ),
  };

  const footerStatusesByStatus: any = {
    progress: <div className="summary-item__footer--col" />,
    waiting: <div className="summary-item__footer--col" />,
    reject: <SummaryEmployeesStatusRejected itemData={itemData} />,
    approve: <SummaryEmployeesStatusApproved itemData={itemData} />,
  };

  const [statisticList, setStatisticList]: any = useState([]);

  useEffect(() => {
    if (!allUserStatistics || !Object.keys(allUserStatistics).length) return;

    setStatisticList(
      mergeAndSum(allUserStatistics?.expenses, allUserStatistics?.tasks)
        .statistic
    );

    setStatisticForTable((prev: any[]) => [
      ...prev,
      {
        user: itemData.user,
        data: mergeAndSum(allUserStatistics?.expenses, allUserStatistics?.tasks)
          .statistic,
        all_hours: allUserStatistics.all_hours,
        all_sum: allUserStatistics.all_sum,
      },
    ]);
  }, [allUserStatistics]);

  const handleOpenProfile = () => {
    setPopup({ popup: "profile-popup", data: itemData.user });
  };

  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    !isActive && setIsVisible(isActive);

    setTimeout(() => {
      setIsVisible(isActive);
    }, 500);
  }, [isActive]);

  useEffect(() => {
    if (userData.status !== "project_lead") return;

    if (
      itemData.status === "reject" &&
      itemData.approves.find((item: any) => item.user.id === userData.id)
        ?.status !== "reject"
    ) {
      setItemData({
        ...itemData,
        status: "waiting",
      });
    }
  }, [itemData]);

  const leaderStatuses: { [key: string]: string } = {
    waiting:
      '<svg width="20" height="20" viewBox="0 0 20 20"><use xlink:href="#time"></use></svg>',
    approve:
      '<svg width="20" height="20" viewBox="0 0 20 20"><use xlinkHref="#round-check"></use></svg>',
    reject:
      '<svg width="20" height="20" viewBox="0 0 20 20"><use xlink:href="#round-error"></use></svg>',
  };

  console.log(itemData?.status, isChangeDecision, isCanCheck, allApprovals);

  return (
    <>
      {isClickToExport && (
        <SummaryExportTable
          user={itemData.user}
          statistic={allUserStatistics}
          statisticList={statisticList}
        />
      )}
      {isClickToExport && <SummaryExportTableTimesheet user={itemData.user} />}

      <div ref={rootEl} className={`summary-item ${isActive && "is-active"}`}>
        <div onClick={handleOpenItem} className="summary-item__target">
          <div className="summary-item__target--user summary-item__user">
            <button
              onClick={handleOpenProfile}
              className="summary-item__user--avatar"
            >
              {itemData.user.avatar ? (
                <picture>
                  <img
                    src={getApiLink(`/${itemData.user.avatar}`)}
                    alt=""
                    width="60"
                    height="60"
                    loading="lazy"
                  />
                </picture>
              ) : (
                <div
                  className="aside__user--avatar"
                  style={{
                    background: itemData.user.avatar_color
                      ? itemData.user.avatar_color
                      : "var(--accent)",
                  }}
                >
                  {itemData.user.last_name[0]}
                  {itemData.user.first_name[0]}
                </div>
              )}
            </button>
            <div className="summary-item__user--info">
              <h2 className="summary-item__user--name">
                {itemData.user.last_name} {itemData.user.first_name}
              </h2>
              <b
                className="summary-item__user--position"
                style={{ textTransform: "capitalize" }}
              >
                {itemData.user.role}
              </b>
              {/* <span
                className="summary-item__user--position"
                style={{ textTransform: "capitalize" }}
              >
                {itemData.user?.departments.map((item, index) =>
                  index === 0 ? item.name : `, ${item.name}`
                )}
              </span> */}
            </div>
            <button
              onClick={handleFavorite}
              type="button"
              className={`summary-item__user--favorite ${
                isFavoriteLocal && "is-active"
              }`}
            >
              <svg width="20" height="20" viewBox="0 0 20 20">
                <use xlinkHref="#star"></use>
              </svg>
            </button>
          </div>
          <div
            className={`summary-item__target--status ${
              itemData.status === "waiting" && "is-waiting"
            } ${itemData.status === "reject" && "is-danger"} ${
              itemData.status === "approve" && "is-success"
            }`}
          >
            <span>{timesheetStatus[itemData.status]?.title}</span>
            <svg width="20" height="20" viewBox="0 0 20 20">
              <use xlinkHref={timesheetStatus[itemData.status]?.icon}></use>
            </svg>
          </div>
          <button type="button" className="summary-item__target--toggle">
            <svg
              width="10"
              height="7"
              viewBox="0 0 10 7"
              className="summary-item__target--arrow"
            >
              <use xlinkHref="#drop-down-arrow"></use>
            </svg>
          </button>
        </div>
        <div className="summary-item__block">
          <div style={{ overflow: isVisible ? "visible" : "hidden" }}>
            {user.status !== "project_lead" && (
              <div className="summary-item__elements-list summary-item__elements-list__leaders">
                <span></span>
                <strong>Approving Managers:</strong>
                <ul className="leaders">
                  {allApprovals.map((item: any, index: number) => (
                    <li
                      key={item?.user?.id ?? item?.manager?.id}
                      className={`summary-item__target--status is-${
                        item?.status ?? ""
                      }`}
                    >
                      <span>
                        {item?.user?.last_name} {item?.user?.first_name}
                      </span>
                      {HTMLReactParser(
                        leaderStatuses[item?.status ?? ""] ?? ""
                      )}
                    </li>
                  ))}
                </ul>
              </div>
            )}

            <div className="summary-item__elements-list">
              {statisticList?.length
                ? statisticList
                    ?.filter((item: any) =>
                      chosenProject?.value === "default"
                        ? item
                        : item.project.id === chosenProject?.value
                    )
                    ?.map((item: IStatisticList) => (
                      <div
                        key={item.project.id}
                        className="summary-item__element"
                      >
                        <h3 className="summary-item__element--name">
                          {item.project.name} – {item.project.description}
                        </h3>
                        <div className="summary-item__element--progress">
                          <span>
                            {item.task.hours}{" "}
                            <Translate>timesheet_page.table.h</Translate>
                          </span>
                          <span
                            data-value={`${
                              item.task.percent > 100 ? 100 : item.task.percent
                            }%`}
                          >
                            <div
                              className="line_done"
                              style={{ width: `${item.task.percent}%` }}
                            />
                          </span>
                          <span>
                            {(item.task.hours / 8).toFixed(2)}{" "}
                            <Translate>d</Translate>
                          </span>
                        </div>
                        <div className="summary-item__element--progress">
                          <span>
                            {item.expense.sum.toFixed(2)} {currency}
                          </span>
                          <span
                            data-value={`${
                              item.expense.percent > 100
                                ? 100
                                : item.expense.percent
                            }%`}
                          >
                            <div
                              className="line_done"
                              style={{ width: `${item.expense.percent}%` }}
                            />
                          </span>
                        </div>
                      </div>
                    ))
                : "No data yet"}
            </div>
            <div className="summary-item__total">
              <b className="summary-item__total--title">
                <Translate>employees_page.table.total</Translate>
              </b>
              <div className="summary-item__total--element summary-item__total-element summary-item__total-element__two">
                <div className="summary-item__total-element--icon">
                  <svg width="22" height="22" viewBox="0 0 22 22">
                    <use xlinkHref="#time-2"></use>
                  </svg>
                </div>
                <b className="summary-item__total-element--name">
                  <Translate>
                    summary_page.main.time_spent_for_projects
                  </Translate>
                </b>
                <NavLink
                  onClick={(_) => dispatch(setChosenTimesheet(itemData))}
                  to={`/timesheet/${itemData.id}`}
                  className="summary-item__total-element--link"
                >
                  <Translate>Timesheet</Translate>
                  <svg width="7" height="10" viewBox="0 0 7 10">
                    <use xlinkHref="#arrow-next"></use>
                  </svg>
                </NavLink>
                <div className="summary-item__total-element--value">
                  {allUserStatistics?.all_hours?.toFixed(1) ?? 0}&nbsp;
                  <Translate>summary_page.main.hours</Translate>
                </div>
                <div className="summary-item__total-element--value summary-item__total-element--value-days">
                  {((allUserStatistics?.all_hours ?? 0) / 8).toFixed(2)}{" "}
                  <Translate>days</Translate>
                </div>
              </div>
              <div className="summary-item__total--element summary-item__total-element">
                <div className="summary-item__total-element--icon">
                  <svg width="22" height="22" viewBox="0 0 22 22">
                    <use xlinkHref="#money-2"></use>
                  </svg>
                </div>
                <b className="summary-item__total-element--name">
                  <Translate>
                    summary_page.main.money_spent_for_projects
                  </Translate>
                </b>
                <NavLink
                  onClick={(_) => dispatch(setChosenTimesheet(itemData))}
                  to={`/expenses/${itemData.id}`}
                  className="summary-item__total-element--link"
                >
                  <Translate>Costs</Translate>
                  <svg width="7" height="10" viewBox="0 0 7 10">
                    <use xlinkHref="#arrow-next"></use>
                  </svg>
                </NavLink>
                <div className="summary-item__total-element--value">
                  {allUserStatistics?.all_sum?.toFixed(2) ?? 0} {currency}
                </div>
              </div>
            </div>
            <div
              className={`summary-item__footer ${
                ((allApprovals.filter((apr: any) => apr.user.id === user.id)[0]
                  ?.status !== "approve" &&
                  itemData.status === "waiting") ||
                  itemData.status === "progress" ||
                  isChangeDecision) &&
                (!itemData.approves.find(
                  (item: any) => item.user.id === userData.id
                )?.status
                  ? true
                  : itemData.approves.find(
                      (item: any) => item.user.id === userData.id
                    )?.status === "waiting") &&
                "add-cols"
              }`}
            >
              {footerStatusesByStatus[itemData?.status]}
              {isChangeDecision ? (
                <SummaryEmployeesButtons
                  isClickToExport={isClickToExport}
                  handleExportPdf={handleExportPdf}
                  timesheetId={itemData.id}
                  isCanApprove={isCanCheck}
                  setValue={setChosenProject}
                />
              ) : userData.status === "manager" &&
                itemData.user.id === userData.id ? (
                footerBlockByStatus[itemData?.status]
              ) : (
                isCanCheck &&
                itemData?.status !== "progress" &&
                footerBlockByStatus[
                  allApprovals?.filter((apr: any) => apr.user.id === user.id)[0]
                    ?.status
                ]
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
