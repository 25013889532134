import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { IDepartment, IProject, ISelect } from "../../../models";
import SimpleBar from "simplebar-react";
import { Translate } from "../../translate/Translate";
import { useTranslation } from "react-i18next";
import axios, { AxiosResponse } from "axios";
import { getApiLink } from "../../../functions/getApiLink";
import { log } from "util";
import { getBearer } from "../../../functions/getBearer";

interface IPopupEmployeeProjectsProps {
  isOpenProjects: string;
  setIsOpenProjects: any;
  data: any;
  popup: any;
  setChosenProjects: any;
  setChosenDepartments: any;
  setChosenTasks: any;
  chosenDepartments: any;
  chosenDepartment: any;
  chosenProjects: any;
  chosenTasks: any;
  setSelectedManagers: any;
  selectedManagers: string[];
  allManagers: any[];
}

export const PopupEmployeeProjects: React.FC<IPopupEmployeeProjectsProps> = ({
  isOpenProjects,
  setIsOpenProjects,
  popup,
  data,
  setChosenProjects,
  setChosenDepartments,
  setChosenTasks,
  chosenDepartments,
  chosenDepartment,
  chosenProjects,
  chosenTasks,
  setSelectedManagers,
  selectedManagers,
  allManagers,
}) => {
  const [projects, setProjects] = useState<IProject[]>([]);
  const departments: IDepartment[] = useSelector(
    (state: any) => state.toolkit.departments
  );
  const [departmentsList, setDepartmentsList] = useState<IDepartment[]>([]);
  const [departmentTasksList, setDepartmentTasksList] = useState<any>([]);

  const [searchValue, setSearchValue] = useState("");

  const checkedAllInputTypes: { [key: string]: boolean } = {
    departments: departmentsList?.length === chosenDepartments?.length,
    departmentsTask: departmentTasksList?.length === chosenTasks?.length,
    projects: projects?.length === chosenProjects?.length,
  };

  useEffect(() => {
    setProjects([]);
    let projects: IProject[] = [];

    chosenDepartments?.map((item: IDepartment) => {
      projects.push(
        ...departments?.filter((item2) => item2.id === item.id)[0]?.projects
      );
    });

    // const newProjectsList = projects.filter((proj: any) => {
    //   if (
    //     chosenDepartments.some((dep: any) => proj.department?.id === dep?.id)
    //   ) {
    //     return proj;
    //   }
    // });

    setProjects((prev) => [...prev, ...projects]);
  }, [chosenDepartments]);

  const [currentType, setCurrentType] = useState<string>("");

  useEffect(() => {
    if (departmentsList?.length) {
      setCurrentType("departments");
    } else if (departmentTasksList?.length) {
      setCurrentType("departmentsTask");
    } else if (projects?.length) {
      setCurrentType("projects");
    }
  }, [departmentsList, departmentTasksList, projects]);

  const handleSelectProject = (item: IProject) => {
    !chosenProjects.some((item2: any) => item2.id === item.id)
      ? setChosenProjects((prev: any) => [...prev, item])
      : setChosenProjects((prev: any) =>
          prev.filter((item2: any) => item2.id !== item.id)
        );
  };
  const handleChosenDepartment = (item: IDepartment) => {
    !chosenDepartments.some((item2: any) => item2.id === item.id)
      ? setChosenDepartments((prev: any) => [...prev, item])
      : setChosenDepartments((prev: any) =>
          prev.filter((item2: any) => item2.id !== item.id)
        );
  };
  const handleChosenDepartmentTask = (item: IDepartment) => {
    !chosenTasks.some((item2: any) => item2.id === item.id)
      ? setChosenTasks((prev: any) => [...prev, item])
      : setChosenTasks((prev: any) =>
          prev.filter((item2: any) => item2.id !== item.id)
        );
  };
  const handleChooseManager = (item: any) => {
    !selectedManagers.some((item2: any) => item2 === item.id)
      ? setSelectedManagers((prev: any) => [...prev, item.id])
      : setSelectedManagers((prev: any) =>
          prev.filter((item2: any) => item2 !== item.id)
        );
  };

  const handleCheckedAll = () => {
    if (departmentsList.length) {
      setChosenDepartments(
        departmentsList.length !== chosenDepartments.length
          ? departmentsList
          : []
      );
    } else if (departmentTasksList.length) {
      setChosenTasks(
        departmentTasksList.length !== chosenTasks.length
          ? departmentTasksList
          : []
      );
    } else if (projects.length) {
      setChosenProjects(
        projects.length !== chosenProjects.length ? projects : []
      );
    }
  };

  useEffect(() => {
    if (popup === "add-project-popup" || popup === "edit-project-popup") return;
    if (isOpenProjects) return;

    setTimeout(() => {
      setDepartmentsList([]);
    }, 400);
  }, [popup, isOpenProjects]);

  useEffect(() => {
    if (isOpenProjects === "") return;
    if (isOpenProjects !== "departments") return;

    axios
      .get(getApiLink("/api/admin/department/"))
      .then((res: AxiosResponse<IDepartment[]>) => {
        setDepartmentsList(res.data);
      })
      .catch((error) => {});
  }, [isOpenProjects]);

  useEffect(() => {
    if (!chosenDepartment?.length) return;

    setDepartmentTasksList(
      departments?.filter((item) => item.id === chosenDepartment)[0]
        ?.task_categories
    );
  }, [chosenDepartment]);

  useEffect(() => {
    if (!data?.all_projects) return;
    setChosenProjects(projects);
  }, [data, projects]);

  const typeOfSearchingText: { [key: string]: string } = {
    leaders: "Search a manager",
    projects: "Search a project",
    departments: "Search a department",
    tasks: "Search a task",
  };

  const typeOfAllText: { [key: string]: string } = {
    leaders: "All Leaders",
    projects: "All projects",
    departments: "All departments",
    tasks: "All tasks",
  };

  const typeOfNoneText: { [key: string]: () => void } = {
    leaders: () => (!allManagers?.length ? "Not found any leaders" : ""),
    projects: () => (!projects.length ? "Not found any projects" : ""),
    departments: () =>
      !departmentsList.length ? "Not found any departments" : "",
    tasks: () => (!departmentTasksList?.length ? "Not found any tasks" : ""),
  };

  const [isOpen, setIsOpen] = useState(true);

  const handleCloseModal = () => {
    setIsOpen(false);

    setTimeout(() => {
      setIsOpenProjects("");
      setIsOpen(true);
    }, 400);
  };

  return (
    <div
      className={
        "sub-popup-employee popup is-sub" +
        (isOpen && isOpenProjects !== "" ? " is-active" : "")
      }
      id="edit-sub-popup-employee"
      style={{ display: "flex" }}
    >
      <div className="sub-popup-employee__wrapper popup-wrapper">
        <div
          className="sub-popup-employee__bg popup-bg"
          onClick={handleCloseModal}
        ></div>
        <div
          className={`sub-popup-employee__body popup-body ${
            popup === "add-project-popup" || popup === "edit-project-popup"
              ? "popup-body-new-project"
              : ""
          }`}
        >
          {/*<PopupClose/>*/}
          <button
            type="button"
            onClick={handleCloseModal}
            className="remove-table-item__close-btn popup-close-btn popup-close"
            title="Close"
          >
            <svg width="15" height="15" viewBox="0 0 15 15">
              <use xlinkHref="#close"></use>
            </svg>
          </button>
          <div className="sub-popup-employee__body--wrapper">
            <button
              type="button"
              className="sub-popup-employee__close-btn popup-close-btn"
              onClick={handleCloseModal}
              title="Close"
            >
              <svg width="15" height="15" viewBox="0 0 15 15">
                <use xlinkHref="#close"></use>
              </svg>
            </button>
            <SimpleBar
              autoHide={false}
              className="sub-popup-employee__container popup-container"
            >
              <ul className="popup-checkbox-list">
                {(isOpenProjects === "departments" ||
                  isOpenProjects === "projects") &&
                  !!projects.length &&
                  !departmentsList.length && (
                    <li className={"bold"}>
                      <label className="popup-checkbox">
                        <input
                          type="checkbox"
                          onChange={handleCheckedAll}
                          checked={checkedAllInputTypes[currentType] ?? false}
                          className="popup-checkbox__input all-check"
                        />
                        <span className="popup-checkbox__element">
                          <svg width="11" height="8" viewBox="0 0 11 8">
                            <use xlinkHref="#check"></use>
                          </svg>
                        </span>
                        <span className="popup-checkbox__text">
                          {typeOfAllText[isOpenProjects]}
                        </span>
                      </label>
                    </li>
                  )}

                {!!isOpenProjects && typeOfNoneText[isOpenProjects]()}

                {popup !== "add-project-popup" &&
                  popup !== "edit-project-popup" &&
                  (isOpenProjects === "departments" ||
                    isOpenProjects === "projects") && (
                    <>
                      {!departmentsList.length
                        ? !!projects.length &&
                          projects
                            ?.filter(
                              (item) =>
                                item?.name
                                  ?.toLowerCase()
                                  .includes(searchValue.toLowerCase()) ||
                                item.description
                                  .toLowerCase()
                                  .includes(searchValue.toLowerCase())
                            )
                            ?.filter((item) => !item.archive)
                            ?.map((project) => (
                              <li key={project.id}>
                                <label className="popup-checkbox">
                                  <input
                                    onChange={(_) =>
                                      handleSelectProject(project)
                                    }
                                    checked={chosenProjects?.some(
                                      (item: any) => item.id === project.id
                                    )}
                                    type="checkbox"
                                    name={project.name}
                                    className="popup-checkbox__input"
                                  />
                                  <span className="popup-checkbox__element">
                                    <svg
                                      width="11"
                                      height="8"
                                      viewBox="0 0 11 8"
                                    >
                                      <use xlinkHref="#check"></use>
                                    </svg>
                                  </span>
                                  <span
                                    className="popup-checkbox__text"
                                    style={{
                                      whiteSpace: "nowrap",
                                      textOverflow: "ellipsis",
                                      overflow: "hidden",
                                    }}
                                  >
                                    {project.name} – {project.description}
                                  </span>
                                </label>
                              </li>
                            ))
                        : departmentsList
                            ?.filter((item) =>
                              item?.name
                                ?.toLowerCase()
                                .includes(searchValue.toLowerCase())
                            )
                            ?.map((department) => (
                              <li key={department.id}>
                                <label className="popup-checkbox">
                                  <input
                                    onChange={(_) =>
                                      handleChosenDepartment(department)
                                    }
                                    checked={chosenDepartments?.some(
                                      (item: any) => item.id === department.id
                                    )}
                                    type="checkbox"
                                    name={department.name}
                                    className="popup-checkbox__input"
                                  />
                                  <span className="popup-checkbox__element">
                                    <svg
                                      width="11"
                                      height="8"
                                      viewBox="0 0 11 8"
                                    >
                                      <use xlinkHref="#check"></use>
                                    </svg>
                                  </span>
                                  <span
                                    className="popup-checkbox__text"
                                    style={{
                                      whiteSpace: "nowrap",
                                      textOverflow: "ellipsis",
                                      overflow: "hidden",
                                    }}
                                  >
                                    {department.name}
                                  </span>
                                </label>
                              </li>
                            ))}
                    </>
                  )}

                {isOpenProjects === "leaders" &&
                  allManagers?.map((manager: any) => (
                    <li key={manager.id}>
                      <label className="popup-checkbox">
                        <input
                          onChange={(_) => handleChooseManager(manager)}
                          checked={selectedManagers?.some(
                            (item: any) => item === manager.id
                          )}
                          type="checkbox"
                          name={manager.name}
                          className="popup-checkbox__input"
                        />
                        <span className="popup-checkbox__element">
                          <svg width="11" height="8" viewBox="0 0 11 8">
                            <use xlinkHref="#check"></use>
                          </svg>
                        </span>
                        <span
                          className="popup-checkbox__text"
                          style={{
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                          }}
                        >
                          {manager.first_name} {manager.last_name}
                        </span>
                      </label>
                    </li>
                  ))}

                {(popup === "add-project-popup" ||
                  popup === "edit-project-popup") &&
                  departmentTasksList
                    ?.filter((item: any) =>
                      item?.name
                        ?.toLowerCase()
                        .includes(searchValue.toLowerCase())
                    )
                    ?.map((task: any) => (
                      <li key={task.id}>
                        <label className="popup-checkbox">
                          <input
                            onChange={(_) => handleChosenDepartmentTask(task)}
                            checked={chosenTasks?.some(
                              (item: any) => item.id === task.id
                            )}
                            type="checkbox"
                            name={task.name}
                            className="popup-checkbox__input"
                          />
                          <span className="popup-checkbox__element">
                            <svg width="11" height="8" viewBox="0 0 11 8">
                              <use xlinkHref="#check"></use>
                            </svg>
                          </span>
                          <span
                            className="popup-checkbox__text"
                            style={{
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis",
                              overflow: "hidden",
                            }}
                          >
                            {task.name}
                          </span>
                        </label>
                      </li>
                    ))}
              </ul>
            </SimpleBar>
            <div className="sub-popup-employee__search">
              <label>
                <input
                  type="search"
                  name="search"
                  required
                  onChange={(e) => setSearchValue(e.target.value)}
                  value={searchValue}
                  className="input"
                  placeholder={typeOfSearchingText[isOpenProjects]}
                />
              </label>
              <button className="btn is-grey" type="submit">
                <Translate>employees_page.table.search</Translate>
                <svg width="15" height="15" viewBox="0 0 15 15">
                  <use xlinkHref="#search"></use>
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
