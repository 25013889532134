import ExcelJS from "exceljs";
import { ISelect, IVacation } from "../../models";
import React from "react";

interface IVacationsProps {
    vacations: IVacation[]
    translate: any
    worksheet: ExcelJS.Worksheet
    logo: number
    listYear: any
    department: ISelect | undefined
}

export const Vacations = ({ worksheet, translate, listYear, vacations, logo, department }: IVacationsProps) => {

    worksheet.columns = [
        { header: '', key: 'col0', width: 8.00 },
        { header: '', key: 'col1', width: 4.00 },
        { header: '', key: 'col2', width: 13.50 },
        { header: '', key: 'col3', width: 7.0 },
        { header: '', key: 'col4', width: 7.0 },
        { header: '', key: 'col5', width: 7.0 },
        { header: '', key: 'col6', width: 7.0 },
        { header: '', key: 'col7_1', width: 7.0 },
        { header: '', key: 'col7_2', width: 7.0 },
        { header: '', key: 'col7_3', width: 7.0 },
        { header: '', key: 'col7_4', width: 7.0 },
        { header: '', key: 'col7_5', width: 7.0 },
        { header: '', key: 'col7_6', width: 7.0 },
        { header: '', key: 'col7_7', width: 7.0 },
        { header: '', key: 'col7_8', width: 7.0 },
        { header: '', key: 'col7_9', width: 7.0 },
        { header: '', key: 'col7_10', width: 7.0 },
        { header: '', key: 'col7_11', width: 7.0 },
        { header: '', key: 'col7_12', width: 7.0 },
        { header: '', key: 'col8', width: 7.0 },
    ];


    worksheet.addRow({})
    worksheet.getRow(1).height = 25;

    const styleForHeader: Partial<ExcelJS.Style> = {
        font: { bold: true, size: 22 },
        alignment: { vertical: 'middle', horizontal: 'left' }
    };

    worksheet.mergeCells('B2:T2');
    worksheet.getCell('B2').value = "Vacations";
    worksheet.getCell('B2').style = styleForHeader;

    worksheet.addRow({})

    worksheet.mergeCells('B4:T4');
    worksheet.getCell('B4').value = {
        richText: [
            { text: "Department: ", font: { bold: true, size: 14 } },
            { text: department?.label ?? "", font: { bold: false, size: 14 } },
        ]
    };
    worksheet.getCell('B4').alignment = {
        horizontal: 'left'
    };
    const date = listYear
    worksheet.mergeCells('B5:T5');
    worksheet.getCell('B5').value = {
        richText: [
            { text: date, font: { bold: true, size: 14 } },
        ]
    };
    worksheet.getCell('B5').alignment = {
        horizontal: 'left'
    };

    worksheet.addRow({})

    const styleForTableHeader: Partial<ExcelJS.Style> = {
        font: { bold: true, size: 11, color: { argb: '000000' } },
        alignment: { vertical: 'middle', horizontal: 'center' },
        fill: {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'FBC900' }
        },
        border: {
            top: { style: 'thin', color: { argb: '000000' } },
            left: { style: 'thin', color: { argb: '000000' } },
            bottom: { style: 'thin', color: { argb: '000000' } },
            right: { style: 'thin', color: { argb: '000000' } }
        }
    };

    worksheet.addRow({
        col1: 'No',
        col2: 'Name',
        col3: 'Н-ні',
        col4: "Дод-ві",
        col5: "Д/р",
        col6: "Усього",
        col7_1: "1",
        col7_2: "2",
        col7_3: "3",
        col7_4: "4",
        col7_5: "5",
        col7_6: "6",
        col7_7: "7",
        col7_8: "8",
        col7_9: "9",
        col7_10: "10",
        col7_11: "11",
        col7_12: "12",
        col8: "Н-ні",
    }).eachCell((cell, colNumber) => {
        cell.style = styleForTableHeader;
    });

    worksheet.getRow(6).height = 18;

    const styleForTableBody: Partial<ExcelJS.Style> = {
        font: { size: 10 },
        alignment: { vertical: 'middle', horizontal: 'center', wrapText: true },
        border: {
            top: { style: 'hair', color: { argb: '000000' } },
            left: { style: 'hair', color: { argb: '000000' } },
            bottom: { style: 'hair', color: { argb: '000000' } },
            right: { style: 'hair', color: { argb: '000000' } },
        }
    }

    const tasksReversed = vacations.slice().reverse();
    vacations
        ?.filter(item => item.user.status !== "manager")
        ?.map((item: IVacation, index: number) => {
            let amountDays = 0
            const isLastRow = index === tasksReversed.length - 1;

            const rowObject: any = {
                col1: index + 1,
                col2: `${item.user.first_name} ${item.user.last_name}`,
                col3: item.remain,
                col4: item.extra,
                col5: item.user.holidays,
                col6: +item.remain + +item.extra + +item.user.holidays,
            }

            item.months.map((item2, index2) => {
                amountDays += item2.days
                rowObject[`col7_${index2 + 1}`] = item2.days
            })

            rowObject.col8 = +item.remain + +item.extra + +item.user.holidays - amountDays

            worksheet.addRow(rowObject).eachCell((cell, colNumber) => {
                cell.style = styleForTableBody;

                if (colNumber === 2) {
                    cell.style = {
                        ...styleForTableBody,
                        border: {
                            ...styleForTableBody.border,
                            left: { style: 'thin', color: { argb: '000000' } },
                        },
                        fill: {
                            type: 'pattern',
                            pattern: 'solid',
                            fgColor: { argb: 'EFEFEF' }
                        },
                    }
                } else if (colNumber === 3) {
                    cell.style = {
                        ...styleForTableBody,
                        alignment: { vertical: 'middle', horizontal: 'left', wrapText: true },
                        fill: {
                            type: 'pattern',
                            pattern: 'solid',
                            fgColor: { argb: 'EFEFEF' }
                        },
                    }
                } else if (colNumber === 7) {
                    cell.style = {
                        ...styleForTableBody,
                        fill: {
                            type: 'pattern',
                            pattern: 'solid',
                            fgColor: { argb: 'FFF0C1' }
                        },
                    }
                } else if (colNumber === 20) {
                    cell.style = {
                        ...styleForTableBody,
                        fill: {
                            type: 'pattern',
                            pattern: 'solid',
                            fgColor: { argb: 'FFF0C1' }
                        },
                        border: {
                            ...styleForTableBody.border,
                            right: { style: 'thin', color: { argb: '000000' } },
                        },
                    }
                }


                if (isLastRow) {
                    cell.style = {
                        ...styleForTableBody,
                        border: {
                            ...styleForTableBody.border,
                            bottom: { style: "thin", color: { argb: "000000" } }
                        }
                    }

                    if (colNumber === 2) {
                        cell.style = {
                            ...styleForTableBody,
                            border: {
                                ...styleForTableBody.border,
                                bottom: { style: "thin", color: { argb: "000000" } },
                                left: { style: 'thin', color: { argb: '000000' } },
                            }
                        }

                    } else if (colNumber === 20) {
                        cell.style = {
                            ...styleForTableBody,
                            border: {
                                ...styleForTableBody.border,
                                bottom: { style: "thin", color: { argb: "000000" } },
                                right: { style: 'thin', color: { argb: '000000' } },
                            }
                        }
                    }
                }
            });

            return worksheet.getRow(index + 7).height = 20;
        })


    worksheet.addImage(logo, {
        // @ts-ignore
        tl: { col: 18.9, row: 0.2 },
        // @ts-ignore
        br: { col: 20, row: 2.3 },
    });

}
