export const tableToExcel = function () {
    const uri = 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,';
    const template = `
    <html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40">
    <head>
        <!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]-->
    </head>
    <body>
        <table>{table}</table>
    </body>
    </html>`;

    // @ts-ignore
    const base64 = function (s) {
        return window.btoa(unescape(encodeURIComponent(s)));
    };

    // @ts-ignore
    const format = function (s, c) {
        // @ts-ignore
        return s.replace(/{(\w+)}/g, function (m, p) {
            return c[p];
        });
    };

    // @ts-ignore
    return function (table, filename = 'excel-export') {
        if (!table.nodeType) table = document.querySelectorAll(table);

        // @ts-ignore
        table.forEach((tbl) => {
            const ctx = {
                worksheet: filename,
                table: tbl.innerHTML
            };

            const content = format(template, ctx);
            const blob = new Blob([content], {type: 'application/vnd.ms-excel'});

            // Создаем объект URL для Blob
            const url = window.URL.createObjectURL(blob);

            // Создаем ссылку для скачивания
            const link = document.createElement('a');
            link.setAttribute('href', url);

            // Добавляем заголовок Content-Disposition
            link.setAttribute('download', `${filename}.xls`);
            link.setAttribute('target', '_blank');

            link.style.visibility = 'hidden';

            // Добавляем ссылку в DOM и эмулируем клик для скачивания файла
            document.body.appendChild(link);
            link.click();

            // Удаляем ссылку из DOM и освобождаем ресурсы
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
        });
    };
}();