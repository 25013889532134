import { getBearer } from "../functions/getBearer";
import axios from "axios";
import { getApiLink } from "../functions/getApiLink";
import { setSummaryEmployees } from "../storage/toolkit";
import { GetAccessToken } from "./GetAccessToken";
import { IUser } from "../models";

export const SetSummaryEmployees = async (
  dispatch: any,
  user: IUser,
  month?: number,
  year?: number,
  projectId?: string
) => {
  const date = new Date();

  month = month ?? date.getMonth() + 1;
  year = year ?? date.getFullYear();
  projectId = projectId ?? "";

  if (!month || !year) return;

  getBearer("get");
  await axios
    .get(
      getApiLink(
        `/api/timesheet/employees/?month=${month}&year=${year}${
          projectId && "&project_id=" + projectId
        }`
      )
    )
    .then(({ data }) => {
      let array = data;
      
      if (user.status === "manager") {
        
        array.favourite = array.favourite.filter((item: any) => !(item.user.id !== user.id && item.user.status === "manager"))
        array.all = array.all.filter((item: any) => !(item.user.id !== user.id && item.user.status === "manager"))
        
      }
      
      dispatch(setSummaryEmployees(array));
    })
    .catch((er) => {
      er?.response?.status === 401 &&
        GetAccessToken(dispatch, SetSummaryEmployees);
    });
};
