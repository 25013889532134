import React, { createContext, useContext, useEffect, useState } from 'react'
import { PopupAddEmployee } from "./components/PopupAddEmployee";
import { PopupEditEmployee } from "./components/PopupEditEmployee";
import { PopupDeleteEmployee } from "./components/PopupDeleteEmployee";
import { PopupAddProject } from "./components/PopupAddProject";
import { PopupEditProject } from "./components/PopupEditProject";
import { PopupForgotPassword } from "./components/PopupForgotPassword";
import { PopupContext } from "../../App";
import { PopupEmployeeProjects } from "./components/PopupEmployeeProjects";
import { PopupRemoveProject } from "./components/PopupRemoveProject";
import { IDepartment, IProject, IUser } from "../../models";
import { PopupProfile } from "./components/PopupProfile";
import { PopupEditProfile } from "./components/PopupEditProfile";
import { PopupDeleteTask } from "./components/PopupDeleteTask";
import { PopupDeleteExpense } from "./components/PopupDeleteExpense";
import { PopupApproveTimesheet } from './components/PopupApproveTimesheet';
import { PopupApproveEmployeeTimesheet } from "./components/PopupApproveEmployeeTimesheet";
import { PopupResetPasswordThankYou } from "./components/PopupResetPasswordThankYou";
import { PopupResetPassword } from "./components/PopupResetPassword";
import { PopupReviewToTimesheet } from "./components/PopupReviewToTimesheet";
import { PopupListStyled } from "./PopupList.styled";
import { PopupResetPasswordInput } from "./components/PopupResetPasswordInput";
import { PopupResetPasswordSent } from "./components/PopupResetPasswordSent";
import { log } from "util";
import { useSelector } from "react-redux";
import { DeleteReceipt } from './components/PopupDeleteReceipt';
import { getBearer } from '../../functions/getBearer';
import axios from 'axios';
import { getApiLink } from '../../functions/getApiLink';

interface IPopupListProps {
    popup: any
}

export const IsPopupActiveContext: any = createContext(null)
export const IsPopupActiveSecondContext: any = createContext(null)

export const PopupList: React.FC<IPopupListProps> = ({ popup }) => {

    const departments: IDepartment[] = useSelector((state: any) => state.toolkit.departments)
    const user: IUser = useSelector((state: any) => state.toolkit.user)

    const [isPopupActive, setIsPopupActive] = useState(false)
    const [isPopupSecondActive, setIsPopupSecondActive] = useState(false)
    const [isOpenProjects, setIsOpenProjects] = useState("")
    const [chosenProjects, setChosenProjects] = useState<IProject[]>([])
    const [chosenDepartments, setChosenDepartments] = useState<IDepartment[]>([])
    const [chosenTasks, setChosenTasks] = useState<any[]>([]);
    const [chosenDepartment, setChosenDepartment] = useState();
    const [selectedManagers, setSelectedManagers] = useState<string[]>([])
    const [allManagers, setAllManagers] = useState([])

    useEffect(() => {
        if (popup.popup !== "edit-employee-popup") return

        setChosenDepartments(popup.data.all_departments ? departments : popup.data.departments)
    }, [popup])


    useEffect(() => {
        if (popup.popup !== "edit-project-popup") return setChosenTasks([]);

        setChosenTasks(popup.data.task_categories)
    }, [popup])


    useEffect(() => {

        if(!Object.keys(user).length) return;
        if(user?.status !== 'admin') return;

        getBearer('get')
        axios.get(getApiLink('/api/admin/employee/project_leads/')).then(({ data }) => {
            setAllManagers(data)
        }).catch((err) => {
            
        })

    }, [user])
    

    const popupList: { [key: string]: React.ReactNode } = {
        "forgot-password-popup": <PopupForgotPassword popup={popup} data={popup.data} />,

        "add-new-employee-popup": <PopupAddEmployee
            data={popup.data}
            chosenProjects={chosenProjects}
            chosenDepartments={chosenDepartments}
            setIsOpenProjects={setIsOpenProjects}
            selectedManagers={selectedManagers}
            allManagers={allManagers}
        />,
        "edit-employee-popup": <PopupEditEmployee
            popup={popup}
            data={popup.data}
            setChosenDepartments={setChosenDepartments}
            chosenDepartments={chosenDepartments}
            setChosenProjects={setChosenProjects}
            chosenProjects={chosenProjects}
            setIsOpenProjects={setIsOpenProjects}
            setSelectedManagers={setSelectedManagers}
            selectedManagers={selectedManagers}
            allManagers={allManagers}
        />,
        "edit-project-popup": <PopupEditProject
            data={popup.data}
            setIsOpenProjects={setIsOpenProjects}
            chosenTasks={chosenTasks}
            setChosenDepartment={setChosenDepartment}
        />,
        "add-project-popup": <PopupAddProject
            setIsOpenProjects={setIsOpenProjects}
            chosenTasks={chosenTasks}
            setChosenDepartment={setChosenDepartment}
        />,
        "remove-employee-popup": <PopupDeleteEmployee data={popup.data} />,
        "remove-task-popup": <PopupDeleteTask data={popup.data} />,
        "remove-expense-popup": <PopupDeleteExpense data={popup.data} />,
        "remove-project-popup": <PopupRemoveProject data={popup.data} />,

        "delete-receipt": <DeleteReceipt data={popup.data} />,

        "approve-timesheet-popup": <PopupApproveTimesheet data={popup.data} />,
        "approve-employee-timesheet-popup": <PopupApproveEmployeeTimesheet data={popup.data} />,
        "review-employee-timesheet-popup": <PopupReviewToTimesheet data={popup.data} />,

        "profile-popup": <PopupProfile data={popup.data} />,
        "edit-profile-popup": <PopupEditProfile popup={popup} />,

        "reset-password-input-popup": <PopupResetPasswordInput popup={popup} data={popup.data} />,
        "reset-password-popup": <PopupResetPassword popup={popup} data={popup.data} />,
        "reset-password-thanks-popup": <PopupResetPasswordThankYou popup={popup} data={popup.data} />,
        "reset-password-sent-popup": <PopupResetPasswordSent popup={popup} data={popup.data} />,
    }

    const setPopup: any = useContext(PopupContext)

    useEffect(() => {
        if (!popup.popup) return setSelectedManagers([]);

        setIsPopupActive(true)

        if (!popup.data) {
            setChosenProjects([])
            setChosenDepartments([])
            setSelectedManagers([])
        }

    }, [popup.popup])

    useEffect(() => {
        if (isPopupActive) return;

        setTimeout(() => {
            if (popup.popup === "profile-popup") return;

            setPopup("")
        }, 500)
    }, [isPopupActive])


    useEffect(() => {
        if (!popup.secondPopup) return;

        setIsPopupActive(true)
    }, [popup.secondPopup])

    const sidePopup = ["profile-popup", "edit-profile-popup"]

    const handleClosePopup = () => {
        setIsPopupActive(false)

        setTimeout(() => {
            setPopup("")
        }, 500)
    }

    return (
        <PopupListStyled>
            <IsPopupActiveSecondContext.Provider value={setIsPopupSecondActive}>
                <IsPopupActiveContext.Provider value={setIsPopupActive}>
                    <div
                        className={"popup" + (isPopupActive ? " is-active" : "") + (sidePopup.includes(popup.popup) ? " side-popup" : "")}
                        style={{ display: "flex" }}>
                        <div className="popup-wrapper">
                            <div className="add-project__bg popup-bg" onClick={handleClosePopup} />

                            {popupList[popup.popup]}

                        </div>
                    </div>

                    <PopupEmployeeProjects
                        data={popup.data}
                        popup={popup.popup}
                        setChosenProjects={setChosenProjects}
                        isOpenProjects={isOpenProjects}
                        setIsOpenProjects={setIsOpenProjects}
                        setChosenDepartments={setChosenDepartments}
                        setChosenTasks={setChosenTasks}
                        chosenProjects={chosenProjects}
                        chosenTasks={chosenTasks}
                        chosenDepartments={chosenDepartments}
                        chosenDepartment={chosenDepartment}
                        setSelectedManagers={setSelectedManagers}
                        selectedManagers={selectedManagers}
                        allManagers={allManagers}
                    />

                    <div
                        className={"popup" + ((popup.secondPopup && isPopupSecondActive) ? " is-active" : "") + (sidePopup.includes(popup.secondPopup) ? " side-popup" : "")}
                        style={{ display: "flex" }}>
                        <div className="popup-wrapper">
                            <div className="add-project__bg popup-bg" onClick={_ => setIsPopupSecondActive(false)} />

                            {popup.secondPopup && popupList[popup.secondPopup]}

                        </div>
                    </div>


                </IsPopupActiveContext.Provider>
            </IsPopupActiveSecondContext.Provider>
        </PopupListStyled>
    )
}
