import { BorderStyle, Document, Packer, Paragraph, Table, TableCell, TableRow, VerticalAlign, WidthType, TextRun, AlignmentType, Header, ImageRun } from "docx";
import { saveAs } from "file-saver";
import { MonthNumber } from "../../constants/MonthNumber";

export const OverviewProjectDocsTemplate = ({ image, chosenTimesheet, projects, users, chosenProject }: any) => {

    const chosenProjectId = chosenProject?.id || chosenProject?.value
    const chosenProjectName = chosenProject?.name || chosenProject?.label

    users = chosenProjectId ?
        users.filter((item: any) => item.statistics.tasks.some((item2: any) => item2.project.id === chosenProjectId))
        : users

    const employeesList = users.map((item: any, index: number) => {
        const isLastItem = users.length - 1 === index

        const amountOfHome = item.statistics.tasks.reduce((num: number, curr: any) => num += curr.home, 0) / 8
        const amountOfField = item.statistics.tasks.reduce((num: number, curr: any) => num += curr.field, 0) / 8

        return new TableRow({
            children: [
                new TableCell({
                    children: [new Paragraph({
                        children: [
                            new TextRun({
                                text: `${item?.user?.first_name} ${item.user.last_name}`,
                                font: "Calibri",
                            })
                        ],
                        alignment: AlignmentType.LEFT
                    })],
                    borders: { top: { style: BorderStyle.SINGLE, size: 1 }, bottom: { style: isLastItem ? BorderStyle.SINGLE : BorderStyle.DOTTED, size: 1 }, left: { style: BorderStyle.SINGLE, size: 1 }, right: { style: BorderStyle.DOTTED, size: 1 } },
                    verticalAlign: VerticalAlign.CENTER,
                    margins: { top: 30, bottom: 30, left: 50, right: 50 },
                }),
                new TableCell({
                    children: [new Paragraph({
                        children: [
                            new TextRun({
                                text: amountOfHome.toFixed(1).toString(),
                                font: "Calibri",
                            })
                        ],
                        alignment: AlignmentType.CENTER
                    })],
                    borders: { top: { style: BorderStyle.SINGLE, size: 1 }, bottom: { style: isLastItem ? BorderStyle.SINGLE : BorderStyle.DOTTED, size: 1 }, left: { style: BorderStyle.DOTTED, size: 1 }, right: { style: BorderStyle.DOTTED, size: 1 } },
                    verticalAlign: VerticalAlign.CENTER,
                    margins: { top: 30, bottom: 30, left: 50, right: 50 },
                }),
                new TableCell({
                    children: [new Paragraph({
                        children: [
                            new TextRun({
                                text: amountOfField.toFixed(1).toString(),
                                font: "Calibri",
                            })
                        ],
                        alignment: AlignmentType.CENTER
                    })],
                    borders: { top: { style: BorderStyle.SINGLE, size: 1 }, bottom: { style: isLastItem ? BorderStyle.SINGLE : BorderStyle.DOTTED, size: 1 }, left: { style: BorderStyle.DOTTED, size: 1 }, right: { style: BorderStyle.DOTTED, size: 1 } },
                    verticalAlign: VerticalAlign.CENTER,
                    margins: { top: 30, bottom: 30, left: 50, right: 50 },
                }),
                new TableCell({
                    children: [new Paragraph({
                        children: [
                            new TextRun({
                                text: ((amountOfHome + amountOfField) / 8).toFixed(1).toString(),
                                font: "Calibri",
                            })
                        ],
                        alignment: AlignmentType.CENTER
                    })],
                    borders: { top: { style: BorderStyle.SINGLE, size: 1 }, bottom: { style: isLastItem ? BorderStyle.SINGLE : BorderStyle.DOTTED, size: 1 }, left: { style: BorderStyle.DOTTED, size: 1 }, right: { style: BorderStyle.DOTTED, size: 1 } },
                    verticalAlign: VerticalAlign.CENTER,
                    margins: { top: 30, bottom: 30, left: 50, right: 50 },
                }),
            ]
        })
    })

    const allHomeAbount = users.reduce((sum: number, curr: any) => {
        return sum += curr.statistics.tasks.reduce((num: number, curr: any) => num += curr.home, 0)
    }, 0)
    const allFieldAbount = users.reduce((sum: number, curr: any) => {
        return sum += curr.statistics.tasks.reduce((num: number, curr: any) => num += curr.field, 0)
    }, 0)



    const tableLogo = {
        default: new Header({
            children: [
                new Paragraph({
                    children: [
                        new ImageRun({
                            data: image,
                            transformation: {
                                width: 70,
                                height: 55,
                            },
                        }),
                    ],
                    alignment: AlignmentType.RIGHT,
                }),
            ],
        }),
    }

    const isHaveChosenProject = chosenProjectId ? [
        new Paragraph({
            children: [
                new TextRun({ text: "Project number: ", bold: true, size: 28, font: 'Calibri' }),
                new TextRun({ text: chosenProjectName, italics: true, size: 28, font: 'Calibri' })
            ]
        }),
    ] : []

    const tableHeader = [
        // Заголовок
        new Paragraph({
            children: [new TextRun({ text: "Activity Timesheet", size: 40, bold: true, font: 'Calibri' })],
            alignment: AlignmentType.LEFT,
        }),
        new Paragraph(""),
        new Paragraph(""),
        // Project number
        ...isHaveChosenProject,
        // Period
        new Paragraph({
            children: [
                new TextRun({ text: "Period: ", bold: true, size: 28, font: 'Calibri' }),
                new TextRun({ text: `${MonthNumber()[+chosenTimesheet.date.split('/')[1]]?.en_title} 20${chosenTimesheet.date.split('/')[2]}`, italics: true, size: 28, font: 'Calibri' })
            ]
        }),
    ]

    const mainTable = [
        new Table({
            width: { size: 100, type: WidthType.PERCENTAGE },
            rows: [
                new TableRow({
                    children: [
                        new TableCell({
                            children: [new Paragraph({
                                children: [
                                    new TextRun({
                                        text: "Expert Name",
                                        bold: true,
                                        font: "Calibri",
                                    })
                                ],
                                alignment: AlignmentType.CENTER
                            })],
                            borders: { top: { style: BorderStyle.SINGLE, size: 3 }, bottom: { style: BorderStyle.SINGLE, size: 3 }, left: { style: BorderStyle.SINGLE, size: 3 }, right: { style: BorderStyle.SINGLE, size: 3 } },
                            verticalAlign: VerticalAlign.CENTER,
                            width: { size: 40, type: WidthType.PERCENTAGE },
                            shading: { fill: "FBC900" },
                            margins: { top: 30, bottom: 30, left: 50, right: 50 },
                        }),
                        new TableCell({
                            children: [new Paragraph({
                                children: [
                                    new TextRun({
                                        text: "Home",  // Перенос текста
                                        bold: true,
                                        font: "Calibri",
                                    })
                                ],
                                alignment: AlignmentType.CENTER  // Горизонтальное выравнивание
                            })],
                            borders: {
                                top: { style: BorderStyle.SINGLE, size: 3 },
                                bottom: { style: BorderStyle.SINGLE, size: 3 },
                                left: { style: BorderStyle.SINGLE, size: 3 },
                                right: { style: BorderStyle.SINGLE, size: 3 }
                            },
                            verticalAlign: VerticalAlign.CENTER,  // Вертикальное выравнивание
                            width: { size: 20, type: WidthType.PERCENTAGE },
                            shading: { fill: "FBC900" },
                            margins: { top: 30, bottom: 30, left: 50, right: 50 },
                        }),
                        new TableCell({
                            children: [new Paragraph({
                                children: [
                                    new TextRun({
                                        text: "Field",
                                        bold: true,
                                        font: "Calibri",
                                    })
                                ],
                                alignment: AlignmentType.CENTER
                            })],
                            borders: { top: { style: BorderStyle.SINGLE, size: 3 }, bottom: { style: BorderStyle.SINGLE, size: 3 }, left: { style: BorderStyle.SINGLE, size: 3 }, right: { style: BorderStyle.SINGLE, size: 3 } },
                            verticalAlign: VerticalAlign.CENTER,
                            width: { size: 20, type: WidthType.PERCENTAGE },
                            shading: { fill: "FBC900" },
                            margins: { top: 30, bottom: 30, left: 50, right: 50 },
                        }),
                        new TableCell({
                            children: [new Paragraph({
                                children: [
                                    new TextRun({
                                        text: "Total",
                                        bold: true,
                                        font: "Calibri",
                                    })
                                ],
                                alignment: AlignmentType.CENTER
                            })],
                            borders: { top: { style: BorderStyle.SINGLE, size: 3 }, bottom: { style: BorderStyle.SINGLE, size: 3 }, left: { style: BorderStyle.SINGLE, size: 3 }, right: { style: BorderStyle.SINGLE, size: 3 } },
                            verticalAlign: VerticalAlign.CENTER,
                            width: { size: 20, type: WidthType.PERCENTAGE },
                            shading: { fill: "FBC900" },
                            margins: { top: 30, bottom: 30, left: 50, right: 50 },
                        }),
                    ]
                }),

                ...employeesList,

                new TableRow({
                    children: [
                        new TableCell({
                            children: [new Paragraph({
                                children: [
                                    new TextRun({
                                        text: "Total working days",
                                        bold: true,
                                        font: "Calibri",
                                    })
                                ],
                                alignment: AlignmentType.CENTER
                            })],
                            borders: { top: { style: BorderStyle.SINGLE, size: 3 }, bottom: { style: BorderStyle.SINGLE, size: 3 }, left: { style: BorderStyle.SINGLE, size: 3 }, right: { style: BorderStyle.SINGLE, size: 3 } },
                            verticalAlign: VerticalAlign.CENTER,
                            width: { size: 40, type: WidthType.PERCENTAGE },
                            shading: { fill: "EFEFEF" },
                            margins: { top: 30, bottom: 30, left: 50, right: 50 },
                        }),
                        new TableCell({
                            children: [new Paragraph({
                                children: [
                                    new TextRun({
                                        text: (allHomeAbount / 8).toFixed(1).toString(),  // Перенос текста
                                        bold: true,
                                        font: "Calibri",
                                    })
                                ],
                                alignment: AlignmentType.CENTER  // Горизонтальное выравнивание
                            })],
                            borders: {
                                top: { style: BorderStyle.SINGLE, size: 3 },
                                bottom: { style: BorderStyle.SINGLE, size: 3 },
                                left: { style: BorderStyle.SINGLE, size: 3 },
                                right: { style: BorderStyle.SINGLE, size: 3 }
                            },
                            verticalAlign: VerticalAlign.CENTER,  // Вертикальное выравнивание
                            width: { size: 20, type: WidthType.PERCENTAGE },
                            shading: { fill: "EFEFEF" },
                            margins: { top: 30, bottom: 30, left: 50, right: 50 },
                        }),
                        new TableCell({
                            children: [new Paragraph({
                                children: [
                                    new TextRun({
                                        text: (allFieldAbount / 8).toFixed(1).toString(),
                                        bold: true,
                                        font: "Calibri",
                                    })
                                ],
                                alignment: AlignmentType.CENTER
                            })],
                            borders: { top: { style: BorderStyle.SINGLE, size: 3 }, bottom: { style: BorderStyle.SINGLE, size: 3 }, left: { style: BorderStyle.SINGLE, size: 3 }, right: { style: BorderStyle.SINGLE, size: 3 } },
                            verticalAlign: VerticalAlign.CENTER,
                            width: { size: 20, type: WidthType.PERCENTAGE },
                            shading: { fill: "EFEFEF" },
                            margins: { top: 30, bottom: 30, left: 50, right: 50 },
                        }),
                        new TableCell({
                            children: [new Paragraph({
                                children: [
                                    new TextRun({
                                        text: ((allFieldAbount + allHomeAbount) / 8).toFixed(1).toString(),
                                        bold: true,
                                        font: "Calibri",
                                    })
                                ],
                                alignment: AlignmentType.CENTER
                            })],
                            borders: { top: { style: BorderStyle.SINGLE, size: 3 }, bottom: { style: BorderStyle.SINGLE, size: 3 }, left: { style: BorderStyle.SINGLE, size: 3 }, right: { style: BorderStyle.SINGLE, size: 3 } },
                            verticalAlign: VerticalAlign.CENTER,
                            width: { size: 20, type: WidthType.PERCENTAGE },
                            shading: { fill: "EFEFEF" },
                            margins: { top: 30, bottom: 30, left: 50, right: 50 },
                        }),
                    ]
                }),
            ]
        }),
    ]

    const doc = new Document({
        sections: [
            {
                headers: tableLogo,
                children: [
                    ...tableHeader,

                    new Paragraph(""),
                    new Paragraph(""),

                    ...mainTable,
                ]
            }
        ]
    });

    Packer.toBlob(doc).then((blob: any) => {
        saveAs(blob, "Overall_Word.docx");
    });
};
