import { getApiLink } from "../functions/getApiLink";
import axios, { AxiosResponse } from "axios";
import { setCurrency } from "../storage/toolkit";
import { ICurrency } from "../models";

export const GetCurrency = (dispatch: any, month?: string, year?: string) => {
  axios
    .get(
      getApiLink(
        `/api/base/currency/rate/?date=${month ?? new Date().getMonth() + 1}.${
          year ?? new Date().getFullYear()
        }`
      )
    )
    .then(({ data }: AxiosResponse<ICurrency>) => {
      dispatch(setCurrency(data));
      
    })
    .catch((error) => {
        
    });
};
