import React, {useEffect, useState} from 'react'
import {useLanguage} from "../../../hooks/Language";
import {useClickOutside} from "../../../hooks/ClickOutside";
import {AsideCurrency} from './AsideCurrency';
import {ICurrency, ISelect, IUser} from "../../../models";
import {useDispatch, useSelector} from "react-redux";
import {setCurrentCurrency} from "../../../storage/toolkit";

interface IAsideLanguagesProps {

}

export const AsideLanguages: React.FC<IAsideLanguagesProps> = () => {

    const [isOpen, setIsOpen] = useState(false)
    const {rootEl} = useClickOutside(setIsOpen)

    const dispatch = useDispatch()

    const currency: ICurrency = useSelector((state: any) => state.toolkit.currency)
    const userData: IUser = useSelector((state: any) => state.toolkit.user)

    const isAdmin = userData.status?.includes("admin")

    const [currencyList, setCurrencyList] = useState<ISelect[]>([
        {value: "eur", label: "eur"}
    ])
    const [selectedCurrency, setSelectedCurrency] = useState<ISelect>()

    useEffect(() => {
        setCurrencyList([
            {value: "eur", label: "eur"}
        ])

        const {date, ...currencyList} = currency

        const currencyArray: ISelect[] = Object.keys(currencyList).map(item => {
            return {
                value: item,
                label: item,
            }
        })

        setCurrencyList(prev => [...prev, ...currencyArray])
        setSelectedCurrency(currencyArray[0])
        dispatch(setCurrentCurrency(currencyArray[0]?.value))

    }, [currency])

    useEffect(() => {
        dispatch(setCurrentCurrency(selectedCurrency?.value))
    }, [selectedCurrency])

    return (
        <div ref={rootEl} className="aside__change-on-min">
            <div>
                <div className="aside__add--list">
                    <AsideCurrency/>

                    {!isAdmin && <div className="aside__add--item">
                        <div className={`aside__language drop-down is-dark is-bottom ${isOpen && "is-active"}`}>
                            <button onClick={_ => setIsOpen(prev => !prev)}
                                    className="aside__language--target drop-down__target" type="button">
                                <i>
                                    <svg width="15" height="15" viewBox="0 0 15 15">
                                        <use xlinkHref="#money"></use>
                                    </svg>
                                </i>
                                <span style={{textTransform: "uppercase"}}>
                                    {currencyList.filter(item => item.value === selectedCurrency?.value)[0]?.label}
                                </span>
                                {currencyList.length > 1 &&
                                    <svg width="10" height="7" viewBox="0 0 10 7" className="drop-down__target--arrow">
                                        <use xlinkHref="#drop-down-arrow"/>
                                    </svg>}
                            </button>
                            <div className="aside__language--block drop-down__block">
                                <ul className="drop-down__list">

                                    {
                                        currencyList?.filter(currency => currency.value !== selectedCurrency?.value).map(currency =>
                                            <li key={currency.value} onClick={_ => {
                                                setIsOpen(false)
                                                setSelectedCurrency(currency)
                                            }}>
                                                <a style={{textTransform: "uppercase"}}>
                                                    {currency.label}
                                                </a>
                                            </li>
                                        )
                                    }

                                </ul>
                            </div>
                        </div>
                    </div>}
                </div>
            </div>
            {!isAdmin ? <div className={"aside-min"}>
                    <div className={`aside__language drop-down is-dark is-bottom is-min ${isOpen && "is-active"}`}>
                        <button onClick={_ => setIsOpen(prev => !prev)}
                                className="aside__language--target drop-down__target" type="button">
                        <span style={{textTransform: "uppercase"}}>
                            {currencyList.filter(item => item.value === selectedCurrency?.value)[0]?.label}
                        </span>
                            <svg width="10" height="7" viewBox="0 0 10 7"
                                 className="drop-down__target--arrow">
                                <use xlinkHref="#drop-down-arrow"></use>
                            </svg>
                        </button>
                        <div className="aside__language--block drop-down__block">
                            <ul className="drop-down__list">
                                {
                                    currencyList?.filter(lang => lang.value !== selectedCurrency?.value).map(lang =>
                                        <li key={lang.value} onClick={_ => {
                                            setSelectedCurrency(lang)
                                            setIsOpen(false)
                                        }}>
                                            <a style={{textTransform: "uppercase"}}>
                                                {lang.label}
                                            </a>
                                        </li>
                                    )
                                }
                            </ul>
                        </div>
                    </div>

                    <AsideCurrency/>
                </div>
                : <AsideCurrency isMini={true}/>
            }
        </div>
    )
}
