import ExcelJS from "exceljs";
import {MonthNumber} from "../../constants/MonthNumber";
import {ICategory, ICurrency, IExpense, IProject, ITimesheet} from "../../models";

interface ICostProps {
    currency: ICurrency | any
    chosenTimesheet: ITimesheet
    expenses: IExpense[]
    translate: any
    worksheet: ExcelJS.Worksheet
    logo: number
    chosenProject?: any
}

export const Costs = ({worksheet, translate, chosenTimesheet, logo, currency, chosenProject, ...props}: ICostProps) => {

    let expenses = props.expenses.map(item => ({
        ...item,
        sum: +item.sum.toFixed(2),
        sum_in_currency: +item.sum_in_currency.toFixed(2)
    }));
    

    const chosenProjectId = chosenProject?.id || chosenProject?.value
    const chosenProjectName = chosenProject?.name || chosenProject?.label

    const documentAuthor = `${chosenTimesheet?.user?.first_name} ${chosenTimesheet?.user?.last_name}`
    const approvalAuthor = `${chosenTimesheet?.manager?.first_name} ${chosenTimesheet?.manager?.last_name}`
    const approvalDate = chosenTimesheet?.status === "approve" ? chosenTimesheet?.updated_at : ''
    

    worksheet.columns = [
        {header: '', key: 'col0', width: 6},
        {header: '', key: 'col1', width: 12},
        {header: '', key: 'colPr', width: 12},
        {header: '', key: 'col2', width: 11},
        {header: '', key: 'col4', width: 30},
        {header: '', key: 'col5', width: 10},
        {header: '', key: 'col6', width: 10},
        {header: '', key: 'col7', width: 10},
        {header: '', key: 'col7_1', width: 10},
        {header: '', key: 'col8', width: 9},
        {header: '', key: 'col9', width: 15},
        {header: '', key: 'col10', width: 25},
    ];


    worksheet.addRow({})
    worksheet.getRow(1).height = 25;

    const styleForHeader: Partial<ExcelJS.Style> = {
        font: {bold: true, size: 22},
        alignment: {vertical: 'middle', horizontal: 'left'}
    };

    worksheet.mergeCells('B2:G2');
    worksheet.getCell('B2').value = "Costs Report";
    worksheet.getCell('B2').style = styleForHeader;

    worksheet.addRow({})

    worksheet.mergeCells('B4:E4');
    worksheet.getCell('B4').value = {
        richText: [
            {text: 'Expert Name: ', font: {bold: true, size: 12}},
            {text: String(documentAuthor), font: {bold: false, size: 12, italic: true}}
        ]
    };

    if (chosenProjectId) {
        worksheet.mergeCells('B5:E5');
        worksheet.getCell('B5').value = {
            richText: [
                {text: 'Project number: ', font: {bold: true, size: 12}},
                {text: chosenProjectName, font: {bold: false, size: 12, italic: true}}
            ]
        };
        const date = `${translate(MonthNumber()[+(chosenTimesheet?.date[3] + chosenTimesheet?.date[4])]?.translate_code)} 20${chosenTimesheet?.date && chosenTimesheet?.date[6]}${chosenTimesheet?.date && chosenTimesheet?.date[7]}`
        worksheet.mergeCells('B6:E6');
        worksheet.getCell('B6').value = {
            richText: [
                {text: 'Period: ', font: {bold: true, size: 12}},
                {text: String(date), font: {bold: false, size: 12, italic: true}}
            ]
        };
    } else {
        const date = `${translate(MonthNumber()[+(chosenTimesheet?.date[3] + chosenTimesheet?.date[4])]?.translate_code)} 20${chosenTimesheet?.date && chosenTimesheet?.date[6]}${chosenTimesheet?.date && chosenTimesheet?.date[7]}`
        worksheet.mergeCells('B5:E5');
        worksheet.getCell('B5').value = {
            richText: [
                {text: 'Period: ', font: {bold: true, size: 12}},
                {text: String(date), font: {bold: false, size: 12, italic: true}}
            ]
        };
    }


    if(!chosenProjectId) worksheet.addRow({})

    worksheet.addRow({})

    const styleForTableHeader: Partial<ExcelJS.Style> = {
        font: {bold: true, size: 9, color: {argb: '000000'}},
        alignment: {vertical: 'middle', horizontal: 'center', wrapText: true},
        fill: {
            type: 'pattern',
            pattern: 'solid',
            fgColor: {argb: 'FBC900'}
        },
        border: {
            top: {style: 'thin', color: {argb: '000000'}},
            left: {style: 'thin', color: {argb: '000000'}},
            bottom: {style: 'thin', color: {argb: '000000'}},
            right: {style: 'thin', color: {argb: '000000'}}
        }
    };

    worksheet.addRow({
        col1: 'Date',
        col2: 'Category',
        colPr: 'Project Num',
        col3: 'Date of payment',
        col4: 'Cost Description',
        col5: 'Cost',
        col6: 'Currency',
        col7: 'Cost, EUR',
        col7_1: 'Receipt',
        col9: 'Currency',
        col10: 'Currency exchange rate to EUR'
    }).eachCell((cell) => {
        cell.style = styleForTableHeader;
    });

    const numHeaderRow = 8

    worksheet.getRow(numHeaderRow).height = 25;

    const styleForTableBody: Partial<ExcelJS.Style> = {
        font: {size: 10},
        alignment: {vertical: 'middle', horizontal: 'center', wrapText: true},
        border: {
            top: {style: 'hair', color: {argb: '000000'}},
            left: {style: 'hair', color: {argb: '000000'}},
            bottom: {style: 'hair', color: {argb: '000000'}},
            right: {style: 'hair', color: {argb: '000000'}},
        }
    }

    expenses.slice().reverse().map((item: any, index: number) => {
        return worksheet.addRow({
            col1: item.date.replaceAll("/", "."),
            colPr: item.project.name,
            col2: item.category.name,
            col4: item.description,
            col5: item.sum_in_currency,
            col6: item.currency.toUpperCase(),
            col7: item.sum,
            col7_1: item.check_file ? "File" : ""
        }).eachCell((cell, colNumber) => {
            cell.style = styleForTableBody;

            if (colNumber === worksheet.getColumnKey('col7_1').number && item.check_file) {
                cell.value = { text: 'File', hyperlink: "https://api-ces.timesheet.space/"+item.check_file };
            }

            if (colNumber === 5) {
                cell.style = {
                    ...styleForTableBody,
                    alignment: {horizontal: "left", vertical: "middle", wrapText: true}
                }
            }

            if (colNumber === 2) {
                cell.style = {
                    font: {size: 10},
                    fill: {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: {argb: 'ffffff'}
                    },
                    alignment: {vertical: 'middle', horizontal: 'center'},
                    border: {
                        ...styleForTableBody.border,
                        left: {style: 'thin', color: {argb: '000000'}},
                    },
                }
                cell.numFmt = 'dd/mm/yy';
            } else if (colNumber === 3) {
                cell.style = {
                    ...styleForTableBody,
                    alignment: {vertical: 'middle', horizontal: 'center', wrapText: true},
                }
            } else if (colNumber === 6) {
                cell.style = {
                    font: {size: 10},
                    fill: {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: {argb: 'ffffff'}
                    },
                    alignment: {vertical: 'middle', horizontal: 'center'},
                    border: {
                        top: {style: 'hair', color: {argb: '000000'}},
                        left: {style: 'hair', color: {argb: '000000'}},
                        bottom: {style: 'hair', color: {argb: '000000'}},
                        right: {style: 'hair', color: {argb: '000000'}},
                    }
                }
                cell.numFmt = '0.00';
            } else if (colNumber === 9) {
                cell.style = {
                    ...styleForTableBody,
                    border: {
                        ...styleForTableBody.border,
                        right: {style: 'thin', color: {argb: '000000'}},
                    },
                }
            }
        });
    })

    const taskLength = expenses.length
    const rowNumberForTotal = taskLength + numHeaderRow + 2;

    worksheet.addRow({
        col1: "",
        colPr: "",
        col2: "",
        col3: "",
        col4: "",
        col5: "Total",
        col6: "",
        col7: {formula: `SUM(H${numHeaderRow + 1}:H${rowNumberForTotal - 2})`},
        col7_1: ""
    }).eachCell((cell, colNumber) => {
        cell.style = {
            ...styleForTableBody,
            font: {size: 10, bold: true},
            fill: {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {argb: 'EFEFEF'}
            },
            border: {
                top: {style: 'thin', color: {argb: '000000'}},
                left: {style: 'hair', color: {argb: '000000'}},
                right: {style: 'hair', color: {argb: '000000'}},
                bottom: {style: 'thin', color: {argb: '000000'}},
            },
            alignment: {horizontal: "center", vertical: "middle"}
        }

        if(colNumber === 2) {
            cell.style = {
                ...styleForTableBody,
                fill: {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: {argb: 'EFEFEF'}
                },
                border: {
                    ...styleForTableBody.border,
                    bottom: {style: 'thin', color: {argb: '000000'}},
                    top: {style: 'thin', color: {argb: '000000'}},
                    left: {style: 'thin', color: {argb: '000000'}},
                },
            }
        } else if(colNumber === 9) {
            cell.style = {
                ...styleForTableBody,
                fill: {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: {argb: 'EFEFEF'}
                },
                border: {
                    ...styleForTableBody.border,
                    bottom: {style: 'thin', color: {argb: '000000'}},
                    top: {style: 'thin', color: {argb: '000000'}},
                    right: {style: 'thin', color: {argb: '000000'}},
                },
            }
        }
    })
    worksheet.mergeCells(`F${rowNumberForTotal - 1}:G${rowNumberForTotal - 1}`);

    const allCurr = expenses.map(item => item.currency)
    
    Object.keys(currency).filter(item => item !== 'date').filter(item => allCurr.some(i => i === item)).map((item: string, index) => {
        worksheet.getCell(`K${numHeaderRow + index + 1}`).value = item.toUpperCase();
        worksheet.getCell(`K${numHeaderRow + index + 1}`).style = {
            ...styleForTableBody,
            border: {
                ...styleForTableBody.border,
                left: {style: 'thin', color: {argb: '000000'}},
            },
        };
        worksheet.getCell(`K${numHeaderRow + index + 1}`).numFmt = '0.00';
        worksheet.getCell(`L${numHeaderRow + index + 1}`).value = (1 / currency[item]).toFixed(4);
        worksheet.getCell(`L${numHeaderRow + index + 1}`).style = {
            ...styleForTableBody,
            border: {
                ...styleForTableBody.border,
                right: {style: 'thin', color: {argb: '000000'}},
            },
        };
        worksheet.getCell(`L${numHeaderRow + index + 1}`).numFmt = '0.0000';
    })

    worksheet.getCell(`K${numHeaderRow + allCurr.length}`).style = {
        ...styleForTableBody,
        border: {
            ...styleForTableBody.border,
            bottom: {style: 'thin', color: {argb: '000000'}},
            left: {style: 'thin', color: {argb: '000000'}},
        },
    };
    worksheet.getCell(`L${numHeaderRow + allCurr.length}`).style = {
        ...styleForTableBody,
        border: {
            ...styleForTableBody.border,
            bottom: {style: 'thin', color: {argb: '000000'}},
            right: {style: 'thin', color: {argb: '000000'}},
        },
    };

    worksheet.addRow({})

    worksheet.addRow({
        col1: "Summary"
    }).eachCell((cell, colNumber) => {
        cell.style = {font: {size: 18, bold: true}};
    })

    worksheet.mergeCells(`B${numHeaderRow + taskLength + 3}:C${numHeaderRow + taskLength + 3}`);

    worksheet.addRow({})

    const allProjects = expenses.reduce((arr: ICategory[], cur) => {

        if (!arr.some(item => item.id === cur.category.id)) {
            arr.push(cur.category)
        }

        return arr;
    }, [])


    worksheet.addRow({
        col1: 'Category',
        colPr: 'Total',
    }).eachCell((cell, colNumber) => {
        cell.style = styleForTableHeader;
    });
    allProjects.map((category, index) => {

        const sum = expenses.filter(item => item.category.id === category.id).reduce((arr: number, cur) => {
            return arr += cur.sum
        }, 0)

        return worksheet.addRow({
            col1: category.name,
            colPr: sum
        }).eachCell((cell, colNumber) => {
            cell.style = {
                ...styleForTableBody,
                alignment: {horizontal: "right", vertical: "middle", wrapText: true}
            };

            if (colNumber === 2) {
                cell.style = {
                    ...styleForTableBody,
                    border: {
                        ...styleForTableBody.border,
                        left: {style: 'thin', color: {argb: '000000'}},
                    },
                    alignment: {horizontal: "right", vertical: "middle", wrapText: true}
                }
            } else if (colNumber === 3) {
                cell.style = {
                    ...styleForTableBody,
                    border: {
                        ...styleForTableBody.border,
                        right: {style: 'thin', color: {argb: '000000'}},
                    },
                    alignment: {horizontal: "right", vertical: "middle", wrapText: true}
                }
            }

        });
    })

    worksheet.addRow({
        col1: 'Total:',
        colPr: {formula: `SUM(C${taskLength + numHeaderRow + 6}:C${taskLength + numHeaderRow + allProjects.length + 5})`},
    }).eachCell((cell, colNumber) => {
        cell.style = {
            ...styleForTableBody,
            font: {size: 10, bold: true},
            fill: {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {argb: 'EFEFEF'}
            },
            border: {
                top: {style: 'thin', color: {argb: '000000'}},
                left: {style: 'thin', color: {argb: '000000'}},
                right: {style: 'thin', color: {argb: '000000'}},
                bottom: {style: 'thin', color: {argb: '000000'}},
            },
            alignment: {horizontal: "right", vertical: "middle"}
        };

    });




    // chosenTimesheet.approves
    if (!!approvalDate) {
        worksheet.addRow({})
        
        // worksheet.addRow({
        //     col1: "Approve"
        // }).eachCell((cell, colNumber) => {
        //     cell.style = {font: {size: 18, bold: true}};
        // })
        
        worksheet.addRow({})

        chosenTimesheet.approves.map(approval => {
            return (
                worksheet.addRow({
                    col1: {
                        richText: [
                            {text: 'Approved by: ', font: {bold: true, size: 12}},
                            {text: `${approval.user.first_name} ${approval.user.last_name}`, font: {size: 12, italic: true}}
                        ]
                    }
                })
            )
        })

        worksheet.addRow({
            col1: {
                richText: [
                    {text: 'Date: ', font: {bold: true, size: 12}},
                    {text: String(approvalDate), font: {size: 12, italic: true}}
                ]
            }
        })
    }


    worksheet.addImage(logo, {
        // @ts-ignore
        tl: {col: 11.9, row: 0.2},
        // @ts-ignore
        br: {col: 12, row: 4.5},
    });

}
