import React, {useContext, useEffect, useRef, useState} from 'react'
import axios, {AxiosResponse} from "axios";
import {getBearer} from "../../../functions/getBearer";
import {getApiLink} from "../../../functions/getApiLink";
import {useDispatch, useSelector} from 'react-redux';
import {addProject} from '../../../storage/toolkit';
import {IsPopupActiveContext} from "../PopupList";
import {Translate} from "../../translate/Translate";
import {PopupCloseCancel} from "./PopupCloseCancel";
import {PopupClose} from "./PopupClose";
import {useTranslation} from "react-i18next";
import {IDepartment, ISelect} from "../../../models";
import {CustomSelect} from "../../customSelect/CustomSelect";
import {useDepartmentsAndTasks} from "../../../hooks/DepartmentsAndTasks";

interface IPopupAddProjectProps {
    setIsOpenProjects: any
    chosenTasks: any
    setChosenDepartment: any
}

export const PopupAddProject: React.FC<IPopupAddProjectProps> = ({setIsOpenProjects, chosenTasks, setChosenDepartment}) => {


    const dispatch = useDispatch()

    const {departments, tasks} = useDepartmentsAndTasks()

    const [nameValue, setNameValue] = useState<string>('')
    const [descriptionValue, setDescriptionValue] = useState<string>('')

    const setIsPopupActive: any = useContext(IsPopupActiveContext)

    const handleAddProject = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()

        getBearer("post")
        const bodyRequest = {
            "name": nameValue,
            "description": descriptionValue,
            "department_id": departments.value?.value,
            "task_categories_ids": chosenTasks.map((item: any) => item.id)
        };

        axios.post(getApiLink("/api/admin/project/add/"), bodyRequest).then(({data}) => {
            dispatch(addProject(data))
            setIsPopupActive(false)
        }).catch(er => {})
    }

    useEffect(() => {
        setChosenDepartment(departments.value?.value)
    }, [departments.value])


    const {t} = useTranslation();


    return (
        <div className="add-project__body popup-body">
            <h2 className="add-project__title popup-title title">
                <Translate>projects_admin.add_new_project</Translate>
            </h2>
            <PopupClose/>
            <div className="add-project__container popup-container" data-simplebar
                 data-simplebar-auto-hide="false">
                <form onSubmit={handleAddProject} className="add-project__form popup-form">
                    <div className="popup-form__row">
                        <label className="popup-form__label is-full">
                            <span>
                                <Translate>projects_admin.project_name</Translate>
                            </span>
                            <input type="text" name="project-name" required
                                   className="input" value={nameValue} onChange={e => setNameValue(e.target.value)}
                                   placeholder={`${t("projects_admin.project_name")}`}
                            />
                        </label>
                    </div>
                    <div className="popup-form__row">
                        <label className="popup-form__label is-full">
                            <span>
                                <Translate>projects_admin.project_description</Translate>
                            </span>
                            <input type="text" name="project-description" required
                                   className="input" onChange={e => setDescriptionValue(e.target.value)}
                                   value={descriptionValue}
                                   placeholder={`${t("projects_admin.project_description")}`}
                            />
                        </label>
                    </div>
                    <div className="popup-form__row">
                        <label className="popup-form__label is-full">
                            <span>
                                <Translate>Department name</Translate>
                            </span>
                            {departments.select}
                        </label>
                    </div>
                    <div className="popup-form__row">
                        <label className="popup-form__label is-full">
                            <span>
                                <Translate>Tasks</Translate>
                            </span>
                            <a onClick={_ => setIsOpenProjects((prev: string) => prev !== "tasks" ? "tasks" : "")}
                               className="popup-form__open-sub-popup open-popup">
                                <span id="checked-projects" data-none-text="None" data-text-1="project"
                                      data-text-2="projects" data-all-text="All projects">
                                    {chosenTasks?.length === tasks.value?.value.length ?
                                        <Translate>employees_admin.others.all_projects</Translate> : `${chosenTasks?.length} `}

                                    {
                                        chosenTasks?.length !== tasks.value?.value.length && <Translate>Tasks</Translate>
                                    }
                                </span>
                                <svg width="10" height="7" viewBox="0 0 10 7">
                                    <use xlinkHref="#drop-down-arrow"></use>
                                </svg>
                            </a>
                        </label>
                    </div>
                    <div className="popup-form__row is-min-gap">
                        <PopupCloseCancel/>
                        <button className="popup-form__submit btn" type="submit">
                            <Translate>projects_admin.save</Translate>
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
}
