import React, {useEffect, useState} from 'react'
import axios, {AxiosResponse} from "axios";
import {getApiLink} from "../../functions/getApiLink";
import {IProject} from "../../models";
import { CustomSelect } from '../customSelect/CustomSelect';
import { useSelector } from 'react-redux';

interface ISummaryAllProjectsProps {
    setValue?: any
}

export const SummaryAllProjects: React.FC<ISummaryAllProjectsProps> = ({setValue}) => {

    const [allSelectProjects, setAllSelectProjects] = useState([{
        value: "default",
        label: "All projects"
    }])
    const [currentProject, setCurrentProject] = useState(allSelectProjects[0])
    const [isLoading, setIsLoading] = useState(true)

    const allUserProjects = useSelector((state: any) => state.toolkit.allUserProjects)

    useEffect(() => {    
        if(!isLoading) return setIsLoading(false);

        const refactorProjectsList = !!Object.keys(allUserProjects).length ? allUserProjects?.projects_list?.map((item: IProject) => ({
            value: item.id,
            label: `${item.name} - ${item.description}` 
        })) : []
        

        setAllSelectProjects(prev => [...prev, ...refactorProjectsList])

    }, [isLoading, allUserProjects])

    useEffect(() => {
        if(!setValue) return;
        setValue(currentProject)
    }, [currentProject])

    return (
        <CustomSelect className={"allProjects"} list={allSelectProjects}
                      setSelectedItem={setCurrentProject} selectValue={currentProject}/>
    )
}
