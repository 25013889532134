import jsPDF from "jspdf";
import "jspdf-autotable";
import React, { useEffect, useState } from "react";
import { useClickOutside } from "../../hooks/ClickOutside";
import { Translate } from "../translate/Translate";
import html2pdf from "html2pdf.js";
import ExcelJS from "exceljs";
import FileSaver from "file-saver";
import logo from "./../../assets/html/img/logo-2.png";
import { MonthNumber } from "../../constants/MonthNumber";
import { useTranslation } from "react-i18next";
import { tableToExcel } from "../excel/Default";

interface ITableExportProps {
  title?: string;
  onClick?: any;
  excelFile?: any;
  docxFile?: any;
  isCanNotExportPdf?: boolean;
  isCanNotExportWord?: boolean;
  onClickExcel?: () => void;
}

export const TableExportCustom: React.FC<ITableExportProps> = ({
  title,
  onClick,
  excelFile,
  docxFile,
  isCanNotExportPdf,
  isCanNotExportWord,
  onClickExcel,
}) => {
  const exportExcelFile = () => {
    if (!!excelFile) return excelFile();

    onClickExcel && onClickExcel();

    tableToExcel(".table-to-download-excel", "timesheet");
  };

  // const exportDocsFile = async () => {
  //     if (!!docxFile) return docxFile();
  // }

  const convertToPDF = () => {
    const element = document.querySelectorAll(".table-to-download-pdf");

    element.forEach((tbl) => {
      const opt = {
        margin: 0.5,
        filename: "timesheet.pdf",
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 6 },
        jsPDF: { unit: "in", format: "letter" }, // Установка альбомной ориентации
      };

      html2pdf().from(tbl).set(opt).save();
    });
  };

  const [isExportSelectOpen, setIsExportSelectOpen] = useState(false);
  const { rootEl } = useClickOutside(setIsExportSelectOpen);

  return (
    <div
      ref={rootEl}
      className={
        isExportSelectOpen
          ? "section-table__export drop-down is-right-default is-active"
          : "section-table__export drop-down is-right-default"
      }
    >
      <button
        onClick={(_) => {
          setIsExportSelectOpen((prev) => !prev);
          onClick && onClick();
        }}
        className="section-table__export--target drop-down__target"
        type="button"
      >
        {title === "export all" ? (
          <Translate>summary_page.main.export_all</Translate>
        ) : (
          <Translate>employees_admin.table.export</Translate>
        )}
        <svg width="16" height="17" viewBox="0 0 16 17">
          <use xlinkHref="#download"></use>
        </svg>
      </button>
      <div className="section-table__export--block drop-down__block">
        <ul className="drop-down__list">
          <li>
            {/*<a onClick={e => tableToExcel('.table-to-download-excel', "timesheet")}>*/}
            <a onClick={exportExcelFile}>
              <Translate>export_as</Translate> .xlsx
            </a>
          </li>
          {!isCanNotExportWord && (
            <li>
              {/*<a onClick={e => tableToExcel('.table-to-download-excel', "timesheet")}>*/}
              <a onClick={docxFile}>
                <Translate>export_as</Translate> .docx
              </a>
            </li>
          )}
          {/* {!isCanNotExportPdf && <li>
                        <a onClick={e => convertToPDF()}>
                            <Translate>export_as</Translate> .pdf
                        </a>
                    </li>} */}
        </ul>
      </div>
    </div>
  );
};
