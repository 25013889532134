import styled from "styled-components";

export const AsideStyled = styled.aside`
  z-index: 3;
  
  .aside__body--wrapper {
    width: 100%;
  }
  
  .aside__user--avatar img {
    -webkit-border-radius: 50%;-moz-border-radius: 50%;border-radius: 50%;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .aside__log-out {
    cursor: pointer;
  }

  .drop-down__list a {
    text-align: center;
  }
  
  .aside__language--target > svg {
    margin-top: -3px;
  }

  &.is-min .aside__user--avatar {
    margin-left: 0;
  }
  
  .aside__language--target > span {
    min-width: 0;
  }
  .aside__language {
    width: fit-content;
    border: none;
    margin: 0;
  }
  .aside__language--target {
    height: auto;
    padding: 0;
  }
  
  .aside__language.is-min .aside__language--target {
    border-top: 1px solid #5F6472;
    padding-top: 20px;
    margin-top: 20px;
    height: auto;
  }

  .aside__language--target {
    white-space: nowrap;
    height: auto !important;
  }
  
  .aside__add--item {
    width: 100%;
  }

  
  &.is-min .aside__language,
  &.is-min .aside__language--target.drop-down__target {
    width: 100% !important;
  }
  &.is-min .aside__language--target.drop-down__target span {
    margin: 0 auto;
    width: 100%;
  }



  &.is-min .aside__language--target.drop-down__target i {
    display: none;
  }
  &.is-min .aside__add--list {
    height: 0;
    width: 46px;
  }
  
  .aside__add--item.is-mini {
    height: 50px;
    .drop-down__target {
      padding-bottom: 0;
      padding-top: 20px;
      margin-top: 20px;
      border-top: 1px solid #5F6472;
    }
  }
  
  .aside-min {
    .aside__language--target {
      padding-top: 5px;
    }
  }
`
