import ExcelJS from "exceljs";
import {MonthNumber} from "../../../constants/MonthNumber";
import {IProject, ISelect, ITimesheet} from "../../../models";
import {generateAlphaNumericSequence} from "../../../functions/getAlphabetNumbers";

interface IProps {
    worksheet: ExcelJS.Worksheet
    chosenTimesheet: ITimesheet
    projects: IProject[]
    users: any
    translate: any
    logo: number
    chosenDepartment: ISelect
    chosenProject: IProject
}

export const SummaryEmployeesExcelTimesheet = ({
                                                   worksheet,
                                                   projects,
                                                   chosenTimesheet,
                                                   translate,
                                                   users,
                                                   logo,
                                                   chosenDepartment,
                                                   chosenProject,
                                               }: IProps) => {

    if(chosenProject?.id)
        users = users.filter((user: any) => user.statistics.tasks.some((task: any) => task.project.id === chosenProject.id))

    // Создание основных колонок
    worksheet.columns = [
        {header: '', key: 'colEmpty', width: 5.0},
        {header: '', key: 'colUsers', width: 36.33},
    ];

    const projectColumns = projects.map((project, index) => ({
        header: '', key: `col${index + 1}`, width: 15
    }));

    if (chosenProject?.id) {
        const totalColumn = [
            {header: '', key: 'ProjHome', width: 15.0},
            {header: '', key: 'ProjField', width: 15.0},
            {header: '', key: 'ProjTotal', width: 15.0},
        ];

        worksheet.columns = worksheet.columns.concat(totalColumn);
    } else {
        const totalColumn = [
            {header: '', key: 'TotalWorkingDays', width: 12.33},
            {header: '', key: 'DailyRateEUR', width: 10.1},
            {header: '', key: 'AmountEUR', width: 8.1},
        ];

        worksheet.columns = worksheet.columns.concat(projectColumns, totalColumn);
    }


    worksheet.addRow({})
    worksheet.getRow(1).height = 25;

    const styleForHeader: Partial<ExcelJS.Style> = {
        font: {bold: true, size: 20},
        alignment: {vertical: 'middle', horizontal: 'left'}
    };

    worksheet.mergeCells('B2:N2');
    worksheet.getCell('B2').value = "Activity Timesheet";
    worksheet.getCell('B2').style = styleForHeader;

    worksheet.addRow({})

    // const date = `${MonthNumber()[+(chosenTimesheet?.date[3] + chosenTimesheet?.date[4])]?.en_title}, 20${chosenTimesheet?.date && chosenTimesheet?.date[6]}${chosenTimesheet?.date && chosenTimesheet?.date[7]}`
    worksheet.mergeCells('B4:N4');
    worksheet.getCell('B4').value = {
        richText: [
            {text: "Name of the Department/legal entity: ", font: {bold: true, size: 12}},
            {text: chosenDepartment.label, font: {bold: false, size: 12, italic: true}},
        ]
    };

    if (chosenProject?.id) {
        worksheet.mergeCells('B5:N5');
        worksheet.getCell('B5').value = {
            richText: [
                {text: "Project number: ", font: {bold: true, size: 12}},
                {text: chosenProject.name, font: {bold: false, size: 12, italic: true}},
            ]
        };
    } else {
        worksheet.addRow({})
    }

    const date = `${translate(MonthNumber()[+(chosenTimesheet?.date[3] + chosenTimesheet?.date[4])]?.translate_code)} 20${chosenTimesheet?.date && chosenTimesheet?.date[6]}${chosenTimesheet?.date && chosenTimesheet?.date[7]}`
    if(chosenProject?.id) {
        worksheet.mergeCells('B6:E6');
        worksheet.getCell('B6').value = {
            richText: [
                {text: 'Period: ', font: {bold: true, size: 12}},
                {text: String(date), font: {bold: false, size: 12, italic: true}}
            ]
        };
    } else {
        worksheet.mergeCells('B5:E5');
        worksheet.getCell('B5').value = {
            richText: [
                {text: 'Period: ', font: {bold: true, size: 12}},
                {text: String(date), font: {bold: false, size: 12, italic: true}}
            ]
        };
    }

    worksheet.addRow({})
    worksheet.addRow({})

    const styleForTableHeader: Partial<ExcelJS.Style> = {
        font: {bold: true, size: 11, color: {argb: '000000'}},
        alignment: {vertical: 'middle', horizontal: 'center', wrapText: true},
        fill: {
            type: 'pattern',
            pattern: 'solid',
            fgColor: {argb: 'F8BF0A'}
        },
        border: {
            top: {style: 'thin', color: {argb: '000000'}},
            left: {style: 'thin', color: {argb: '000000'}},
            bottom: {style: 'thin', color: {argb: '000000'}},
            right: {style: 'thin', color: {argb: '000000'}}
        }
    };


    // Создание строки шапки 1
    const rowHeaderOne: any = {
        colUsers: 'Expert Name',
        TotalWorkingDays: "Total working days",
        DailyRateEUR: "Daily Rate, EUR",
        AmountEUR: "Amount, EUR",
        ProjHome: "Home",
        ProjField: "Field",
        ProjTotal: "Total"
    }

    const valuesCount = 100;
    const result = generateAlphaNumericSequence(valuesCount);
    const allProjectsLength = projects.length + 2

    projects.map((project, index) => {
        rowHeaderOne[`col${index + 1}`] = "Project number, working days"
    })


    worksheet.addRow(rowHeaderOne).eachCell((cell, colNumber) => {
        cell.style = styleForTableHeader;
    });

    if (!chosenProject?.id) {
        worksheet.mergeCells(`${result[2]}${worksheet.rowCount}`, `${result[allProjectsLength - 1]}${worksheet.rowCount}`)
    }


    // Создание строки шапки 1


    if (!chosenProject?.id) {
        // Создание строки шапки 2
        const rowHeader: any = {
            colUsers: 'Users',
        }

        projects.map((project, index) => {
            rowHeader[`col${index + 1}`] = project.name
        })

        worksheet.addRow(rowHeader).eachCell((cell, colNumber) => {
            cell.style = styleForTableHeader;
        });
        // Создание строки шапки 2
    }

    if (!chosenProject?.id) {
        worksheet.mergeCells(`B${worksheet.rowCount - 1}:B${worksheet.rowCount}`);
        worksheet.mergeCells(`${result[allProjectsLength]}${worksheet.rowCount - 1}:${result[allProjectsLength]}${worksheet.rowCount}`);
        worksheet.mergeCells(`${result[allProjectsLength + 1]}${worksheet.rowCount - 1}:${result[allProjectsLength + 1]}${worksheet.rowCount}`);
        worksheet.mergeCells(`${result[allProjectsLength + 2]}${worksheet.rowCount - 1}:${result[allProjectsLength + 2]}${worksheet.rowCount}`);
    }

    const styleForTableBody: Partial<ExcelJS.Style> = {
        font: {size: 11},
        alignment: {vertical: 'middle', horizontal: 'center'},
        border: {
            top: {style: 'hair', color: {argb: '000000'}},
            left: {style: 'hair', color: {argb: '000000'}},
            bottom: {style: 'hair', color: {argb: '000000'}},
            right: {style: 'hair', color: {argb: '000000'}},
        }
    }

    const rowStartTableNumber = worksheet.rowCount

    let if3 = false;
    let if4 = false;

    users.map((user: any, index: number) => {

        const homeHours = user.statistics?.tasks.reduce((sum: number, curr: any) => {
            sum += curr.home
            return sum
        }, 0)
        const fieldHours = user.statistics?.tasks.reduce((sum: number, curr: any) => {
            sum += curr.field
            return sum
        }, 0)

        const rowUser: any = {
            colUsers: 'Users',
            ProjHome: homeHours ?? 0,
            ProjField: fieldHours ?? 0,
            ProjTotal: {formula: `SUM(C${rowStartTableNumber + 1 + index}:D${rowStartTableNumber + 1 + index})`},
            DailyRateEUR: "",
            AmountEUR: "",
        }

        rowUser[`colUsers`] = `${user?.user?.first_name} ${user?.user?.last_name}`

        projects.forEach((project, index) => {

            if (user.statistics.tasks.some((proj: any) => proj?.project?.id === project?.id)) {
                rowUser[`col${index + 1}`] = user.statistics.tasks.filter((proj: any) => proj?.project?.id === project?.id)[0]?.hours / 8
            } else {
                rowUser[`col${index + 1}`] = 0
            }

        })

        rowUser[`TotalWorkingDays`] = {formula: `SUM(${result[2]}${rowStartTableNumber + 1 + index}:${result[projects.length + 1]}${rowStartTableNumber + 1 + index})`}

        worksheet.addRow(rowUser).eachCell((cell, colNumber) => {
            cell.style = styleForTableBody;
            cell.numFmt = '#,##0.0';
            
            if3 = if3 ? if3 : colNumber === 3 && Number(cell?.value) > 0;
            if4 = if4 ? if4 : colNumber === 4 && Number(cell.value) > 0
            
            if((if3 || if4) && (colNumber === 6 || colNumber === 4)) {
                
                cell.style = {
                    font: {size: 11, bold: true},
                    alignment: {vertical: 'middle', horizontal: 'center'},
                    border: {
                        top: {style: 'hair', color: {argb: '000000'}},
                        left: {style: 'hair', color: {argb: '000000'}},
                        bottom: {style: 'hair', color: {argb: '000000'}},
                        right: {style: 'hair', color: {argb: '000000'}},
                    }
                };
                cell.numFmt = '#,##0.0';

                if3 = false;
                if4 = false;
            }
            console.log(cell?.value);
            if (cell?.value && Number(cell?.value) > 0) {
                
                cell.style = {
                    font: {size: 11, bold: true},
                    alignment: {vertical: 'middle', horizontal: 'center'},
                    border: {
                        top: {style: 'hair', color: {argb: '000000'}},
                        left: {style: 'hair', color: {argb: '000000'}},
                        bottom: {style: 'hair', color: {argb: '000000'}},
                        right: {style: 'hair', color: {argb: '000000'}},
                    }
                };
                cell.numFmt = '#,##0.0';
            }
            if(colNumber === 2) {
                cell.style = {
                    ...styleForTableBody,
                    alignment: {
                        ...styleForTableBody.alignment,
                        horizontal: 'left'
                    },
                    border: {
                        ...styleForTableBody.border,
                        left: {style: 'thin', color: {argb: '000000'}},
                    }
                };
            } else if(colNumber === (5 + (chosenProject?.id ? 0 : projects.length))) {
                cell.style = {
                    ...styleForTableBody,
                    border: {
                        ...styleForTableBody.border,
                        right: {style: 'thin', color: {argb: '000000'}},
                    }
                };
            }
        });

    })

    const styleForTableFooter: Partial<ExcelJS.Style> = {
        font: {bold: true, size: 11, color: {argb: '000000'}},
        alignment: {vertical: 'middle', horizontal: 'center', wrapText: true},
        fill: {
            type: 'pattern',
            pattern: 'solid',
            fgColor: {argb: 'EFEFEF'}
        },
        border: {
            top: {style: 'thin', color: {argb: '000000'}},
            left: {style: 'thin', color: {argb: '000000'}},
            bottom: {style: 'thin', color: {argb: '000000'}},
            right: {style: 'thin', color: {argb: '000000'}}
        }
    }

    const rowFooter: any = {
        colUsers: 'Total working days',
    }

    projects.map((project, index) => {
        let sum = 0;

        users.forEach((user: any) => {
            if (user.statistics.tasks.some((proj: any) => proj.project.id === project.id)) {
                sum += user.statistics.tasks.filter((proj: any) => proj.project.id === project.id)[0].hours
            }
        })

        rowFooter[`col${index + 1}`] = {formula: `SUM(${result[index + 2]}${rowStartTableNumber + 1}:${result[index + 2]}${rowStartTableNumber + users.length})`}
    })

    rowFooter[`TotalWorkingDays`] = {formula: `SUM(${result[projects.length + 2]}${rowStartTableNumber + 1}:${result[projects.length + 2]}${rowStartTableNumber + users.length})`}
    rowFooter[`DailyRateEUR`] = ""
    rowFooter[`AmountEUR`] = ""
    rowFooter[`ProjHome`] = {formula: `SUM(C${rowStartTableNumber + 1}:C${rowStartTableNumber + users.length})`}
    rowFooter[`ProjField`] = {formula: `SUM(D${rowStartTableNumber + 1}:D${rowStartTableNumber + users.length})`}
    rowFooter[`ProjTotal`] = {formula: `SUM(E${rowStartTableNumber + 1}:E${rowStartTableNumber + users.length})`}

    worksheet.addRow(rowFooter).eachCell((cell, colNumber) => {
        cell.style = styleForTableFooter;

        if (colNumber === projects.length + 4) {
            cell.numFmt = '#,##0.0';
            cell.style = {
                font: {bold: true, size: 11, color: {argb: 'F8BF0A'}},
                alignment: {vertical: 'top', horizontal: 'center', wrapText: true},
                fill: {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: {argb: 'EFEFEF'}
                },
                border: {
                    top: {style: 'thin', color: {argb: '000000'}},
                    left: {style: 'thin', color: {argb: '000000'}},
                    bottom: {style: 'thin', color: {argb: '000000'}},
                    right: {style: 'thin', color: {argb: '000000'}}
                }
            }
        } else if (colNumber !== 2 && colNumber !== 3) {
            cell.numFmt = '#,##0.0';
        }

        if (cell?.value && Number(cell?.value) > 0) {
            cell.style = {...styleForTableBody};
            cell.font = {bold: true}
        }

        if (colNumber === 2) {
            cell.style = {...styleForTableFooter};
            cell.alignment = {horizontal: 'left'};
        }
    });


    if(chosenProject?.id) {
        worksheet.addImage(logo, {
            // @ts-ignore
            tl: {col: 4, row: 0.9},
            // @ts-ignore
            br: {col: 5, row: 4}
        });
    } else {
        worksheet.addImage(logo, {
            // @ts-ignore
            tl: {col: projects.length + 3.8, row: 0.9},
            // @ts-ignore
            br: {col: projects.length + 5, row: 4}
        });
    }

}