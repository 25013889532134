import React, { useContext } from 'react'
import { PopupClose } from './PopupClose'
import { Translate } from '../../translate/Translate'
import { PopupCloseCancel } from './PopupCloseCancel'
import { getApiLink } from '../../../functions/getApiLink'
import { IsPopupActiveContext } from '../PopupList'
import { useDispatch } from 'react-redux'
import { getBearer } from '../../../functions/getBearer'
import axios from 'axios'
import { deleteCheck } from '../../../storage/toolkit'

interface IDeleteReceiptProps {
    data: any
}

export const DeleteReceipt: React.FC<IDeleteReceiptProps> = ({data}) => {

    const setIsPopupActive: any = useContext(IsPopupActiveContext)
    const dispatch = useDispatch()

    const handleDelete = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()

        getBearer('delete')
        axios.delete(getApiLink(`/api/expense/delete_check/?expense_id=${data.id}`)).then((res) => {
            const dataItem = res.data
            if (!dataItem.status) return;

            setIsPopupActive(false)
            dispatch(deleteCheck(data))

        }).catch(er => console.log(getApiLink('/api/expense/delete_check/'), er))

    }

    return (
        <div className="remove-table-item__body popup-body">
            <PopupClose />
            <div className="remove-table-item__container popup-container"
                data-simplebar-auto-hide="false">
                <h2 className="remove-table-item__title popup-title title is-center">
                    Are you sure you want to delete receipt?
                </h2>
                <form onSubmit={handleDelete} className="popup-form">
                    <div className="popup-form__row is-min-gap">
                        <PopupCloseCancel />
                        <button className="popup-form__submit btn" type="submit">
                            <Translate>timesheet_page.popups.delete</Translate>
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
}
