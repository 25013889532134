import {getBearer} from "../functions/getBearer";
import axios from "axios";
import {getApiLink} from "../functions/getApiLink";
import {setTasks} from "../storage/toolkit";
import { GetAccessToken } from "./GetAccessToken";

export const SetTasks = async (dispatch: any, timesheetId: string, projectId?: string) => {

    if(!timesheetId) return;

    projectId = projectId ? `&project_id=${projectId}` : ""

    getBearer("get")
    await axios.get(getApiLink(`/api/timesheet/tasks/?timesheet_id=${timesheetId}${projectId ?? ""}`)).then(({data}) => {
        dispatch(setTasks(data))
    }).catch(er => {
        er?.response?.status === 401 && GetAccessToken(dispatch, SetTasks)
    })
}