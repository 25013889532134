import React, { useEffect } from 'react'
import ExcelJS from "exceljs";
import { MonthNumber } from "../../constants/MonthNumber";
import { IDepartment, IEmployee, ITimesheet } from "../../models";

interface IEmployeesProps {
    employees: IEmployee[]
    translate: any
    worksheet: ExcelJS.Worksheet
    logo: number
    listYear: any
    departments: IDepartment[]
}

export const Employees = ({ worksheet, translate, listYear, employees, logo, departments }: IEmployeesProps) => {

    worksheet.columns = [
        { header: '', key: 'col0', width: 8.00 },
        { header: '', key: 'col1', width: 5.33 },
        { header: '', key: 'col2', width: 13.33 },
        { header: '', key: 'col3', width: 18.33 },
        { header: '', key: 'col4', width: 10.17 },
        { header: '', key: 'col5', width: 22.50 },
        { header: '', key: 'col6', width: 16.50 },
        { header: '', key: 'col7', width: 12.60 },
        { header: '', key: 'col8', width: 12.80 },
    ];


    worksheet.addRow({})
    worksheet.getRow(1).height = 25;

    const styleForHeader: Partial<ExcelJS.Style> = {
        font: { bold: true, size: 22 },
        alignment: { vertical: 'middle', horizontal: 'left' }
    };

    worksheet.mergeCells('B2:I2');
    worksheet.getCell('B2').value = "Employees";
    worksheet.getCell('B2').style = styleForHeader;

    worksheet.addRow({})

    const date = listYear
    worksheet.mergeCells('B4:I4');
    worksheet.getCell('B4').value = {
        richText: [
            { text: date, font: { bold: true, size: 14 } },
        ]
    };
    worksheet.getCell('B4').alignment = {
        horizontal: 'left'
    };

    worksheet.addRow({})

    const styleForTableHeader: Partial<ExcelJS.Style> = {
        font: { bold: true, size: 11, color: { argb: '000000' } },
        alignment: { vertical: 'middle', horizontal: 'center' },
        fill: {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'FBC900' }
        },
        border: {
            top: { style: 'thin', color: { argb: '000000' } },
            left: { style: 'thin', color: { argb: '000000' } },
            bottom: { style: 'thin', color: { argb: '000000' } },
            right: { style: 'thin', color: { argb: '000000' } }
        }
    };

    worksheet.addRow({
        col1: 'No',
        col2: 'Name',
        col3: 'Position',
        col4: 'Category',
        col5: 'Email',
        col6: 'Phone number',
        col7: 'Department',
        col8: 'Projects',
    }).eachCell((cell, colNumber) => {
        cell.style = styleForTableHeader;
    });

    worksheet.getRow(6).height = 18;

    const styleForTableBody: Partial<ExcelJS.Style> = {
        font: { size: 10 },
        alignment: { vertical: 'middle', horizontal: 'left', wrapText: true },
        border: {
            top: { style: 'hair', color: { argb: '000000' } },
            left: { style: 'hair', color: { argb: '000000' } },
            bottom: { style: 'hair', color: { argb: '000000' } },
            right: { style: 'hair', color: { argb: '000000' } },
        }
    }
    const styleForTableBodyRetire: Partial<ExcelJS.Style> = {
        font: { size: 10, color: { argb: 'cccccc' } },
        alignment: { vertical: 'middle', horizontal: 'left', wrapText: true },
        border: {
            top: { style: 'hair', color: { argb: '000000' } },
            left: { style: 'hair', color: { argb: '000000' } },
            bottom: { style: 'hair', color: { argb: '000000' } },
            right: { style: 'hair', color: { argb: '000000' } },
        }
    }

    const tasksReversed = employees.slice().reverse();
    employees
        ?.slice()
        ?.sort((a, b) => +a.archive - +b.archive)
        ?.map((item: IEmployee, index: number) => {
            const isLastRow = index === tasksReversed.length - 1;

            const statusesParser: { [key: string]: string } = {
                "employee": "Employee",
                "manager": "Manager",
                "project_lead": "Project Manager"
            }

            const userDepartments = !item.all_departments
                ? item.departments.map((item2, index) => {
                    return item2.name
                })
                : departments.map((item2: any, index: number) => {
                    return item2.name
                })

            return worksheet.addRow({
                col1: index + 1,
                col2: `${item.first_name} ${item.last_name}`,
                col3: item.role,
                col4: statusesParser[item.status],
                col5: item.email,
                col6: item.phone,
                col7: userDepartments.join(", "),
                col8: item.all_projects ? "All projects" : item.projects.length + " Projects",
            }).eachCell((cell, colNumber) => {
                cell.style = item.archive ? styleForTableBodyRetire : styleForTableBody;

                if (colNumber === 9) {
                    cell.style = item.archive ?
                        {
                            ...styleForTableBody,
                            font: { size: 10, color: { argb: 'cccccc' } },
                            border: {
                                ...styleForTableBody.border,
                                right: { style: 'thin', color: { argb: '000000' } },
                            }
                        }
                        :
                        {
                            ...styleForTableBody,
                            border: {
                                ...styleForTableBody.border,
                                right: { style: 'thin', color: { argb: '000000' } },
                            }
                        }
                } else if (colNumber === 2) {
                    cell.style = item.archive ?
                        {
                            ...styleForTableBody,
                            font: { size: 10, color: { argb: 'cccccc' } },
                            border: {
                                ...styleForTableBody.border,
                                left: { style: 'thin', color: { argb: '000000' } },
                            }
                        }
                        :
                        {
                            ...styleForTableBody,
                            border: {
                                ...styleForTableBody.border,
                                left: { style: 'thin', color: { argb: '000000' } },
                            }
                        }
                } else if (colNumber === 5) {
                    cell.style = item.archive ?
                        {
                            ...styleForTableBody,
                            alignment: { vertical: 'middle', horizontal: 'center' },
                            font: { size: 10, color: { argb: 'cccccc' } },
                        }
                        :
                        {
                            ...styleForTableBody,
                            alignment: { vertical: 'middle', horizontal: 'center' },
                        }
                }


                if (isLastRow) {
                    cell.style = item.archive ?
                        {
                            ...styleForTableBody,
                            font: { size: 10, color: { argb: 'cccccc' } },
                            border: {
                                ...styleForTableBody.border,
                                bottom: { style: "thin", color: { argb: "000000" } }
                            }
                        }
                        :
                        {
                            ...styleForTableBody,
                            border: {
                                ...styleForTableBody.border,
                                bottom: { style: "thin", color: { argb: "000000" } }
                            }
                        }

                    if (colNumber === 2) {
                        cell.style = item.archive ?
                        {
                            ...styleForTableBody,
                            font: { size: 10, color: { argb: 'cccccc' } },
                            border: {
                                ...styleForTableBody.border,
                                bottom: { style: "thin", color: { argb: "000000" } },
                                left: { style: 'thin', color: { argb: '000000' } },
                            }
                        }
                        :
                        {
                            ...styleForTableBody,
                            border: {
                                ...styleForTableBody.border,
                                bottom: { style: "thin", color: { argb: "000000" } },
                                left: { style: 'thin', color: { argb: '000000' } },
                            }
                        }
                    } else if (colNumber === 9) {
                        cell.style = item.archive ?
                        {
                            ...styleForTableBody,
                            font: { size: 10, color: { argb: 'cccccc' } },
                            border: {
                                ...styleForTableBody.border,
                                bottom: { style: "thin", color: { argb: "000000" } },
                                right: { style: 'thin', color: { argb: '000000' } },
                            }
                        }
                        :
                        {
                            ...styleForTableBody,
                            border: {
                                ...styleForTableBody.border,
                                bottom: { style: "thin", color: { argb: "000000" } },
                                right: { style: 'thin', color: { argb: '000000' } },
                            }
                        }
                    }
                }
            });
        })


    worksheet.addImage(logo, {
        // @ts-ignore
        tl: { col: 8.5, row: 0.5 },
        // @ts-ignore
        br: { col: 9, row: 2 },
    });

}
