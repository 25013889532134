import React, { useContext, useEffect, useRef, useState } from "react";
import { IEmployee, ITask, ITimesheet, IVacation } from "../../../models";
import { Translate } from "../../../components/translate/Translate";
import { useDispatch, useSelector } from "react-redux";
import { PopupContext } from "../../../App";
import { BlockToEdit } from "../../timesheet/Timesheet";
import { getBearer } from "../../../functions/getBearer";
import axios from "axios";
import { getApiLink } from "../../../functions/getApiLink";
import { SetStatistic } from "../../../api/SetStatistic";
import { SetTasks } from "../../../api/SetTasks";
import { useClickOutside } from "../../../hooks/ClickOutside";
import { handleOpenContextMenu } from "../../../functions/handleOpenContextMenu";

interface IVacationsItemProps {
  itemData: IVacation;
  index: number;
}

export const VacationsItem: React.FC<IVacationsItemProps> = ({
  itemData,
  index,
}) => {
  const [total, setTotal] = useState(0);
  const [totalMonth, setTotalMonth] = useState(0);
  const [isOpenContextMenu, setIsOpenContextMenu] = useState(false);
  const [menuPosition, setMenuPosition] = useState({});
  const [comment, setComment] = useState<string>("");
  const [extraDays, setExtraDays] = useState(+itemData.extra);
  const [isChangeExtraDays, setIsChangeExtraDays] = useState(false);
  const [yearDays, setYearDays] = useState(+itemData?.user?.holidays);
  const [isChangeYearDays, setIsChangeYearDays] = useState<boolean>(false);
  const [isCanEditComment, setIsCanEditComment] = useState<boolean>(false);

  useEffect(() => {
    setComment(itemData.comment);
  }, []);

  const inputBlock: any = useRef(null);
  const inputYearBlock: any = useRef(null);

  useEffect(() => {
    setTotal(0);
    setTotal((prev) => prev + +itemData.remain + +extraDays + +yearDays);
    itemData.months.map((item) => setTotalMonth((prev) => prev + item.days));
  }, [extraDays, yearDays]);

  const handleOpenEditExtraDays = () => {
    setIsOpenContextMenu(false);

    setTimeout(() => {
      setIsChangeExtraDays(true);
      setTimeout(() => {
        inputBlock?.current?.focus();
      }, 100);
    }, 100);
  };

  const handleOpenEditYearsDays = () => {
    setIsOpenContextMenu(false);

    setTimeout(() => {
      setIsChangeYearDays(true);
      setTimeout(() => {
        inputYearBlock?.current?.focus();
      }, 100);
    }, 100);
  };

  const handleChangeExtraDays = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    changeExtra();
  };
  const handleChangeYearDays = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    changeYearDays();
  };

  const changeExtra = () => {
    getBearer("patch");
    axios
      .patch(
        getApiLink(
          `/api/admin/employee/vacations/update/?vacation_id=${itemData.id}`
        ),
        {
          extra: +extraDays,
          comment: comment,
          holidays: +yearDays,
        }
      )
      .then(({ data }) => {
        setIsChangeExtraDays(false);
      })
      .catch((er) => {});
  };

  const changeYearDays = () => {
    getBearer("patch");
    axios
      .patch(
        getApiLink(
          `/api/admin/employee/vacations/update/?vacation_id=${itemData.id}`
        ),
        {
          extra: +extraDays,
          comment: comment,
          holidays: +yearDays,
        }
      )
      .then(({ data }) => {
        setIsChangeYearDays(false);
      })
      .catch((er) => {});
  };

  const commentEl: any = useRef(null);

  const colExtra = useClickOutside(setIsChangeExtraDays);
  const colDayYear = useClickOutside(setIsChangeYearDays);

  useEffect(() => {
    if (isChangeExtraDays) return;
    if (+itemData.extra === extraDays) return;
    setTimeout(() => {
      changeExtra();
    }, 100);
  }, [isChangeExtraDays]);

  useEffect(() => {
    if (isChangeYearDays) return;
    if (+itemData.remain === yearDays) return;
    setTimeout(() => {
      changeYearDays();
    }, 100);
  }, [isChangeYearDays]);

  const modalBlock: any = useRef(null);
  const rowBlock: any = useRef(null);

  useEffect(() => {
    const onClick = (e: any) =>
      modalBlock.current.contains(e.target) || setIsOpenContextMenu(false);
    const onContext = (e: any) =>
      rowBlock.current.contains(e.target) || setIsOpenContextMenu(false);

    document.addEventListener("click", onClick);
    document.addEventListener("contextmenu", onContext);
    return () => {
      document.removeEventListener("click", onClick);
      document.removeEventListener("contextmenu", onContext);
    };
  }, []);

  useEffect(() => {
    if (!isOpenContextMenu) {
      setTimeout(() => {
        setMenuPosition({
          top: "auto",
          left: "auto",
        });
      }, 300);
    }
  }, [isOpenContextMenu]);

  const handleChangeComment = () => {
    getBearer("patch");
    axios
      .patch(
        getApiLink(
          `/api/admin/employee/vacations/update/?vacation_id=${itemData.id}`
        ),
        {
          extra: +extraDays,
          comment: comment,
          holidays: +yearDays,
        }
      )
      .then(({ data }) => {
        setIsCanEditComment(false);
      })
      .catch((er) => {});
  };

  const handleOpenToAddComment = () => {
    setIsOpenContextMenu(false);
    setIsCanEditComment(true);
  };

  useEffect(() => {
    const onClick = (e: any) => {
      if (
        !e.target.closest("textarea") &&
        !e.target.closest(".drop-down-2__list") &&
        isCanEditComment &&
        !isOpenContextMenu
      ) {
        setIsCanEditComment(true);
        handleChangeComment();
      }
    };
    document.addEventListener("click", onClick);
    return () => document.removeEventListener("click", onClick);
  }, [isOpenContextMenu, isCanEditComment, comment]);

  return (
    <div
      className="section-table__row drop-down-2"
      ref={rowBlock}
      onContextMenu={(e) =>
        handleOpenContextMenu({
          e,
          isOpenContextMenu,
          setMenuPosition,
          setIsOpenContextMenu,
          height: 60,
          width: 175,
        })
      }
    >
      <div className="section-table__param visible-on-mob">
        <span>{index}</span>
        <span style={{ whiteSpace: "pre-wrap", wordBreak: "normal" }}>
          {itemData.user.last_name} {itemData.user.first_name}
        </span>
      </div>
      <div className="section-table__param visible-on-desktop is-none-vertical-hover">
        {index}
      </div>
      <div
        className="section-table__param visible-on-desktop is-none-vertical-hover"
        style={{ whiteSpace: "pre-wrap", wordBreak: "normal" }}
      >
        {itemData.user.last_name} {itemData.user.first_name}
      </div>
      <div
        className="section-table__param"
        style={{ fontWeight: itemData?.remain !== 0 ? "bold" : "" }}
      >
        {itemData.remain.toFixed(1)}
      </div>
      <div
        className="section-table__param section-table__param_extra"
        style={{ fontWeight: extraDays !== 0 ? "bold" : "" }}
      >
        {!isChangeExtraDays ? (
          extraDays.toFixed(1)
        ) : (
          <form ref={colExtra.rootEl} onSubmit={handleChangeExtraDays}>
            <input
              ref={inputBlock}
              type="number"
              onChange={(e) => setExtraDays(+e.target.value)}
              placeholder={"0"}
              value={extraDays === 0 ? "" : extraDays}
            />
          </form>
        )}

        {comment && <div className="rectangle" />}

        {(comment || isCanEditComment) && (
          <textarea
            ref={commentEl}
            style={{
              opacity: isCanEditComment ? 1 : 0,
              visibility: isCanEditComment ? "visible" : "hidden",
            }}
            disabled={!isCanEditComment}
            className={`section-table__comment ${
              !itemData?.comment && "non-have-comment"
            }`}
            value={comment}
            onChange={(e) => setComment(e.target.value)}
          />
        )}
      </div>
      <div
        className="section-table__param"
        style={{ fontWeight: yearDays !== 0 ? "bold" : "" }}
      >
        {!isChangeYearDays ? (
          yearDays.toFixed(1)
        ) : (
          <form ref={colDayYear.rootEl} onSubmit={handleChangeYearDays}>
            <input
              ref={inputYearBlock}
              type="number"
              onChange={(e) => setYearDays(+e.target.value)}
              placeholder={"0"}
              value={yearDays === 0 ? "" : yearDays}
            />
          </form>
        )}
      </div>
      <div
        className="section-table__param"
        style={{ fontWeight: total !== 0 ? "bold" : "" }}
      >
        {total.toFixed(1)}
      </div>
      {itemData.months.map((item, index2) => (
        <div
          key={index2}
          className="section-table__param"
          style={{ fontWeight: item.days !== 0 ? "bold" : "" }}
        >
          {item.days.toFixed(1)}
        </div>
      ))}
      <div
        className="section-table__param"
        style={{ fontWeight: total - totalMonth !== 0 ? "bold" : "" }}
      >
        {(total - totalMonth).toFixed(1)}
      </div>
      <div
        className={"drop-down-2__block" + (isOpenContextMenu ? " active" : "")}
        ref={modalBlock}
        style={menuPosition}
      >
        <ul className="drop-down-2__list">
          <li>
            <a onClick={handleOpenEditExtraDays}>
              <svg width="15" height="16" viewBox="0 0 15 16">
                <use xlinkHref="#edit"></use>
              </svg>
              <Translate>vacations_admin.edit_extra_days</Translate>
            </a>
          </li>
          <li>
            <a onClick={handleOpenToAddComment}>
              <svg
                width="15"
                height="16"
                viewBox="0 0 15 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <use xlinkHref="#plus"></use>
              </svg>
              <Translate>add_comment</Translate>
            </a>
          </li>
          <li>
            <a onClick={handleOpenEditYearsDays}>
              <svg width="15" height="16" viewBox="0 0 15 16">
                <use xlinkHref="#edit"></use>
              </svg>
              Edit days per year
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};
