export const TimeMinutesList = () => {
    return [
        {
            value: 0,
            label: "00"
        },
        {
            value: 0.5,
            label: "30"
        },
    ]
}