import axios, {AxiosResponse} from "axios";
import {getApiLink} from "../functions/getApiLink";
import { setDepartments } from "../storage/toolkit";

export const GetDepartments = (dispatch: any) => {

    axios.get(getApiLink(`/api/admin/department/`))
        .then(({data}: AxiosResponse<any>) => {
            dispatch(setDepartments(data))
        })
        .catch((error) => {
            
        });
}
