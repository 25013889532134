import React, { useEffect } from 'react'
import ExcelJS from "exceljs";
import { MonthNumber } from "../../constants/MonthNumber";
import { IEmployee, IProject, ITimesheet } from "../../models";

interface IProjectsProps {
    projects: IProject[]
    translate: any
    worksheet: ExcelJS.Worksheet
    logo: number
    listYear: any
}

export const Projects = ({ worksheet, translate, listYear, projects, logo }: IProjectsProps) => {

    worksheet.columns = [
        { header: '', key: 'col0', width: 8.00 },
        { header: '', key: 'col1', width: 5.00 },
        { header: '', key: 'col2', width: 11.0 },
        { header: '', key: 'col3', width: 12.33 },
        { header: '', key: 'col4', width: 31.33 },
        { header: '', key: 'col5', width: 11.10 },
    ];

    worksheet.addRow({})
    worksheet.getRow(1).height = 25;

    const styleForHeader: Partial<ExcelJS.Style> = {
        font: { bold: true, size: 22 },
        alignment: { vertical: 'middle', horizontal: 'left' }
    };

    worksheet.mergeCells('B2:D2');
    worksheet.getCell('B2').value = "Projects";
    worksheet.getCell('B2').style = styleForHeader;

    worksheet.addRow({})

    const date = listYear
    worksheet.mergeCells('B4:D4');
    worksheet.getCell('B4').value = {
        richText: [
            { text: date, font: { bold: true, size: 14 } },
        ]
    };
    worksheet.getCell('B4').alignment = {
        horizontal: 'left'
    };

    worksheet.addRow({})

    const styleForTableHeader: Partial<ExcelJS.Style> = {
        font: { bold: true, size: 11, color: { argb: '000000' } },
        alignment: { vertical: 'middle', horizontal: 'center' },
        fill: {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'FBC900' }
        },
        border: {
            top: { style: 'thin', color: { argb: '000000' } },
            left: { style: 'thin', color: { argb: '000000' } },
            bottom: { style: 'thin', color: { argb: '000000' } },
            right: { style: 'thin', color: { argb: '000000' } }
        }
    };

    worksheet.addRow({
        col1: 'No',
        col2: 'Department',
        col3: 'Number',
        col4: 'Project description',
        col5: 'Tasks',
    }).eachCell((cell, colNumber) => {
        cell.style = styleForTableHeader;
    });

    worksheet.getRow(6).height = 18;

    const styleForTableBody: Partial<ExcelJS.Style> = {
        font: { size: 10 },
        alignment: { vertical: 'middle', horizontal: 'center', wrapText: true },
        border: {
            top: { style: 'hair', color: { argb: '000000' } },
            left: { style: 'hair', color: { argb: '000000' } },
            bottom: { style: 'hair', color: { argb: '000000' } },
            right: { style: 'hair', color: { argb: '000000' } },
        }
    }
    const styleForTableBodyRetire: Partial<ExcelJS.Style> = {
        font: { size: 10, color: { argb: 'cccccc' } },
        alignment: { vertical: 'middle', horizontal: 'left', wrapText: true },
        border: {
            top: { style: 'hair', color: { argb: '000000' } },
            left: { style: 'hair', color: { argb: '000000' } },
            bottom: { style: 'hair', color: { argb: '000000' } },
            right: { style: 'hair', color: { argb: '000000' } },
        }
    }

    const tasksReversed = projects.slice().reverse();
    projects?.map((item: IProject, index: number) => {
        const isLastRow = index === tasksReversed.length - 1;

        return worksheet.addRow({
            col1: index + 1,
            col2: item.department.name,
            col3: item.name,
            col4: item.description,
            col5: item.task_categories.length + " tasks",
        }).eachCell((cell, colNumber) => {
            cell.style = item.archive ? styleForTableBodyRetire : styleForTableBody;


            if (colNumber === 6) {
                cell.style = item.archive ?
                    {
                        ...styleForTableBody,
                        font: { size: 10, color: { argb: 'cccccc' } },
                        border: {
                            ...styleForTableBody.border,
                            right: { style: 'thin', color: { argb: '000000' } },
                        }
                    }
                    :
                    {
                        ...styleForTableBody,
                        border: {
                            ...styleForTableBody.border,
                            right: { style: 'thin', color: { argb: '000000' } },
                        }
                    }
            } else if (colNumber === 2) {
                cell.style = item.archive ?
                    {
                        ...styleForTableBody,
                        font: { size: 10, color: { argb: 'cccccc' } },
                        border: {
                            ...styleForTableBody.border,
                            left: { style: 'thin', color: { argb: '000000' } },
                        }
                    }
                    :
                    {
                        ...styleForTableBody,
                        border: {
                            ...styleForTableBody.border,
                            left: { style: 'thin', color: { argb: '000000' } },
                        }
                    }
            } else if (colNumber === 3) {
                cell.style = item.archive ?
                    {
                        ...styleForTableBody,
                        alignment: { vertical: 'middle', horizontal: 'center' },
                        font: { size: 10, color: { argb: 'cccccc' } },
                    }
                    :
                    {
                        ...styleForTableBody,
                        alignment: { vertical: 'middle', horizontal: 'center' },
                    }
            } else if (colNumber === 5) {
                cell.style = {
                    ...styleForTableBody,
                    alignment: {
                        ...styleForTableBody.alignment,
                        horizontal: "left"
                    }
                }
            }


            if (isLastRow) {
                cell.style = item.archive ?
                    {
                        ...styleForTableBody,
                        font: { size: 10, color: { argb: 'cccccc' } },
                        border: {
                            ...styleForTableBody.border,
                            bottom: { style: "thin", color: { argb: "000000" } }
                        }
                    }
                    :
                    {
                        ...styleForTableBody,
                        border: {
                            ...styleForTableBody.border,
                            bottom: { style: "thin", color: { argb: "000000" } }
                        }
                    }

                if (colNumber === 2) {
                    cell.style = item.archive ?
                        {
                            ...styleForTableBody,
                            font: { size: 10, color: { argb: 'cccccc' } },
                            border: {
                                ...styleForTableBody.border,
                                bottom: { style: "thin", color: { argb: "000000" } },
                                left: { style: 'thin', color: { argb: '000000' } },
                            }
                        }
                        :
                        {
                            ...styleForTableBody,
                            border: {
                                ...styleForTableBody.border,
                                bottom: { style: "thin", color: { argb: "000000" } },
                                left: { style: 'thin', color: { argb: '000000' } },
                            }
                        }
                } else if (colNumber === 6) {
                    cell.style = item.archive ?
                        {
                            ...styleForTableBody,
                            font: { size: 10, color: { argb: 'cccccc' } },
                            border: {
                                ...styleForTableBody.border,
                                bottom: { style: "thin", color: { argb: "000000" } },
                                right: { style: 'thin', color: { argb: '000000' } },
                            }
                        }
                        :
                        {
                            ...styleForTableBody,
                            border: {
                                ...styleForTableBody.border,
                                bottom: { style: "thin", color: { argb: "000000" } },
                                right: { style: 'thin', color: { argb: '000000' } },
                            }
                        }
                } else if (colNumber === 5) {
                    cell.style = item.archive ?
                        {
                            ...styleForTableBody,
                            font: { size: 10, color: { argb: 'cccccc' } },
                            border: {
                                ...styleForTableBody.border,
                                bottom: { style: "thin", color: { argb: "000000" } }
                            },
                            alignment: {
                                ...styleForTableBody.alignment,
                                horizontal: "left"
                            }
                        }
                        :
                        {
                            ...styleForTableBody,
                            border: {
                                ...styleForTableBody.border,
                                bottom: { style: "thin", color: { argb: "000000" } }
                            },
                            alignment: {
                                ...styleForTableBody.alignment,
                                horizontal: "left"
                            }
                        }
                }
            }

        });
    })


    worksheet.addImage(logo, {
        // @ts-ignore
        tl: { col: 5, row: 0.5 },
        // @ts-ignore
        br: { col: 6, row: 2 },
    });

}
